import PropTypes from "prop-types";
import React from "react";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-lg",
  circle: "rounded-[50%]",
};
const variants = {
  outline: {
    "indigo-500": "border border-solid bg-indigo-500 shadow-xs text-white",
  },
  fill: {
    white: "bg-white shadow-xs text-blue_gray-800",
  },
  gradient: {
    "indigo-500": "bg-indigo-500",
  },
};
const sizes = {
  "2xl": "h-[80px]",
  lg: "h-[44px] px-[35px] text-xl",
  xl: "h-[48px] px-5 text-base",
  sm: "h-[34px] px-[7px]",
  md: "h-[44px] px-[13px] text-[19px]",
  xs: "h-[24px] px-[7px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "gradient",
  size = "xs",
  color = "white",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer whitespace-nowrap ${
        (shape && shapes[shape]) || ""
      } ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]?.[color]) || ""
      }`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round", "circle"]),
  size: PropTypes.oneOf(["2xl", "lg", "xl", "sm", "md", "xs"]),
  variant: PropTypes.oneOf(["outline", "fill", "gradient"]),
  color: PropTypes.oneOf(["indigo-500", "white"]),
};

export { Button };
