import {
  Badge,
  Button,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaChartBar } from "react-icons/fa";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { MdDelete, MdEdit, MdFileCopy, MdPreview } from "react-icons/md";
import { PiKanbanFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { STANDARD_MOMENT_FORMAT, brandColor } from "../../../data/constants";
import { getPartner, selectUser } from "../../../redux/auth/selectors";
import ATSService from "../../../service/ATSService";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";
import UserService from "../../../service/UserService";

function truncateString(str, num) {
  if (!str) return "";
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function removeDuplicateObjects(arr) {
  const uniqueObjects = {};
  const result = [];

  for (const obj of arr) {
    const id = obj._id;

    // Check if the _id is not already in the uniqueObjects dictionary
    if (!uniqueObjects[id]) {
      uniqueObjects[id] = true;
      result.push(obj);
    }
  }

  return result;
}

const PAGE_LIMIT = 9;

const AdAutomation = () => {
  const user = useSelector(selectUser);
  const partner = useSelector(getPartner);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);
  const [singleVacancy, setSingleVacancy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [total, setTotal] = useState(0);

  const loadMoreVacancies = useCallback(
    async (filters = {}, text = "", alternativePage) => {
      if (!user) return;

      setLoading(true);
      try {
        const data = {
          filters: { ...filters, user_id: user._id },
          sort: { createdAt: -1 },
        };
        if (text) data.text = text;
        const response = await CrudService.search(
          "AdCampaign",
          PAGE_LIMIT,
          alternativePage,
          data
        );
        const newVacancies = (
          await ATSService.countApplicants(
            response.data.items.map((item) => ({ _id: item._id }))
          )
        ).data;
        setVacancies((prevVacancies) => [
          ...prevVacancies,
          ...newVacancies.map((v) => ({
            ...v,
            ...response.data.items.find((a) => a._id === v._id),
          })),
        ]);
        setPage((prevPage) => prevPage + 1);
        setTotal(response.data.total);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [user]
  );

  useEffect(() => {
    if (loading) return;
    const handleScroll = () => {
      const container = document.getElementById("vacancyContainer");

      if (
        container &&
        window.innerHeight + window.scrollY >= container.scrollHeight - 100
      ) {
        loadMoreVacancies();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, loadMoreVacancies]);

  useEffect(() => {
    const getSingle = async () => {
      const id = searchParams.get("id");
      const isNew = searchParams.get("new");
      if (!id) return;

      const searchedVacancy = await CrudService.getSingle("AdCampaign", id);
      setSingleVacancy({ ...searchedVacancy.data, isNew: isNew === "true" });
    };
    getSingle();
  }, [searchParams]);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setPage(1);
      setVacancies([]);

      const query = {};

      loadMoreVacancies(query, newValue, 1);
      setSingleVacancy(null);
    }, 1000);
  };

  useEffect(() => {
    loadMoreVacancies();
  }, [loadMoreVacancies]);

  const handleSecondConfirm = async (vacancyId, deleteCandidates) => {
    await ATSService.deleteFunnel(vacancyId, deleteCandidates);
    setSingleVacancy(null);
    setVacancies((c) => c.filter((e) => e._id !== vacancyId));
  };

  const handleFirstConfirm = (vacancyId) => {
    modal.confirm({
      title: "Confirm Deletion",
      content: "Please confirm the deletion of this funnel.",
      okText: "DELETE",
      cancelText: "Cancel",
      closable: true,
      onOk: async () => {
        handleSecondConfirm(vacancyId, false);
      },
    });
  };

  return (
    <>
      {contextHolder}

      <div className="container mx-auto p-4" id="vacancyContainer">
        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            placeholder="Search Campaigns"
            className="block w-full rounded-md border-0 py-1.5 pr-14  dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900  dark:bg-gray-900"
            value={searchTerm}
            onChange={handleInputChange}
            autoComplete="new-password"
          />

          <button
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold  leading-6 whitespace-nowrap py-1.5 px-1 rounded"
            onClick={async () => {
              let result;
              try {
                const res = await PublicService.aiPrompt({
                  content: `
    For the following company:
    Service Details: ${user.serviceDetails}
    Customer Persona Age: ${user.customerPersonaAge}
    Customer Persona Gender: ${user.customerPersonaGender}
    Customer Persona Interest: ${user.customerPersonaInterest}
    Customer Persona Industry: ${user.customerPersonaIndustry}
    Customer Persona Keywords: ${user.customerPersonaKeywords}
    
    We need to create ad copy: ad primary text, headline, description
    Each copy should be no longer than 50 characters!

    Your answer must be in JSON format that can be parsed with JSON.parse function of Javascript without errors in the following format:

    {
      adPrimaryText: String,
      adHeadline: String,
      adDescription: String,
    }
    `,
                });

                result = JSON.parse(
                  res.data?.output
                    ?.replace?.(/```json/g, "")
                    ?.replace?.(/```/g, "")
                );
              } catch (e) {
                console.error("Error with AI:", e);
              }

              const current = await CrudService.create("AdCampaign", {
                user_id: user._id,
                name: `New Ad Campaign ${moment().format(
                  STANDARD_MOMENT_FORMAT
                )}`,

                adPrimaryText: result?.adPrimaryText ?? "",
                adHeadline: result?.adHeadline ?? "",
                adDescription: result?.adDescription ?? "",
              });

              navigate(`/dashboard/createAd?id=${current?.data?.result?._id}`);
            }}
          >
            Create New Campaign
          </button>
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 my-vacancies-grid mt-2">
          {removeDuplicateObjects(
            [singleVacancy, ...vacancies].filter((a) => !!a)
          ).map((vacancy) => (
            <Badge.Ribbon
              key={vacancy._id}
              size="small"
              style={{
                top: 60,
                display: vacancy?.isNew ? "block" : "none",
              }}
              text="New"
              color={partner?.themeColor ?? brandColor}
            >
              <div className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out">
                <div className="px-3 py-3 flex gap-3 items-center justify-between">
                  <div className="font-bold text-xl mb-2 whitespace-nowrap max-w-[200px] overflow-hidden truncate">
                    <Typography.Paragraph
                      editable={{
                        onChange: (e) => {
                          setVacancies((c) => {
                            const current = [...c];
                            const thisOne = current.find(
                              (a) => a._id === vacancy._id
                            );
                            if (!thisOne) return current;
                            thisOne.name = e;

                            return current;
                          });
                          if (singleVacancy?._id === vacancy?._id)
                            setSingleVacancy((x) => ({
                              ...x,
                              name: e,
                            }));
                          CrudService.update("AdCampaign", vacancy._id, {
                            name: e,
                          });
                        },
                        value: vacancy.name,
                        tooltip: "Change funnel name",
                      }}
                    >
                      {truncateString(vacancy.name, 25) || "Unspecified"}
                    </Typography.Paragraph>
                  </div>
                  <Switch
                    size="small"
                    defaultChecked={vacancy.enabled !== false}
                    onChange={async (e) => {
                      if (vacancy.campaign) {
                        await UserService.changeCampaignStatus({
                          active: e,
                          campaignId: vacancy.campaign,
                          _id: vacancy._id,
                        });
                      }
                    }}
                  />
                </div>
                <div className="px-6 pb-4 gap-3 flex flex-col">
                  <div className="flex gap-3 justify-between">
                    {!vacancy?.campaign ? (
                      <Space
                        onClick={async () => {
                          navigate(`/dashboard/createAd?id=${vacancy._id}`);
                        }}
                        className="bg-gray-300 rounded-lg px-2 py-1 text-indigo-500 font-bold cursor-pointer"
                      >
                        <MdEdit
                          size={23}
                          title="Edit"
                          className="cursor-pointer text-indigo-500"
                        />
                        <div>Publish</div>
                      </Space>
                    ) : (
                      <Space
                        onClick={async () => {
                          navigate(`/dashboard/adStats?id=${vacancy._id}`);
                        }}
                        className="bg-gray-300 rounded-lg px-2 py-1 text-indigo-500 font-bold cursor-pointer"
                      >
                        <FaChartBar
                          size={23}
                          title="Stats"
                          className="cursor-pointer text-indigo-500"
                        />
                        <div>Stats</div>
                      </Space>
                    )}
                  </div>
                </div>
              </div>
            </Badge.Ribbon>
          ))}
        </div>
      </div>

      {total >= PAGE_LIMIT * (page - 1) && (
        <div className="flex justify-center mt-5">
          <Button loading={loading} onClick={() => loadMoreVacancies()}>
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default AdAutomation;
