export const configSections = [
  {
    title: "Typography",
    properties: [
      {
        key: "font-family",
        placeholder: "Font Family",
        options: ["font-sans", "font-serif", "font-mono"],
      },
      {
        key: "font-size",
        placeholder: "Font Size",
        notAdvanced: true,
        labels: {
          "text-xs": "12px",
          "text-sm": "14px",
          "text-base": "16px",
          "text-lg": "18px",
          "text-xl": "20px",
          "text-2xl": "24px",
          "text-3xl": "30px",
          "text-4xl": "36px",
          "text-5xl": "48px",
          "text-6xl": "60px",
          "text-7xl": "72px",
          "text-8xl": "96px",
          "text-9xl": "128px",
        },
        options: [
          "text-xs",
          "text-sm",
          "text-base",
          "text-lg",
          "text-xl",
          "text-2xl",
          "text-3xl",
          "text-4xl",
          "text-5xl",
          "text-6xl",
          "text-7xl",
          "text-8xl",
          "text-9xl",
        ],
      },
      {
        key: "font-style",
        placeholder: "Font Style",
        options: ["italic", "non-italic"],
      },
      {
        key: "font-weight",
        placeholder: "Font Weight",
        notAdvanced: true,
        options: [
          "font-thin",
          "font-extralight",
          "font-light",
          "font-normal",
          "font-medium",
          "font-semibold",
          "font-bold",
          "font-extrabold",
          "font-black",
        ],
      },
      {
        key: "letter-spacing",
        placeholder: "Letter Spacing",
        options: [
          "tracking-tighter",
          "tracking-tight",
          "tracking-normal",
          "tracking-wide",
          "tracking-wider",
          "tracking-widest",
        ],
      },
      {
        key: "line-height",
        placeholder: "Line Height",
        options: [
          "leading-3",
          "leading-4",
          "leading-5",
          "leading-6",
          "leading-7",
          "leading-8",
          "leading-9",
          "leading-10",
          "leading-none",
          "leading-tight",
          "leading-snug",
          "leading-normal",
          "leading-relaxed",
          "leading-loose",
        ],
      },
      {
        key: "text-align",
        placeholder: "Text Align",
        notAdvanced: true,
        options: [
          "text-left",
          "text-center",
          "text-right",
          "text-justify",
          "text-start",
          "text-end",
        ],
      },
      {
        key: "text-color",
        placeholder: "Text Color",
        options: [],
        inputType: "color-picker",
        prefix: "text",
      },
      {
        key: "text-decoration",
        placeholder: "Text Decoration",
        options: ["underline", "overline", "line-through", "no-underline"],
      },
      {
        key: "text-transform",
        placeholder: "Text Transform",
        options: ["uppercase", "lowercase", "capitalize", "normal-case"],
      },
      {
        key: "text-overflow",
        placeholder: "Text Overflow",
        options: [
          "truncate",
          "text-overflow",
          "white-space",
          "text-ellipsis",
          "text-clip",
        ],
      },
      {
        key: "text-indent",
        placeholder: "Text Indent",
        options: [
          "indent-0",
          "indent-px",
          "indent-0.5",
          "indent-1",
          "indent-1.5",
          "indent-2",
          "indent-2.5",
          "indent-3",
          "indent-3.5",
          "indent-4",
          "indent-5",
          "indent-6",
          "indent-7",
          "indent-8",
          "indent-9",
          "indent-10",
          "indent-11",
          "indent-12",
          "indent-14",
          "indent-16",
          "indent-20",
          "indent-24",
          "indent-28",
          "indent-32",
          "indent-36",
          "indent-40",
          "indent-44",
          "indent-48",
          "indent-52",
          "indent-56",
          "indent-60",
          "indent-64",
          "indent-72",
          "indent-80",
          "indent-96",
        ],
      },
      {
        key: "whitespace",
        placeholder: "Whitespace",
        options: [
          "whitespace-normal",
          "whitespace-nowrap",
          "whitespace-pre",
          "whitespace-pre-line",
          "whitespace-pre-wrap",
          "whitespace-break-spaces",
        ],
      },
      {
        key: "word-break",
        placeholder: "Word Break",
        options: [
          "break-normal",
          "word-break",
          "break-words",
          "break-all",
          "break-keep",
        ],
      },
    ],
  },
  {
    title: "Borders",
    properties: [
      {
        key: "border-radius",
        placeholder: "Border Radius",
        notAdvanced: true,
        options: [
          "rounded-none",
          "rounded-sm",
          "rounded",
          "rounded-md",
          "rounded-lg",
          "rounded-xl",
          "rounded-2xl",
          "rounded-3xl",
        ],
      },
      {
        key: "border-width",
        placeholder: "Border Width",
        notAdvanced: true,
        options: [
          "border-0",
          "border-2",
          "border-4",
          "border-8",
          "border",
          "border-x-0",
          "border-x-2",
        ],
      },
      {
        key: "border-style",
        placeholder: "Border Style",
        options: [
          "border-solid",
          "border-dashed",
          "border-dotted",
          "border-double",
          "border-hidden",
          "border-none",
        ],
      },
      {
        key: "border-color",
        placeholder: "Border Color",
        notAdvanced: true,
        options: [
          "border-inherit",
          "border-current",
          "border-transparent",
          "border-black",
          "border-white",
          "border-slate-50",
          "border-slate-100",
          "border-slate-200",
          "border-slate-300",
          "border-slate-400",
          "border-slate-500",
          "border-slate-600",
          "border-slate-700",
          "border-slate-800",
          "border-slate-900",
          "border-slate-950",
          "border-gray-50",
          "border-gray-100",
          "border-gray-200",
          "border-gray-300",
          "border-gray-400",
          "border-gray-500",
          "border-gray-600",
          "border-gray-700",
          "border-gray-800",
          "border-gray-900",
          "border-gray-950",
          "border-zinc-50",
          "border-zinc-100",
          "border-zinc-200",
          "border-zinc-300",
          "border-zinc-400",
          "border-zinc-500",
          "border-zinc-600",
          "border-zinc-700",
          "border-zinc-800",
          "border-zinc-900",
          "border-zinc-950",
          "border-neutral-50",
          "border-neutral-100",
          "border-neutral-200",
          "border-neutral-300",
          "border-neutral-400",
          "border-neutral-500",
          "border-neutral-600",
          "border-neutral-700",
          "border-neutral-800",
          "border-neutral-900",
          "border-neutral-950",
          "border-stone-50",
          "border-stone-100",
          "border-stone-200",
          "border-stone-300",
          "border-stone-400",
          "border-stone-500",
          "border-stone-600",
          "border-stone-700",
          "border-stone-800",
          "border-stone-900",
          "border-stone-950",
          "border-red-50",
          "border-red-100",
          "border-red-200",
          "border-red-300",
          "border-red-400",
          "border-red-500",
          "border-red-600",
          "border-red-700",
          "border-red-800",
          "border-red-900",
          "border-red-950",
          "border-orange-50",
          "border-orange-100",
          "border-orange-200",
          "border-orange-300",
          "border-orange-400",
          "border-orange-500",
          "border-orange-600",
          "border-orange-700",
          "border-orange-800",
          "border-orange-900",
          "border-orange-950",
          "border-amber-50",
          "border-amber-100",
          "border-amber-200",
          "border-amber-300",
          "border-amber-400",
          "border-amber-500",
          "border-amber-600",
          "border-amber-700",
          "border-amber-800",
          "border-amber-900",
          "border-amber-950",
          "border-yellow-50",
          "border-yellow-100",
          "border-yellow-200",
          "border-yellow-300",
          "border-yellow-400",
          "border-yellow-500",
          "border-yellow-600",
          "border-yellow-700",
          "border-yellow-800",
          "border-yellow-900",
          "border-yellow-950",
          "border-lime-50",
          "border-lime-100",
          "border-lime-200",
          "border-lime-300",
          "border-lime-400",
          "border-lime-500",
          "border-lime-600",
          "border-lime-700",
          "border-lime-800",
          "border-lime-900",
          "border-lime-950",
          "border-green-50",
          "border-green-100",
          "border-green-200",
          "border-green-300",
          "border-green-400",
          "border-green-500",
          "border-green-600",
          "border-green-700",
          "border-green-800",
          "border-green-900",
          "border-green-950",
          "border-emerald-50",
          "border-emerald-100",
          "border-emerald-200",
          "border-emerald-300",
          "border-emerald-400",
          "border-emerald-500",
          "border-emerald-600",
          "border-emerald-700",
          "border-emerald-800",
          "border-emerald-900",
          "border-emerald-950",
          "border-teal-50",
          "border-teal-100",
          "border-teal-200",
          "border-teal-300",
          "border-teal-400",
          "border-teal-500",
          "border-teal-600",
          "border-teal-700",
          "border-teal-800",
          "border-teal-900",
          "border-teal-950",
          "border-cyan-50",
          "border-cyan-100",
          "border-cyan-200",
          "border-cyan-300",
          "border-cyan-400",
          "border-cyan-500",
          "border-cyan-600",
          "border-cyan-700",
          "border-cyan-800",
          "border-cyan-900",
          "border-cyan-950",
          "border-sky-50",
          "border-sky-100",
          "border-sky-200",
          "border-sky-300",
          "border-sky-400",
          "border-sky-500",
          "border-sky-600",
          "border-sky-700",
          "border-sky-800",
          "border-sky-900",
          "border-sky-950",
          "border-blue-50",
          "border-blue-100",
          "border-blue-200",
          "border-blue-300",
          "border-blue-400",
          "border-blue-500",
          "border-blue-600",
          "border-blue-700",
          "border-blue-800",
          "border-blue-900",
          "border-blue-950",
          "border-indigo-50",
          "border-indigo-100",
          "border-indigo-200",
          "border-indigo-300",
          "border-indigo-400",
          "border-indigo-500",
          "border-indigo-600",
          "border-indigo-700",
          "border-indigo-800",
          "border-indigo-900",
          "border-indigo-950",
          "border-violet-50",
          "border-violet-100",
          "border-violet-200",
          "border-violet-300",
          "border-violet-400",
          "border-violet-500",
          "border-violet-600",
          "border-violet-700",
          "border-violet-800",
          "border-violet-900",
          "border-violet-950",
          "border-purple-50",
          "border-purple-100",
          "border-purple-200",
          "border-purple-300",
          "border-purple-400",
          "border-purple-500",
          "border-purple-600",
          "border-purple-700",
          "border-purple-800",
          "border-purple-900",
          "border-purple-950",
          "border-fuchsia-50",
          "border-fuchsia-100",
          "border-fuchsia-200",
          "border-fuchsia-300",
          "border-fuchsia-400",
          "border-fuchsia-500",
          "border-fuchsia-600",
          "border-fuchsia-700",
          "border-fuchsia-800",
          "border-fuchsia-900",
          "border-fuchsia-950",
          "border-pink-50",
          "border-pink-100",
          "border-pink-200",
          "border-pink-300",
          "border-pink-400",
          "border-pink-500",
          "border-pink-600",
          "border-pink-700",
          "border-pink-800",
          "border-pink-900",
          "border-pink-950",
          "border-rose-50",
          "border-rose-100",
          "border-rose-200",
          "border-rose-300",
          "border-rose-400",
          "border-rose-500",
          "border-rose-600",
          "border-rose-700",
          "border-rose-800",
          "border-rose-900",
          "border-rose-950",
          "border-x-inherit",
          "border-x-current",
          "border-x-transparent",
          "border-x-black",
          "border-x-white",
          "border-x-slate-50",
          "border-x-slate-100",
          "border-x-slate-200",
          "border-x-slate-300",
          "border-x-slate-400",
          "border-x-slate-500",
          "border-x-slate-600",
          "border-x-slate-700",
          "border-x-slate-800",
          "border-x-slate-900",
          "border-x-slate-950",
          "border-x-gray-50",
          "border-x-gray-100",
          "border-x-gray-200",
          "border-x-gray-300",
          "border-x-gray-400",
          "border-x-gray-500",
          "border-x-gray-600",
          "border-x-gray-700",
          "border-x-gray-800",
          "border-x-gray-900",
          "border-x-gray-950",
          "border-x-zinc-50",
          "border-x-zinc-100",
          "border-x-zinc-200",
          "border-x-zinc-300",
          "border-x-zinc-400",
          "border-x-zinc-500",
          "border-x-zinc-600",
          "border-x-zinc-700",
          "border-x-zinc-800",
          "border-x-zinc-900",
          "border-x-zinc-950",
          "border-x-neutral-50",
          "border-x-neutral-100",
          "border-x-neutral-200",
          "border-x-neutral-300",
          "border-x-neutral-400",
          "border-x-neutral-500",
          "border-x-neutral-600",
          "border-x-neutral-700",
          "border-x-neutral-800",
          "border-x-neutral-900",
          "border-x-neutral-950",
          "border-x-stone-50",
          "border-x-stone-100",
          "border-x-stone-200",
          "border-x-stone-300",
          "border-x-stone-400",
          "border-x-stone-500",
          "border-x-stone-600",
          "border-x-stone-700",
          "border-x-stone-800",
          "border-x-stone-900",
          "border-x-stone-950",
          "border-x-red-50",
          "border-x-red-100",
          "border-x-red-200",
          "border-x-red-300",
          "border-x-red-400",
          "border-x-red-500",
          "border-x-red-600",
          "border-x-red-700",
          "border-x-red-800",
          "border-x-red-900",
          "border-x-red-950",
          "border-x-orange-50",
          "border-x-orange-100",
          "border-x-orange-200",
          "border-x-orange-300",
          "border-x-orange-400",
          "border-x-orange-500",
          "border-x-orange-600",
          "border-x-orange-700",
          "border-x-orange-800",
          "border-x-orange-900",
          "border-x-orange-950",
          "border-x-amber-50",
          "border-x-amber-100",
          "border-x-amber-200",
          "border-x-amber-300",
          "border-x-amber-400",
          "border-x-amber-500",
          "border-x-amber-600",
          "border-x-amber-700",
          "border-x-amber-800",
          "border-x-amber-900",
          "border-x-amber-950",
          "border-x-yellow-50",
          "border-x-yellow-100",
          "border-x-yellow-200",
          "border-x-yellow-300",
          "border-x-yellow-400",
          "border-x-yellow-500",
          "border-x-yellow-600",
          "border-x-yellow-700",
          "border-x-yellow-800",
          "border-x-yellow-900",
          "border-x-yellow-950",
          "border-x-lime-50",
          "border-x-lime-100",
          "border-x-lime-200",
          "border-x-lime-300",
          "border-x-lime-400",
          "border-x-lime-500",
          "border-x-lime-600",
          "border-x-lime-700",
          "border-x-lime-800",
          "border-x-lime-900",
          "border-x-lime-950",
          "border-x-green-50",
          "border-x-green-100",
          "border-x-green-200",
          "border-x-green-300",
          "border-x-green-400",
          "border-x-green-500",
          "border-x-green-600",
          "border-x-green-700",
          "border-x-green-800",
          "border-x-green-900",
          "border-x-green-950",
          "border-x-emerald-50",
          "border-x-emerald-100",
          "border-x-emerald-200",
          "border-x-emerald-300",
          "border-x-emerald-400",
          "border-x-emerald-500",
          "border-x-emerald-600",
          "border-x-emerald-700",
          "border-x-emerald-800",
          "border-x-emerald-900",
          "border-x-emerald-950",
          "border-x-teal-50",
          "border-x-teal-100",
          "border-x-teal-200",
          "border-x-teal-300",
          "border-x-teal-400",
          "border-x-teal-500",
          "border-x-teal-600",
          "border-x-teal-700",
          "border-x-teal-800",
          "border-x-teal-900",
          "border-x-teal-950",
          "border-x-cyan-50",
          "border-x-cyan-100",
          "border-x-cyan-200",
          "border-x-cyan-300",
          "border-x-cyan-400",
          "border-x-cyan-500",
          "border-x-cyan-600",
          "border-x-cyan-700",
          "border-x-cyan-800",
          "border-x-cyan-900",
          "border-x-cyan-950",
          "border-x-sky-50",
          "border-x-sky-100",
          "border-x-sky-200",
          "border-x-sky-300",
          "border-x-sky-400",
          "border-x-sky-500",
          "border-x-sky-600",
          "border-x-sky-700",
          "border-x-sky-800",
          "border-x-sky-900",
          "border-x-sky-950",
          "border-x-blue-50",
          "border-x-blue-100",
          "border-x-blue-200",
          "border-x-blue-300",
          "border-x-blue-400",
          "border-x-blue-500",
          "border-x-blue-600",
          "border-x-blue-700",
          "border-x-blue-800",
          "border-x-blue-900",
          "border-x-blue-950",
          "border-x-indigo-50",
          "border-x-indigo-100",
          "border-x-indigo-200",
          "border-x-indigo-300",
          "border-x-indigo-400",
          "border-x-indigo-500",
          "border-x-indigo-600",
          "border-x-indigo-700",
          "border-x-indigo-800",
          "border-x-indigo-900",
          "border-x-indigo-950",
          "border-x-violet-50",
          "border-x-violet-100",
          "border-x-violet-200",
          "border-x-violet-300",
          "border-x-violet-400",
          "border-x-violet-500",
          "border-x-violet-600",
          "border-x-violet-700",
          "border-x-violet-800",
          "border-x-violet-900",
          "border-x-violet-950",
          "border-x-purple-50",
          "border-x-purple-100",
          "border-x-purple-200",
          "border-x-purple-300",
          "border-x-purple-400",
          "border-x-purple-500",
          "border-x-purple-600",
          "border-x-purple-700",
          "border-x-purple-800",
          "border-x-purple-900",
          "border-x-purple-950",
          "border-x-fuchsia-50",
          "border-x-fuchsia-100",
          "border-x-fuchsia-200",
          "border-x-fuchsia-300",
          "border-x-fuchsia-400",
          "border-x-fuchsia-500",
          "border-x-fuchsia-600",
          "border-x-fuchsia-700",
          "border-x-fuchsia-800",
          "border-x-fuchsia-900",
          "border-x-fuchsia-950",
          "border-x-pink-50",
          "border-x-pink-100",
          "border-x-pink-200",
          "border-x-pink-300",
          "border-x-pink-400",
          "border-x-pink-500",
          "border-x-pink-600",
          "border-x-pink-700",
          "border-x-pink-800",
          "border-x-pink-900",
          "border-x-pink-950",
          "border-x-rose-50",
          "border-x-rose-100",
          "border-x-rose-200",
          "border-x-rose-300",
          "border-x-rose-400",
          "border-x-rose-500",
          "border-x-rose-600",
          "border-x-rose-700",
          "border-x-rose-800",
          "border-x-rose-900",
          "border-x-rose-950",
          "border-y-inherit",
          "border-y-current",
          "border-y-transparent",
          "border-y-black",
          "border-y-white",
          "border-y-slate-50",
          "border-y-slate-100",
          "border-y-slate-200",
          "border-y-slate-300",
          "border-y-slate-400",
          "border-y-slate-500",
          "border-y-slate-600",
          "border-y-slate-700",
          "border-y-slate-800",
          "border-y-slate-900",
          "border-y-slate-950",
          "border-y-gray-50",
          "border-y-gray-100",
          "border-y-gray-200",
          "border-y-gray-300",
          "border-y-gray-400",
          "border-y-gray-500",
          "border-y-gray-600",
          "border-y-gray-700",
          "border-y-gray-800",
          "border-y-gray-900",
          "border-y-gray-950",
          "border-y-zinc-50",
          "border-y-zinc-100",
          "border-y-zinc-200",
          "border-y-zinc-300",
          "border-y-zinc-400",
          "border-y-zinc-500",
          "border-y-zinc-600",
          "border-y-zinc-700",
          "border-y-zinc-800",
          "border-y-zinc-900",
          "border-y-zinc-950",
          "border-y-neutral-50",
          "border-y-neutral-100",
          "border-y-neutral-200",
          "border-y-neutral-300",
          "border-y-neutral-400",
          "border-y-neutral-500",
          "border-y-neutral-600",
          "border-y-neutral-700",
          "border-y-neutral-800",
          "border-y-neutral-900",
          "border-y-neutral-950",
          "border-y-stone-50",
          "border-y-stone-100",
          "border-y-stone-200",
          "border-y-stone-300",
          "border-y-stone-400",
          "border-y-stone-500",
          "border-y-stone-600",
          "border-y-stone-700",
          "border-y-stone-800",
          "border-y-stone-900",
          "border-y-stone-950",
          "border-y-red-50",
          "border-y-red-100",
          "border-y-red-200",
          "border-y-red-300",
          "border-y-red-400",
          "border-y-red-500",
          "border-y-red-600",
          "border-y-red-700",
          "border-y-red-800",
          "border-y-red-900",
          "border-y-red-950",
          "border-y-orange-50",
          "border-y-orange-100",
          "border-y-orange-200",
          "border-y-orange-300",
          "border-y-orange-400",
          "border-y-orange-500",
          "border-y-orange-600",
          "border-y-orange-700",
          "border-y-orange-800",
          "border-y-orange-900",
          "border-y-orange-950",
          "border-y-amber-50",
          "border-y-amber-100",
          "border-y-amber-200",
          "border-y-amber-300",
          "border-y-amber-400",
          "border-y-amber-500",
          "border-y-amber-600",
          "border-y-amber-700",
          "border-y-amber-800",
          "border-y-amber-900",
          "border-y-amber-950",
          "border-y-yellow-50",
          "border-y-yellow-100",
          "border-y-yellow-200",
          "border-y-yellow-300",
          "border-y-yellow-400",
          "border-y-yellow-500",
          "border-y-yellow-600",
          "border-y-yellow-700",
          "border-y-yellow-800",
          "border-y-yellow-900",
          "border-y-yellow-950",
          "border-y-lime-50",
          "border-y-lime-100",
          "border-y-lime-200",
          "border-y-lime-300",
          "border-y-lime-400",
          "border-y-lime-500",
          "border-y-lime-600",
          "border-y-lime-700",
          "border-y-lime-800",
          "border-y-lime-900",
          "border-y-lime-950",
          "border-y-green-50",
          "border-y-green-100",
          "border-y-green-200",
          "border-y-green-300",
          "border-y-green-400",
          "border-y-green-500",
          "border-y-green-600",
          "border-y-green-700",
          "border-y-green-800",
          "border-y-green-900",
          "border-y-green-950",
          "border-y-emerald-50",
          "border-y-emerald-100",
          "border-y-emerald-200",
          "border-y-emerald-300",
          "border-y-emerald-400",
          "border-y-emerald-500",
          "border-y-emerald-600",
          "border-y-emerald-700",
          "border-y-emerald-800",
          "border-y-emerald-900",
          "border-y-emerald-950",
          "border-y-teal-50",
          "border-y-teal-100",
          "border-y-teal-200",
          "border-y-teal-300",
          "border-y-teal-400",
          "border-y-teal-500",
          "border-y-teal-600",
          "border-y-teal-700",
          "border-y-teal-800",
          "border-y-teal-900",
          "border-y-teal-950",
          "border-y-cyan-50",
          "border-y-cyan-100",
          "border-y-cyan-200",
          "border-y-cyan-300",
          "border-y-cyan-400",
          "border-y-cyan-500",
          "border-y-cyan-600",
          "border-y-cyan-700",
          "border-y-cyan-800",
          "border-y-cyan-900",
          "border-y-cyan-950",
          "border-y-sky-50",
          "border-y-sky-100",
          "border-y-sky-200",
          "border-y-sky-300",
          "border-y-sky-400",
          "border-y-sky-500",
          "border-y-sky-600",
          "border-y-sky-700",
          "border-y-sky-800",
          "border-y-sky-900",
          "border-y-sky-950",
          "border-y-blue-50",
          "border-y-blue-100",
          "border-y-blue-200",
          "border-y-blue-300",
          "border-y-blue-400",
          "border-y-blue-500",
          "border-y-blue-600",
          "border-y-blue-700",
          "border-y-blue-800",
          "border-y-blue-900",
          "border-y-blue-950",
          "border-y-indigo-50",
          "border-y-indigo-100",
          "border-y-indigo-200",
          "border-y-indigo-300",
          "border-y-indigo-400",
          "border-y-indigo-500",
          "border-y-indigo-600",
          "border-y-indigo-700",
          "border-y-indigo-800",
          "border-y-indigo-900",
          "border-y-indigo-950",
          "border-y-violet-50",
          "border-y-violet-100",
          "border-y-violet-200",
          "border-y-violet-300",
          "border-y-violet-400",
          "border-y-violet-500",
          "border-y-violet-600",
          "border-y-violet-700",
          "border-y-violet-800",
          "border-y-violet-900",
          "border-y-violet-950",
          "border-y-purple-50",
          "border-y-purple-100",
          "border-y-purple-200",
          "border-y-purple-300",
          "border-y-purple-400",
          "border-y-purple-500",
          "border-y-purple-600",
          "border-y-purple-700",
          "border-y-purple-800",
          "border-y-purple-900",
          "border-y-purple-950",
          "border-y-fuchsia-50",
          "border-y-fuchsia-100",
          "border-y-fuchsia-200",
          "border-y-fuchsia-300",
          "border-y-fuchsia-400",
          "border-y-fuchsia-500",
          "border-y-fuchsia-600",
          "border-y-fuchsia-700",
          "border-y-fuchsia-800",
          "border-y-fuchsia-900",
          "border-y-fuchsia-950",
          "border-y-pink-50",
          "border-y-pink-100",
          "border-y-pink-200",
          "border-y-pink-300",
          "border-y-pink-400",
          "border-y-pink-500",
          "border-y-pink-600",
          "border-y-pink-700",
          "border-y-pink-800",
          "border-y-pink-900",
          "border-y-pink-950",
          "border-y-rose-50",
          "border-y-rose-100",
          "border-y-rose-200",
          "border-y-rose-300",
          "border-y-rose-400",
          "border-y-rose-500",
          "border-y-rose-600",
          "border-y-rose-700",
          "border-y-rose-800",
          "border-y-rose-900",
          "border-y-rose-950",
          "border-s-inherit",
          "border-s-current",
          "border-s-transparent",
          "border-s-black",
          "border-s-white",
          "border-s-slate-50",
          "border-s-slate-100",
          "border-s-slate-200",
          "border-s-slate-300",
          "border-s-slate-400",
          "border-s-slate-500",
          "border-s-slate-600",
          "border-s-slate-700",
          "border-s-slate-800",
          "border-s-slate-900",
          "border-s-slate-950",
          "border-s-gray-50",
          "border-s-gray-100",
          "border-s-gray-200",
          "border-s-gray-300",
          "border-s-gray-400",
          "border-s-gray-500",
          "border-s-gray-600",
          "border-s-gray-700",
          "border-s-gray-800",
          "border-s-gray-900",
          "border-s-gray-950",
          "border-s-zinc-50",
          "border-s-zinc-100",
          "border-s-zinc-200",
          "border-s-zinc-300",
          "border-s-zinc-400",
          "border-s-zinc-500",
          "border-s-zinc-600",
          "border-s-zinc-700",
          "border-s-zinc-800",
          "border-s-zinc-900",
          "border-s-zinc-950",
          "border-s-neutral-50",
          "border-s-neutral-100",
          "border-s-neutral-200",
          "border-s-neutral-300",
          "border-s-neutral-400",
          "border-s-neutral-500",
          "border-s-neutral-600",
          "border-s-neutral-700",
          "border-s-neutral-800",
          "border-s-neutral-900",
          "border-s-neutral-950",
          "border-s-stone-50",
          "border-s-stone-100",
          "border-s-stone-200",
          "border-s-stone-300",
          "border-s-stone-400",
          "border-s-stone-500",
          "border-s-stone-600",
          "border-s-stone-700",
          "border-s-stone-800",
          "border-s-stone-900",
          "border-s-stone-950",
          "border-s-red-50",
          "border-s-red-100",
          "border-s-red-200",
          "border-s-red-300",
          "border-s-red-400",
          "border-s-red-500",
          "border-s-red-600",
          "border-s-red-700",
          "border-s-red-800",
          "border-s-red-900",
          "border-s-red-950",
          "border-s-orange-50",
          "border-s-orange-100",
          "border-s-orange-200",
          "border-s-orange-300",
          "border-s-orange-400",
          "border-s-orange-500",
          "border-s-orange-600",
          "border-s-orange-700",
          "border-s-orange-800",
          "border-s-orange-900",
          "border-s-orange-950",
          "border-s-amber-50",
          "border-s-amber-100",
          "border-s-amber-200",
          "border-s-amber-300",
          "border-s-amber-400",
          "border-s-amber-500",
          "border-s-amber-600",
          "border-s-amber-700",
          "border-s-amber-800",
          "border-s-amber-900",
          "border-s-amber-950",
          "border-s-yellow-50",
          "border-s-yellow-100",
          "border-s-yellow-200",
          "border-s-yellow-300",
          "border-s-yellow-400",
          "border-s-yellow-500",
          "border-s-yellow-600",
          "border-s-yellow-700",
          "border-s-yellow-800",
          "border-s-yellow-900",
          "border-s-yellow-950",
          "border-s-lime-50",
          "border-s-lime-100",
          "border-s-lime-200",
          "border-s-lime-300",
          "border-s-lime-400",
          "border-s-lime-500",
          "border-s-lime-600",
          "border-s-lime-700",
          "border-s-lime-800",
          "border-s-lime-900",
          "border-s-lime-950",
          "border-s-green-50",
          "border-s-green-100",
          "border-s-green-200",
          "border-s-green-300",
          "border-s-green-400",
          "border-s-green-500",
          "border-s-green-600",
          "border-s-green-700",
          "border-s-green-800",
          "border-s-green-900",
          "border-s-green-950",
          "border-s-emerald-50",
          "border-s-emerald-100",
          "border-s-emerald-200",
          "border-s-emerald-300",
          "border-s-emerald-400",
          "border-s-emerald-500",
          "border-s-emerald-600",
          "border-s-emerald-700",
          "border-s-emerald-800",
          "border-s-emerald-900",
          "border-s-emerald-950",
          "border-s-teal-50",
          "border-s-teal-100",
          "border-s-teal-200",
          "border-s-teal-300",
          "border-s-teal-400",
          "border-s-teal-500",
          "border-s-teal-600",
          "border-s-teal-700",
          "border-s-teal-800",
          "border-s-teal-900",
          "border-s-teal-950",
          "border-s-cyan-50",
          "border-s-cyan-100",
          "border-s-cyan-200",
          "border-s-cyan-300",
          "border-s-cyan-400",
          "border-s-cyan-500",
          "border-s-cyan-600",
          "border-s-cyan-700",
          "border-s-cyan-800",
          "border-s-cyan-900",
          "border-s-cyan-950",
          "border-s-sky-50",
          "border-s-sky-100",
          "border-s-sky-200",
          "border-s-sky-300",
          "border-s-sky-400",
          "border-s-sky-500",
          "border-s-sky-600",
          "border-s-sky-700",
          "border-s-sky-800",
          "border-s-sky-900",
          "border-s-sky-950",
          "border-s-blue-50",
          "border-s-blue-100",
          "border-s-blue-200",
          "border-s-blue-300",
          "border-s-blue-400",
          "border-s-blue-500",
          "border-s-blue-600",
          "border-s-blue-700",
          "border-s-blue-800",
          "border-s-blue-900",
          "border-s-blue-950",
          "border-s-indigo-50",
          "border-s-indigo-100",
          "border-s-indigo-200",
          "border-s-indigo-300",
          "border-s-indigo-400",
          "border-s-indigo-500",
          "border-s-indigo-600",
          "border-s-indigo-700",
          "border-s-indigo-800",
          "border-s-indigo-900",
          "border-s-indigo-950",
          "border-s-violet-50",
          "border-s-violet-100",
          "border-s-violet-200",
          "border-s-violet-300",
          "border-s-violet-400",
          "border-s-violet-500",
          "border-s-violet-600",
          "border-s-violet-700",
          "border-s-violet-800",
          "border-s-violet-900",
          "border-s-violet-950",
          "border-s-purple-50",
          "border-s-purple-100",
          "border-s-purple-200",
          "border-s-purple-300",
          "border-s-purple-400",
          "border-s-purple-500",
          "border-s-purple-600",
          "border-s-purple-700",
          "border-s-purple-800",
          "border-s-purple-900",
          "border-s-purple-950",
          "border-s-fuchsia-50",
          "border-s-fuchsia-100",
          "border-s-fuchsia-200",
          "border-s-fuchsia-300",
          "border-s-fuchsia-400",
          "border-s-fuchsia-500",
          "border-s-fuchsia-600",
          "border-s-fuchsia-700",
          "border-s-fuchsia-800",
          "border-s-fuchsia-900",
          "border-s-fuchsia-950",
          "border-s-pink-50",
          "border-s-pink-100",
          "border-s-pink-200",
          "border-s-pink-300",
          "border-s-pink-400",
          "border-s-pink-500",
          "border-s-pink-600",
          "border-s-pink-700",
          "border-s-pink-800",
          "border-s-pink-900",
          "border-s-pink-950",
          "border-s-rose-50",
          "border-s-rose-100",
          "border-s-rose-200",
          "border-s-rose-300",
          "border-s-rose-400",
          "border-s-rose-500",
          "border-s-rose-600",
          "border-s-rose-700",
          "border-s-rose-800",
          "border-s-rose-900",
          "border-s-rose-950",
          "border-e-inherit",
          "border-e-current",
          "border-e-transparent",
          "border-e-black",
          "border-e-white",
          "border-e-slate-50",
          "border-e-slate-100",
          "border-e-slate-200",
          "border-e-slate-300",
          "border-e-slate-400",
          "border-e-slate-500",
          "border-e-slate-600",
          "border-e-slate-700",
          "border-e-slate-800",
          "border-e-slate-900",
          "border-e-slate-950",
          "border-e-gray-50",
          "border-e-gray-100",
          "border-e-gray-200",
          "border-e-gray-300",
          "border-e-gray-400",
          "border-e-gray-500",
          "border-e-gray-600",
          "border-e-gray-700",
          "border-e-gray-800",
          "border-e-gray-900",
          "border-e-gray-950",
          "border-e-zinc-50",
          "border-e-zinc-100",
          "border-e-zinc-200",
          "border-e-zinc-300",
          "border-e-zinc-400",
          "border-e-zinc-500",
          "border-e-zinc-600",
          "border-e-zinc-700",
          "border-e-zinc-800",
          "border-e-zinc-900",
          "border-e-zinc-950",
          "border-e-neutral-50",
          "border-e-neutral-100",
          "border-e-neutral-200",
          "border-e-neutral-300",
          "border-e-neutral-400",
          "border-e-neutral-500",
          "border-e-neutral-600",
          "border-e-neutral-700",
          "border-e-neutral-800",
          "border-e-neutral-900",
          "border-e-neutral-950",
          "border-e-stone-50",
          "border-e-stone-100",
          "border-e-stone-200",
          "border-e-stone-300",
          "border-e-stone-400",
          "border-e-stone-500",
          "border-e-stone-600",
          "border-e-stone-700",
          "border-e-stone-800",
          "border-e-stone-900",
          "border-e-stone-950",
          "border-e-red-50",
          "border-e-red-100",
          "border-e-red-200",
          "border-e-red-300",
          "border-e-red-400",
          "border-e-red-500",
          "border-e-red-600",
          "border-e-red-700",
          "border-e-red-800",
          "border-e-red-900",
          "border-e-red-950",
          "border-e-orange-50",
          "border-e-orange-100",
          "border-e-orange-200",
          "border-e-orange-300",
          "border-e-orange-400",
          "border-e-orange-500",
          "border-e-orange-600",
          "border-e-orange-700",
          "border-e-orange-800",
          "border-e-orange-900",
          "border-e-orange-950",
          "border-e-amber-50",
          "border-e-amber-100",
          "border-e-amber-200",
          "border-e-amber-300",
          "border-e-amber-400",
          "border-e-amber-500",
          "border-e-amber-600",
          "border-e-amber-700",
          "border-e-amber-800",
          "border-e-amber-900",
          "border-e-amber-950",
          "border-e-yellow-50",
          "border-e-yellow-100",
          "border-e-yellow-200",
          "border-e-yellow-300",
          "border-e-yellow-400",
          "border-e-yellow-500",
          "border-e-yellow-600",
          "border-e-yellow-700",
          "border-e-yellow-800",
          "border-e-yellow-900",
          "border-e-yellow-950",
          "border-e-lime-50",
          "border-e-lime-100",
          "border-e-lime-200",
          "border-e-lime-300",
          "border-e-lime-400",
          "border-e-lime-500",
          "border-e-lime-600",
          "border-e-lime-700",
          "border-e-lime-800",
          "border-e-lime-900",
          "border-e-lime-950",
          "border-e-green-50",
          "border-e-green-100",
          "border-e-green-200",
          "border-e-green-300",
          "border-e-green-400",
          "border-e-green-500",
          "border-e-green-600",
          "border-e-green-700",
          "border-e-green-800",
          "border-e-green-900",
          "border-e-green-950",
          "border-e-emerald-50",
          "border-e-emerald-100",
          "border-e-emerald-200",
          "border-e-emerald-300",
          "border-e-emerald-400",
          "border-e-emerald-500",
          "border-e-emerald-600",
          "border-e-emerald-700",
          "border-e-emerald-800",
          "border-e-emerald-900",
          "border-e-emerald-950",
          "border-e-teal-50",
          "border-e-teal-100",
          "border-e-teal-200",
          "border-e-teal-300",
          "border-e-teal-400",
          "border-e-teal-500",
          "border-e-teal-600",
          "border-e-teal-700",
          "border-e-teal-800",
          "border-e-teal-900",
          "border-e-teal-950",
          "border-e-cyan-50",
          "border-e-cyan-100",
          "border-e-cyan-200",
          "border-e-cyan-300",
          "border-e-cyan-400",
          "border-e-cyan-500",
          "border-e-cyan-600",
          "border-e-cyan-700",
          "border-e-cyan-800",
          "border-e-cyan-900",
          "border-e-cyan-950",
          "border-e-sky-50",
          "border-e-sky-100",
          "border-e-sky-200",
          "border-e-sky-300",
          "border-e-sky-400",
          "border-e-sky-500",
          "border-e-sky-600",
          "border-e-sky-700",
          "border-e-sky-800",
          "border-e-sky-900",
          "border-e-sky-950",
          "border-e-blue-50",
          "border-e-blue-100",
          "border-e-blue-200",
          "border-e-blue-300",
          "border-e-blue-400",
          "border-e-blue-500",
          "border-e-blue-600",
          "border-e-blue-700",
          "border-e-blue-800",
          "border-e-blue-900",
          "border-e-blue-950",
          "border-e-indigo-50",
          "border-e-indigo-100",
          "border-e-indigo-200",
          "border-e-indigo-300",
          "border-e-indigo-400",
          "border-e-indigo-500",
          "border-e-indigo-600",
          "border-e-indigo-700",
          "border-e-indigo-800",
          "border-e-indigo-900",
          "border-e-indigo-950",
          "border-e-violet-50",
          "border-e-violet-100",
          "border-e-violet-200",
          "border-e-violet-300",
          "border-e-violet-400",
          "border-e-violet-500",
          "border-e-violet-600",
          "border-e-violet-700",
          "border-e-violet-800",
          "border-e-violet-900",
          "border-e-violet-950",
          "border-e-purple-50",
          "border-e-purple-100",
          "border-e-purple-200",
          "border-e-purple-300",
          "border-e-purple-400",
          "border-e-purple-500",
          "border-e-purple-600",
          "border-e-purple-700",
          "border-e-purple-800",
          "border-e-purple-900",
          "border-e-purple-950",
          "border-e-fuchsia-50",
          "border-e-fuchsia-100",
          "border-e-fuchsia-200",
          "border-e-fuchsia-300",
          "border-e-fuchsia-400",
          "border-e-fuchsia-500",
          "border-e-fuchsia-600",
          "border-e-fuchsia-700",
          "border-e-fuchsia-800",
          "border-e-fuchsia-900",
          "border-e-fuchsia-950",
          "border-e-pink-50",
          "border-e-pink-100",
          "border-e-pink-200",
          "border-e-pink-300",
          "border-e-pink-400",
          "border-e-pink-500",
          "border-e-pink-600",
          "border-e-pink-700",
          "border-e-pink-800",
          "border-e-pink-900",
          "border-e-pink-950",
          "border-e-rose-50",
          "border-e-rose-100",
          "border-e-rose-200",
          "border-e-rose-300",
          "border-e-rose-400",
          "border-e-rose-500",
          "border-e-rose-600",
          "border-e-rose-700",
          "border-e-rose-800",
          "border-e-rose-900",
          "border-e-rose-950",
          "border-t-inherit",
          "border-t-current",
          "border-t-transparent",
          "border-t-black",
          "border-t-white",
          "border-t-slate-50",
          "border-t-slate-100",
          "border-t-slate-200",
          "border-t-slate-300",
          "border-t-slate-400",
          "border-t-slate-500",
          "border-t-slate-600",
          "border-t-slate-700",
          "border-t-slate-800",
          "border-t-slate-900",
          "border-t-slate-950",
          "border-t-gray-50",
          "border-t-gray-100",
          "border-t-gray-200",
          "border-t-gray-300",
          "border-t-gray-400",
          "border-t-gray-500",
          "border-t-gray-600",
          "border-t-gray-700",
          "border-t-gray-800",
          "border-t-gray-900",
          "border-t-gray-950",
          "border-t-zinc-50",
          "border-t-zinc-100",
          "border-t-zinc-200",
          "border-t-zinc-300",
          "border-t-zinc-400",
          "border-t-zinc-500",
          "border-t-zinc-600",
          "border-t-zinc-700",
          "border-t-zinc-800",
          "border-t-zinc-900",
          "border-t-zinc-950",
          "border-t-neutral-50",
          "border-t-neutral-100",
          "border-t-neutral-200",
          "border-t-neutral-300",
          "border-t-neutral-400",
          "border-t-neutral-500",
          "border-t-neutral-600",
          "border-t-neutral-700",
          "border-t-neutral-800",
          "border-t-neutral-900",
          "border-t-neutral-950",
          "border-t-stone-50",
          "border-t-stone-100",
          "border-t-stone-200",
          "border-t-stone-300",
          "border-t-stone-400",
          "border-t-stone-500",
          "border-t-stone-600",
          "border-t-stone-700",
          "border-t-stone-800",
          "border-t-stone-900",
          "border-t-stone-950",
          "border-t-red-50",
          "border-t-red-100",
          "border-t-red-200",
          "border-t-red-300",
          "border-t-red-400",
          "border-t-red-500",
          "border-t-red-600",
          "border-t-red-700",
          "border-t-red-800",
          "border-t-red-900",
          "border-t-red-950",
          "border-t-orange-50",
          "border-t-orange-100",
          "border-t-orange-200",
          "border-t-orange-300",
          "border-t-orange-400",
          "border-t-orange-500",
          "border-t-orange-600",
          "border-t-orange-700",
          "border-t-orange-800",
          "border-t-orange-900",
          "border-t-orange-950",
          "border-t-amber-50",
          "border-t-amber-100",
          "border-t-amber-200",
          "border-t-amber-300",
          "border-t-amber-400",
          "border-t-amber-500",
          "border-t-amber-600",
          "border-t-amber-700",
          "border-t-amber-800",
          "border-t-amber-900",
          "border-t-amber-950",
          "border-t-yellow-50",
          "border-t-yellow-100",
          "border-t-yellow-200",
          "border-t-yellow-300",
          "border-t-yellow-400",
          "border-t-yellow-500",
          "border-t-yellow-600",
          "border-t-yellow-700",
          "border-t-yellow-800",
          "border-t-yellow-900",
          "border-t-yellow-950",
          "border-t-lime-50",
          "border-t-lime-100",
          "border-t-lime-200",
          "border-t-lime-300",
          "border-t-lime-400",
          "border-t-lime-500",
          "border-t-lime-600",
          "border-t-lime-700",
          "border-t-lime-800",
          "border-t-lime-900",
          "border-t-lime-950",
          "border-t-green-50",
          "border-t-green-100",
          "border-t-green-200",
          "border-t-green-300",
          "border-t-green-400",
          "border-t-green-500",
          "border-t-green-600",
          "border-t-green-700",
          "border-t-green-800",
          "border-t-green-900",
          "border-t-green-950",
          "border-t-emerald-50",
          "border-t-emerald-100",
          "border-t-emerald-200",
          "border-t-emerald-300",
          "border-t-emerald-400",
          "border-t-emerald-500",
          "border-t-emerald-600",
          "border-t-emerald-700",
          "border-t-emerald-800",
          "border-t-emerald-900",
          "border-t-emerald-950",
          "border-t-teal-50",
          "border-t-teal-100",
          "border-t-teal-200",
          "border-t-teal-300",
          "border-t-teal-400",
          "border-t-teal-500",
          "border-t-teal-600",
          "border-t-teal-700",
          "border-t-teal-800",
          "border-t-teal-900",
          "border-t-teal-950",
          "border-t-cyan-50",
          "border-t-cyan-100",
          "border-t-cyan-200",
          "border-t-cyan-300",
          "border-t-cyan-400",
          "border-t-cyan-500",
          "border-t-cyan-600",
          "border-t-cyan-700",
          "border-t-cyan-800",
          "border-t-cyan-900",
          "border-t-cyan-950",
          "border-t-sky-50",
          "border-t-sky-100",
          "border-t-sky-200",
          "border-t-sky-300",
          "border-t-sky-400",
          "border-t-sky-500",
          "border-t-sky-600",
          "border-t-sky-700",
          "border-t-sky-800",
          "border-t-sky-900",
          "border-t-sky-950",
          "border-t-blue-50",
          "border-t-blue-100",
          "border-t-blue-200",
          "border-t-blue-300",
          "border-t-blue-400",
          "border-t-blue-500",
          "border-t-blue-600",
          "border-t-blue-700",
          "border-t-blue-800",
          "border-t-blue-900",
          "border-t-blue-950",
          "border-t-indigo-50",
          "border-t-indigo-100",
          "border-t-indigo-200",
          "border-t-indigo-300",
          "border-t-indigo-400",
          "border-t-indigo-500",
          "border-t-indigo-600",
          "border-t-indigo-700",
          "border-t-indigo-800",
          "border-t-indigo-900",
          "border-t-indigo-950",
          "border-t-violet-50",
          "border-t-violet-100",
          "border-t-violet-200",
          "border-t-violet-300",
          "border-t-violet-400",
          "border-t-violet-500",
          "border-t-violet-600",
          "border-t-violet-700",
          "border-t-violet-800",
          "border-t-violet-900",
          "border-t-violet-950",
          "border-t-purple-50",
          "border-t-purple-100",
          "border-t-purple-200",
          "border-t-purple-300",
          "border-t-purple-400",
          "border-t-purple-500",
          "border-t-purple-600",
          "border-t-purple-700",
          "border-t-purple-800",
          "border-t-purple-900",
          "border-t-purple-950",
          "border-t-fuchsia-50",
          "border-t-fuchsia-100",
          "border-t-fuchsia-200",
          "border-t-fuchsia-300",
          "border-t-fuchsia-400",
          "border-t-fuchsia-500",
          "border-t-fuchsia-600",
          "border-t-fuchsia-700",
          "border-t-fuchsia-800",
          "border-t-fuchsia-900",
          "border-t-fuchsia-950",
          "border-t-pink-50",
          "border-t-pink-100",
          "border-t-pink-200",
          "border-t-pink-300",
          "border-t-pink-400",
          "border-t-pink-500",
          "border-t-pink-600",
          "border-t-pink-700",
          "border-t-pink-800",
          "border-t-pink-900",
          "border-t-pink-950",
          "border-t-rose-50",
          "border-t-rose-100",
          "border-t-rose-200",
          "border-t-rose-300",
          "border-t-rose-400",
          "border-t-rose-500",
          "border-t-rose-600",
          "border-t-rose-700",
          "border-t-rose-800",
          "border-t-rose-900",
          "border-t-rose-950",
          "border-r-inherit",
          "border-r-current",
          "border-r-transparent",
          "border-r-black",
          "border-r-white",
          "border-r-slate-50",
          "border-r-slate-100",
          "border-r-slate-200",
          "border-r-slate-300",
          "border-r-slate-400",
          "border-r-slate-500",
          "border-r-slate-600",
          "border-r-slate-700",
          "border-r-slate-800",
          "border-r-slate-900",
          "border-r-slate-950",
          "border-r-gray-50",
          "border-r-gray-100",
          "border-r-gray-200",
          "border-r-gray-300",
          "border-r-gray-400",
          "border-r-gray-500",
          "border-r-gray-600",
          "border-r-gray-700",
          "border-r-gray-800",
          "border-r-gray-900",
          "border-r-gray-950",
          "border-r-zinc-50",
          "border-r-zinc-100",
          "border-r-zinc-200",
          "border-r-zinc-300",
          "border-r-zinc-400",
          "border-r-zinc-500",
          "border-r-zinc-600",
          "border-r-zinc-700",
          "border-r-zinc-800",
          "border-r-zinc-900",
          "border-r-zinc-950",
          "border-r-neutral-50",
          "border-r-neutral-100",
          "border-r-neutral-200",
          "border-r-neutral-300",
          "border-r-neutral-400",
          "border-r-neutral-500",
          "border-r-neutral-600",
          "border-r-neutral-700",
          "border-r-neutral-800",
          "border-r-neutral-900",
          "border-r-neutral-950",
          "border-r-stone-50",
          "border-r-stone-100",
          "border-r-stone-200",
          "border-r-stone-300",
          "border-r-stone-400",
          "border-r-stone-500",
          "border-r-stone-600",
          "border-r-stone-700",
          "border-r-stone-800",
          "border-r-stone-900",
          "border-r-stone-950",
          "border-r-red-50",
          "border-r-red-100",
          "border-r-red-200",
          "border-r-red-300",
          "border-r-red-400",
          "border-r-red-500",
          "border-r-red-600",
          "border-r-red-700",
          "border-r-red-800",
          "border-r-red-900",
          "border-r-red-950",
          "border-r-orange-50",
          "border-r-orange-100",
          "border-r-orange-200",
          "border-r-orange-300",
          "border-r-orange-400",
          "border-r-orange-500",
          "border-r-orange-600",
          "border-r-orange-700",
          "border-r-orange-800",
          "border-r-orange-900",
          "border-r-orange-950",
          "border-r-amber-50",
          "border-r-amber-100",
          "border-r-amber-200",
          "border-r-amber-300",
          "border-r-amber-400",
          "border-r-amber-500",
          "border-r-amber-600",
          "border-r-amber-700",
          "border-r-amber-800",
          "border-r-amber-900",
          "border-r-amber-950",
          "border-r-yellow-50",
          "border-r-yellow-100",
          "border-r-yellow-200",
          "border-r-yellow-300",
          "border-r-yellow-400",
          "border-r-yellow-500",
          "border-r-yellow-600",
          "border-r-yellow-700",
          "border-r-yellow-800",
          "border-r-yellow-900",
          "border-r-yellow-950",
          "border-r-lime-50",
          "border-r-lime-100",
          "border-r-lime-200",
          "border-r-lime-300",
          "border-r-lime-400",
          "border-r-lime-500",
          "border-r-lime-600",
          "border-r-lime-700",
          "border-r-lime-800",
          "border-r-lime-900",
          "border-r-lime-950",
          "border-r-green-50",
          "border-r-green-100",
          "border-r-green-200",
          "border-r-green-300",
          "border-r-green-400",
          "border-r-green-500",
          "border-r-green-600",
          "border-r-green-700",
          "border-r-green-800",
          "border-r-green-900",
          "border-r-green-950",
          "border-r-emerald-50",
          "border-r-emerald-100",
          "border-r-emerald-200",
          "border-r-emerald-300",
          "border-r-emerald-400",
          "border-r-emerald-500",
          "border-r-emerald-600",
          "border-r-emerald-700",
          "border-r-emerald-800",
          "border-r-emerald-900",
          "border-r-emerald-950",
          "border-r-teal-50",
          "border-r-teal-100",
          "border-r-teal-200",
          "border-r-teal-300",
          "border-r-teal-400",
          "border-r-teal-500",
          "border-r-teal-600",
          "border-r-teal-700",
          "border-r-teal-800",
          "border-r-teal-900",
          "border-r-teal-950",
          "border-r-cyan-50",
          "border-r-cyan-100",
          "border-r-cyan-200",
          "border-r-cyan-300",
          "border-r-cyan-400",
          "border-r-cyan-500",
          "border-r-cyan-600",
          "border-r-cyan-700",
          "border-r-cyan-800",
          "border-r-cyan-900",
          "border-r-cyan-950",
          "border-r-sky-50",
          "border-r-sky-100",
          "border-r-sky-200",
          "border-r-sky-300",
          "border-r-sky-400",
          "border-r-sky-500",
          "border-r-sky-600",
          "border-r-sky-700",
          "border-r-sky-800",
          "border-r-sky-900",
          "border-r-sky-950",
          "border-r-blue-50",
          "border-r-blue-100",
          "border-r-blue-200",
          "border-r-blue-300",
          "border-r-blue-400",
          "border-r-blue-500",
          "border-r-blue-600",
          "border-r-blue-700",
          "border-r-blue-800",
          "border-r-blue-900",
          "border-r-blue-950",
          "border-r-indigo-50",
          "border-r-indigo-100",
          "border-r-indigo-200",
          "border-r-indigo-300",
          "border-r-indigo-400",
          "border-r-indigo-500",
          "border-r-indigo-600",
          "border-r-indigo-700",
          "border-r-indigo-800",
          "border-r-indigo-900",
          "border-r-indigo-950",
          "border-r-violet-50",
          "border-r-violet-100",
          "border-r-violet-200",
          "border-r-violet-300",
          "border-r-violet-400",
          "border-r-violet-500",
          "border-r-violet-600",
          "border-r-violet-700",
          "border-r-violet-800",
          "border-r-violet-900",
          "border-r-violet-950",
          "border-r-purple-50",
          "border-r-purple-100",
          "border-r-purple-200",
          "border-r-purple-300",
          "border-r-purple-400",
          "border-r-purple-500",
          "border-r-purple-600",
          "border-r-purple-700",
          "border-r-purple-800",
          "border-r-purple-900",
          "border-r-purple-950",
          "border-r-fuchsia-50",
          "border-r-fuchsia-100",
          "border-r-fuchsia-200",
          "border-r-fuchsia-300",
          "border-r-fuchsia-400",
          "border-r-fuchsia-500",
          "border-r-fuchsia-600",
          "border-r-fuchsia-700",
          "border-r-fuchsia-800",
          "border-r-fuchsia-900",
          "border-r-fuchsia-950",
          "border-r-pink-50",
          "border-r-pink-100",
          "border-r-pink-200",
          "border-r-pink-300",
          "border-r-pink-400",
          "border-r-pink-500",
          "border-r-pink-600",
          "border-r-pink-700",
          "border-r-pink-800",
          "border-r-pink-900",
          "border-r-pink-950",
          "border-r-rose-50",
          "border-r-rose-100",
          "border-r-rose-200",
          "border-r-rose-300",
          "border-r-rose-400",
          "border-r-rose-500",
          "border-r-rose-600",
          "border-r-rose-700",
          "border-r-rose-800",
          "border-r-rose-900",
          "border-r-rose-950",
          "border-b-inherit",
          "border-b-current",
          "border-b-transparent",
          "border-b-black",
          "border-b-white",
          "border-b-slate-50",
          "border-b-slate-100",
          "border-b-slate-200",
          "border-b-slate-300",
          "border-b-slate-400",
          "border-b-slate-500",
          "border-b-slate-600",
          "border-b-slate-700",
          "border-b-slate-800",
          "border-b-slate-900",
          "border-b-slate-950",
          "border-b-gray-50",
          "border-b-gray-100",
          "border-b-gray-200",
          "border-b-gray-300",
          "border-b-gray-400",
          "border-b-gray-500",
          "border-b-gray-600",
          "border-b-gray-700",
          "border-b-gray-800",
          "border-b-gray-900",
          "border-b-gray-950",
          "border-b-zinc-50",
          "border-b-zinc-100",
          "border-b-zinc-200",
          "border-b-zinc-300",
          "border-b-zinc-400",
          "border-b-zinc-500",
          "border-b-zinc-600",
          "border-b-zinc-700",
          "border-b-zinc-800",
          "border-b-zinc-900",
          "border-b-zinc-950",
          "border-b-neutral-50",
          "border-b-neutral-100",
          "border-b-neutral-200",
          "border-b-neutral-300",
          "border-b-neutral-400",
          "border-b-neutral-500",
          "border-b-neutral-600",
          "border-b-neutral-700",
          "border-b-neutral-800",
          "border-b-neutral-900",
          "border-b-neutral-950",
          "border-b-stone-50",
          "border-b-stone-100",
          "border-b-stone-200",
          "border-b-stone-300",
          "border-b-stone-400",
          "border-b-stone-500",
          "border-b-stone-600",
          "border-b-stone-700",
          "border-b-stone-800",
          "border-b-stone-900",
          "border-b-stone-950",
          "border-b-red-50",
          "border-b-red-100",
          "border-b-red-200",
          "border-b-red-300",
          "border-b-red-400",
          "border-b-red-500",
          "border-b-red-600",
          "border-b-red-700",
          "border-b-red-800",
          "border-b-red-900",
          "border-b-red-950",
          "border-b-orange-50",
          "border-b-orange-100",
          "border-b-orange-200",
          "border-b-orange-300",
          "border-b-orange-400",
          "border-b-orange-500",
          "border-b-orange-600",
          "border-b-orange-700",
          "border-b-orange-800",
          "border-b-orange-900",
          "border-b-orange-950",
          "border-b-amber-50",
          "border-b-amber-100",
          "border-b-amber-200",
          "border-b-amber-300",
          "border-b-amber-400",
          "border-b-amber-500",
          "border-b-amber-600",
          "border-b-amber-700",
          "border-b-amber-800",
          "border-b-amber-900",
          "border-b-amber-950",
          "border-b-yellow-50",
          "border-b-yellow-100",
          "border-b-yellow-200",
          "border-b-yellow-300",
          "border-b-yellow-400",
          "border-b-yellow-500",
          "border-b-yellow-600",
          "border-b-yellow-700",
          "border-b-yellow-800",
          "border-b-yellow-900",
          "border-b-yellow-950",
          "border-b-lime-50",
          "border-b-lime-100",
          "border-b-lime-200",
          "border-b-lime-300",
          "border-b-lime-400",
          "border-b-lime-500",
          "border-b-lime-600",
          "border-b-lime-700",
          "border-b-lime-800",
          "border-b-lime-900",
          "border-b-lime-950",
          "border-b-green-50",
          "border-b-green-100",
          "border-b-green-200",
          "border-b-green-300",
          "border-b-green-400",
          "border-b-green-500",
          "border-b-green-600",
          "border-b-green-700",
          "border-b-green-800",
          "border-b-green-900",
          "border-b-green-950",
          "border-b-emerald-50",
          "border-b-emerald-100",
          "border-b-emerald-200",
          "border-b-emerald-300",
          "border-b-emerald-400",
          "border-b-emerald-500",
          "border-b-emerald-600",
          "border-b-emerald-700",
          "border-b-emerald-800",
          "border-b-emerald-900",
          "border-b-emerald-950",
          "border-b-teal-50",
          "border-b-teal-100",
          "border-b-teal-200",
          "border-b-teal-300",
          "border-b-teal-400",
          "border-b-teal-500",
          "border-b-teal-600",
          "border-b-teal-700",
          "border-b-teal-800",
          "border-b-teal-900",
          "border-b-teal-950",
          "border-b-cyan-50",
          "border-b-cyan-100",
          "border-b-cyan-200",
          "border-b-cyan-300",
          "border-b-cyan-400",
          "border-b-cyan-500",
          "border-b-cyan-600",
          "border-b-cyan-700",
          "border-b-cyan-800",
          "border-b-cyan-900",
          "border-b-cyan-950",
          "border-b-sky-50",
          "border-b-sky-100",
          "border-b-sky-200",
          "border-b-sky-300",
          "border-b-sky-400",
          "border-b-sky-500",
          "border-b-sky-600",
          "border-b-sky-700",
          "border-b-sky-800",
          "border-b-sky-900",
          "border-b-sky-950",
          "border-b-blue-50",
          "border-b-blue-100",
          "border-b-blue-200",
          "border-b-blue-300",
          "border-b-blue-400",
          "border-b-blue-500",
          "border-b-blue-600",
          "border-b-blue-700",
          "border-b-blue-800",
          "border-b-blue-900",
          "border-b-blue-950",
          "border-b-indigo-50",
          "border-b-indigo-100",
          "border-b-indigo-200",
          "border-b-indigo-300",
          "border-b-indigo-400",
          "border-b-indigo-500",
          "border-b-indigo-600",
          "border-b-indigo-700",
          "border-b-indigo-800",
          "border-b-indigo-900",
          "border-b-indigo-950",
          "border-b-violet-50",
          "border-b-violet-100",
          "border-b-violet-200",
          "border-b-violet-300",
          "border-b-violet-400",
          "border-b-violet-500",
          "border-b-violet-600",
          "border-b-violet-700",
          "border-b-violet-800",
          "border-b-violet-900",
          "border-b-violet-950",
          "border-b-purple-50",
          "border-b-purple-100",
          "border-b-purple-200",
          "border-b-purple-300",
          "border-b-purple-400",
          "border-b-purple-500",
          "border-b-purple-600",
          "border-b-purple-700",
          "border-b-purple-800",
          "border-b-purple-900",
          "border-b-purple-950",
          "border-b-fuchsia-50",
          "border-b-fuchsia-100",
          "border-b-fuchsia-200",
          "border-b-fuchsia-300",
          "border-b-fuchsia-400",
          "border-b-fuchsia-500",
          "border-b-fuchsia-600",
          "border-b-fuchsia-700",
          "border-b-fuchsia-800",
          "border-b-fuchsia-900",
          "border-b-fuchsia-950",
          "border-b-pink-50",
          "border-b-pink-100",
          "border-b-pink-200",
          "border-b-pink-300",
          "border-b-pink-400",
          "border-b-pink-500",
          "border-b-pink-600",
          "border-b-pink-700",
          "border-b-pink-800",
          "border-b-pink-900",
          "border-b-pink-950",
          "border-b-rose-50",
          "border-b-rose-100",
          "border-b-rose-200",
          "border-b-rose-300",
          "border-b-rose-400",
          "border-b-rose-500",
          "border-b-rose-600",
          "border-b-rose-700",
          "border-b-rose-800",
          "border-b-rose-900",
          "border-b-rose-950",
          "border-l-inherit",
          "border-l-current",
          "border-l-transparent",
          "border-l-black",
          "border-l-white",
          "border-l-slate-50",
          "border-l-slate-100",
          "border-l-slate-200",
          "border-l-slate-300",
          "border-l-slate-400",
          "border-l-slate-500",
          "border-l-slate-600",
          "border-l-slate-700",
          "border-l-slate-800",
          "border-l-slate-900",
          "border-l-slate-950",
          "border-l-gray-50",
          "border-l-gray-100",
          "border-l-gray-200",
          "border-l-gray-300",
          "border-l-gray-400",
          "border-l-gray-500",
          "border-l-gray-600",
          "border-l-gray-700",
          "border-l-gray-800",
          "border-l-gray-900",
          "border-l-gray-950",
          "border-l-zinc-50",
          "border-l-zinc-100",
          "border-l-zinc-200",
          "border-l-zinc-300",
          "border-l-zinc-400",
          "border-l-zinc-500",
          "border-l-zinc-600",
          "border-l-zinc-700",
          "border-l-zinc-800",
          "border-l-zinc-900",
          "border-l-zinc-950",
          "border-l-neutral-50",
          "border-l-neutral-100",
          "border-l-neutral-200",
          "border-l-neutral-300",
          "border-l-neutral-400",
          "border-l-neutral-500",
          "border-l-neutral-600",
          "border-l-neutral-700",
          "border-l-neutral-800",
          "border-l-neutral-900",
          "border-l-neutral-950",
          "border-l-stone-50",
          "border-l-stone-100",
          "border-l-stone-200",
          "border-l-stone-300",
          "border-l-stone-400",
          "border-l-stone-500",
          "border-l-stone-600",
          "border-l-stone-700",
          "border-l-stone-800",
          "border-l-stone-900",
          "border-l-stone-950",
          "border-l-red-50",
          "border-l-red-100",
          "border-l-red-200",
          "border-l-red-300",
          "border-l-red-400",
          "border-l-red-500",
          "border-l-red-600",
          "border-l-red-700",
          "border-l-red-800",
          "border-l-red-900",
          "border-l-red-950",
          "border-l-orange-50",
          "border-l-orange-100",
          "border-l-orange-200",
          "border-l-orange-300",
          "border-l-orange-400",
          "border-l-orange-500",
          "border-l-orange-600",
          "border-l-orange-700",
          "border-l-orange-800",
          "border-l-orange-900",
          "border-l-orange-950",
          "border-l-amber-50",
          "border-l-amber-100",
          "border-l-amber-200",
          "border-l-amber-300",
          "border-l-amber-400",
          "border-l-amber-500",
          "border-l-amber-600",
          "border-l-amber-700",
          "border-l-amber-800",
          "border-l-amber-900",
          "border-l-amber-950",
          "border-l-yellow-50",
          "border-l-yellow-100",
          "border-l-yellow-200",
          "border-l-yellow-300",
          "border-l-yellow-400",
          "border-l-yellow-500",
          "border-l-yellow-600",
          "border-l-yellow-700",
          "border-l-yellow-800",
          "border-l-yellow-900",
          "border-l-yellow-950",
          "border-l-lime-50",
          "border-l-lime-100",
          "border-l-lime-200",
          "border-l-lime-300",
          "border-l-lime-400",
          "border-l-lime-500",
          "border-l-lime-600",
          "border-l-lime-700",
          "border-l-lime-800",
          "border-l-lime-900",
          "border-l-lime-950",
          "border-l-green-50",
          "border-l-green-100",
          "border-l-green-200",
          "border-l-green-300",
          "border-l-green-400",
          "border-l-green-500",
          "border-l-green-600",
          "border-l-green-700",
          "border-l-green-800",
          "border-l-green-900",
          "border-l-green-950",
          "border-l-emerald-50",
          "border-l-emerald-100",
          "border-l-emerald-200",
          "border-l-emerald-300",
          "border-l-emerald-400",
          "border-l-emerald-500",
          "border-l-emerald-600",
          "border-l-emerald-700",
          "border-l-emerald-800",
          "border-l-emerald-900",
          "border-l-emerald-950",
          "border-l-teal-50",
          "border-l-teal-100",
          "border-l-teal-200",
          "border-l-teal-300",
          "border-l-teal-400",
          "border-l-teal-500",
          "border-l-teal-600",
          "border-l-teal-700",
          "border-l-teal-800",
          "border-l-teal-900",
          "border-l-teal-950",
          "border-l-cyan-50",
          "border-l-cyan-100",
          "border-l-cyan-200",
          "border-l-cyan-300",
          "border-l-cyan-400",
          "border-l-cyan-500",
          "border-l-cyan-600",
          "border-l-cyan-700",
          "border-l-cyan-800",
          "border-l-cyan-900",
          "border-l-cyan-950",
          "border-l-sky-50",
          "border-l-sky-100",
          "border-l-sky-200",
          "border-l-sky-300",
          "border-l-sky-400",
          "border-l-sky-500",
          "border-l-sky-600",
          "border-l-sky-700",
          "border-l-sky-800",
          "border-l-sky-900",
          "border-l-sky-950",
          "border-l-blue-50",
          "border-l-blue-100",
          "border-l-blue-200",
          "border-l-blue-300",
          "border-l-blue-400",
          "border-l-blue-500",
          "border-l-blue-600",
          "border-l-blue-700",
          "border-l-blue-800",
          "border-l-blue-900",
          "border-l-blue-950",
          "border-l-indigo-50",
          "border-l-indigo-100",
          "border-l-indigo-200",
          "border-l-indigo-300",
          "border-l-indigo-400",
          "border-l-indigo-500",
          "border-l-indigo-600",
          "border-l-indigo-700",
          "border-l-indigo-800",
          "border-l-indigo-900",
          "border-l-indigo-950",
          "border-l-violet-50",
          "border-l-violet-100",
          "border-l-violet-200",
          "border-l-violet-300",
          "border-l-violet-400",
          "border-l-violet-500",
          "border-l-violet-600",
          "border-l-violet-700",
          "border-l-violet-800",
          "border-l-violet-900",
          "border-l-violet-950",
          "border-l-purple-50",
          "border-l-purple-100",
          "border-l-purple-200",
          "border-l-purple-300",
          "border-l-purple-400",
          "border-l-purple-500",
          "border-l-purple-600",
          "border-l-purple-700",
          "border-l-purple-800",
          "border-l-purple-900",
          "border-l-purple-950",
          "border-l-fuchsia-50",
          "border-l-fuchsia-100",
          "border-l-fuchsia-200",
          "border-l-fuchsia-300",
          "border-l-fuchsia-400",
          "border-l-fuchsia-500",
          "border-l-fuchsia-600",
          "border-l-fuchsia-700",
          "border-l-fuchsia-800",
          "border-l-fuchsia-900",
          "border-l-fuchsia-950",
          "border-l-pink-50",
          "border-l-pink-100",
          "border-l-pink-200",
          "border-l-pink-300",
          "border-l-pink-400",
          "border-l-pink-500",
          "border-l-pink-600",
          "border-l-pink-700",
          "border-l-pink-800",
          "border-l-pink-900",
          "border-l-pink-950",
          "border-l-rose-50",
          "border-l-rose-100",
          "border-l-rose-200",
          "border-l-rose-300",
          "border-l-rose-400",
          "border-l-rose-500",
          "border-l-rose-600",
          "border-l-rose-700",
          "border-l-rose-800",
          "border-l-rose-900",
          "border-l-rose-950",
        ],
      },
    ],
  },
  {
    title: "Spacing and Sizing",
    properties: [
      {
        key: "width",
        placeholder: "Width",
        notAdvanced: true,
        options: [
          "w-0",
          "w-px",
          "w-0.5",
          "w-1",
          "w-1.5",
          "w-2",
          "w-2.5",
          "w-3",
          "w-3.5",
          "w-4",
          "w-5",
          "w-6",
          "w-7",
          "w-8",
          "w-9",
          "w-10",
          "w-11",
          "w-12",
          "w-14",
          "w-16",
          "w-20",
          "w-24",
          "w-28",
          "w-32",
          "w-36",
          "w-40",
          "w-44",
          "w-48",
          "w-52",
          "w-56",
          "w-60",
          "w-64",
          "w-72",
          "w-80",
          "w-96",
          "w-auto",
          "w-1/2",
          "w-1/3",
          "w-2/3",
          "w-1/4",
          "w-2/4",
          "w-3/4",
          "w-1/5",
          "w-2/5",
          "w-3/5",
          "w-4/5",
          "w-1/6",
          "w-2/6",
          "w-3/6",
          "w-4/6",
          "w-5/6",
          "w-1/12",
          "w-2/12",
          "w-3/12",
          "w-4/12",
          "w-5/12",
          "w-6/12",
          "w-7/12",
          "w-8/12",
          "w-9/12",
          "w-10/12",
          "w-11/12",
          "w-full",
          "w-screen",
          "w-svw",
          "w-lvw",
          "w-dvw",
          "w-min",
          "w-max",
          "w-fit",
        ],
      },
      {
        key: "height",
        placeholder: "Height",
        notAdvanced: true,
        options: [
          "h-0",
          "h-px",
          "h-0.5",
          "h-1",
          "h-1.5",
          "h-2",
          "h-2.5",
          "h-3",
          "h-3.5",
          "h-4",
          "h-5",
          "h-6",
          "h-7",
          "h-8",
          "h-9",
          "h-10",
          "h-11",
          "h-12",
          "h-14",
          "h-16",
          "h-20",
          "h-24",
          "h-28",
          "h-32",
          "h-36",
          "h-40",
          "h-44",
          "h-48",
          "h-52",
          "h-56",
          "h-60",
          "h-64",
          "h-72",
          "h-80",
          "h-96",
          "h-auto",
          "h-1/2",
          "h-1/3",
          "h-2/3",
          "h-1/4",
          "h-2/4",
          "h-3/4",
          "h-1/5",
          "h-2/5",
          "h-3/5",
          "h-4/5",
          "h-1/6",
          "h-2/6",
          "h-3/6",
          "h-4/6",
          "h-5/6",
          "h-1/12",
          "h-2/12",
          "h-3/12",
          "h-4/12",
          "h-5/12",
          "h-6/12",
          "h-7/12",
          "h-8/12",
          "h-9/12",
          "h-10/12",
          "h-11/12",
          "h-full",
          "h-screen",
          "h-svh",
          "h-lvh",
          "h-dvh",
          "h-min",
          "h-max",
          "h-fit",
        ],
      },
      {
        key: "min-width",
        placeholder: "Minimum Width",
        options: [
          "min-w-0",
          "min-w-1",
          "min-w-2",
          "min-w-3",
          "min-w-4",
          "min-w-5",
          "min-w-6",
          "min-w-7",
          "min-w-8",
          "min-w-9",
          "min-w-10",
          "min-w-11",
          "min-w-12",
          "min-w-14",
          "min-w-16",
          "min-w-20",
          "min-w-24",
          "min-w-28",
          "min-w-32",
          "min-w-36",
          "min-w-40",
          "min-w-44",
          "min-w-48",
          "min-w-52",
          "min-w-56",
          "min-w-60",
          "min-w-64",
          "min-w-72",
          "min-w-80",
          "min-w-96",
          "min-w-px",
          "min-w-0.5",
          "min-w-1.5",
          "min-w-2.5",
          "min-w-3.5",
          "min-w-full",
          "min-w-min",
          "min-w-max",
          "min-w-fit",
        ],
      },
      {
        key: "max-width",
        placeholder: "Maximum Width",
        options: [
          "max-w-0",
          "max-w-px",
          "max-w-0.5",
          "max-w-1",
          "max-w-1.5",
          "max-w-2",
          "max-w-2.5",
          "max-w-3",
          "max-w-3.5",
          "max-w-4",
          "max-w-5",
          "max-w-6",
          "max-w-7",
          "max-w-8",
          "max-w-9",
          "max-w-10",
          "max-w-11",
          "max-w-12",
          "max-w-14",
          "max-w-16",
          "max-w-20",
          "max-w-24",
          "max-w-28",
          "max-w-32",
          "max-w-36",
          "max-w-40",
          "max-w-44",
          "max-w-48",
          "max-w-52",
          "max-w-56",
          "max-w-60",
          "max-w-64",
          "max-w-72",
          "max-w-80",
          "max-w-96",
          "max-w-none",
          "max-w-xs",
          "max-w-sm",
          "max-w-md",
          "max-w-lg",
          "max-w-xl",
          "max-w-2xl",
          "max-w-3xl",
          "max-w-4xl",
          "max-w-5xl",
          "max-w-6xl",
          "max-w-7xl",
          "max-w-full",
          "max-w-min",
          "max-w-max",
          "max-w-fit",
          "max-w-prose",
          "max-w-screen-sm",
          "max-w-screen-md",
          "max-w-screen-lg",
          "max-w-screen-xl",
          "max-w-screen-2xl",
        ],
      },

      {
        key: "min-height",
        placeholder: "Minimum Height",
        options: [
          "min-h-0",
          "min-h-1",
          "min-h-2",
          "min-h-3",
          "min-h-4",
          "min-h-5",
          "min-h-6",
          "min-h-7",
          "min-h-8",
          "min-h-9",
          "min-h-10",
          "min-h-11",
          "min-h-12",
          "min-h-14",
          "min-h-16",
          "min-h-20",
          "min-h-24",
          "min-h-28",
          "min-h-32",
          "min-h-36",
          "min-h-40",
          "min-h-44",
          "min-h-48",
          "min-h-52",
          "min-h-56",
          "min-h-60",
          "min-h-64",
          "min-h-72",
          "min-h-80",
          "min-h-96",
          "min-h-px",
          "min-h-0.5",
          "min-h-1.5",
          "min-h-2.5",
          "min-h-3.5",
          "min-h-full",
          "min-h-min",
          "min-h-max",
          "min-h-fit",
        ],
      },
      {
        key: "max-height",
        placeholder: "Maximum Height",
        options: [
          "max-h-0",
          "max-h-px",
          "max-h-0.5",
          "max-h-1",
          "max-h-1.5",
          "max-h-2",
          "max-h-2.5",
          "max-h-3",
          "max-h-3.5",
          "max-h-4",
          "max-h-5",
          "max-h-6",
          "max-h-7",
          "max-h-8",
          "max-h-9",
          "max-h-10",
          "max-h-11",
          "max-h-12",
          "max-h-14",
          "max-h-16",
          "max-h-20",
          "max-h-24",
          "max-h-28",
          "max-h-32",
          "max-h-36",
          "max-h-40",
          "max-h-44",
          "max-h-48",
          "max-h-52",
          "max-h-56",
          "max-h-60",
          "max-h-64",
          "max-h-72",
          "max-h-80",
          "max-h-96",
          "max-h-none",
          "max-h-xs",
          "max-h-sm",
          "max-h-md",
          "max-h-lg",
          "max-h-xl",
          "max-h-2xl",
          "max-h-3xl",
          "max-h-4xl",
          "max-h-5xl",
          "max-h-6xl",
          "max-h-7xl",
          "max-h-full",
          "max-h-min",
          "max-h-max",
          "max-h-fit",
          "max-h-prose",
          "max-h-screen-sm",
          "max-h-screen-md",
          "max-h-screen-lg",
          "max-h-screen-xl",
          "max-h-screen-2xl",
        ],
      },
    ],
  },
  {
    title: "Flexbox & Grid",
    properties: [
      {
        key: "flex-basis",
        placeholder: "Flex Basis",
        options: [
          "basis-0",
          "basis-1",
          "basis-2",
          "basis-3",
          "basis-4",
          "basis-5",
          "basis-6",
          "basis-7",
          "basis-8",
          "basis-9",
          "basis-10",
          "basis-11",
          "basis-12",
          "basis-14",
          "basis-16",
          "basis-20",
          "basis-24",
          "basis-28",
          "basis-32",
          "basis-36",
          "basis-40",
          "basis-44",
          "basis-48",
          "basis-52",
          "basis-56",
          "basis-60",
          "basis-64",
          "basis-72",
          "basis-80",
          "basis-96",
          "basis-auto",
          "basis-px",
          "basis-0.5",
          "basis-1.5",
          "basis-2.5",
          "basis-3.5",
          "basis-1/2",
          "basis-1/3",
          "basis-2/3",
          "basis-1/4",
          "basis-2/4",
          "basis-3/4",
          "basis-1/5",
          "basis-2/5",
          "basis-3/5",
          "basis-4/5",
          "basis-1/6",
          "basis-2/6",
          "basis-3/6",
          "basis-4/6",
          "basis-5/6",
          "basis-1/12",
          "basis-2/12",
          "basis-3/12",
          "basis-4/12",
          "basis-5/12",
          "basis-6/12",
          "basis-7/12",
          "basis-8/12",
          "basis-9/12",
          "basis-10/12",
          "basis-11/12",
          "basis-full",
        ],
      },
      {
        key: "flex-direction",
        placeholder: "Flex Direction",
        options: [
          "flex-row",
          "flex-row-reverse",
          "flex-col",
          "flex-col-reverse",
        ],
      },
      {
        key: "flex-wrap",
        placeholder: "Flex Wrap",
        options: ["flex-wrap", "flex-wrap-reverse", "flex-nowrap"],
      },
      {
        key: "flex",
        placeholder: "Flex",
        options: ["flex-1", "flex-auto", "flex-initial", "flex-none"],
      },
      {
        key: "flex-grow",
        placeholder: "Flex Grow",
        options: ["grow", "grow-0"],
      },
      {
        key: "flex-shrink",
        placeholder: "Flex Shrink",
        options: ["shrink", "shrink-0"],
      },
      {
        key: "order",
        placeholder: "Order",
        options: [
          "order-1",
          "order-2",
          "order-3",
          "order-4",
          "order-5",
          "order-6",
          "order-7",
          "order-8",
          "order-9",
          "order-10",
          "order-11",
          "order-12",
          "order-first",
          "order-last",
          "order-none",
        ],
      },
      {
        key: "grid-template-columns",
        placeholder: "Grid Template Columns",
        options: [
          "grid-cols-1",
          "grid-cols-2",
          "grid-cols-3",
          "grid-cols-4",
          "grid-cols-5",
          "grid-cols-6",
          "grid-cols-7",
          "grid-cols-8",
          "grid-cols-9",
          "grid-cols-10",
          "grid-cols-11",
          "grid-cols-12",
          "grid-cols-none",
          "grid-cols-subgrid",
        ],
      },
      {
        key: "grid-column",
        placeholder: "Grid Column Start / End",
        options: [
          "col-auto",
          "col-span-1",
          "col-span-2",
          "col-span-3",
          "col-span-4",
          "col-span-5",
          "col-span-6",
          "col-span-7",
          "col-span-8",
          "col-span-9",
          "col-span-10",
          "col-span-11",
          "col-span-12",
          "col-span-full",
          "col-start-1",
          "col-start-2",
          "col-start-3",
          "col-start-4",
          "col-start-5",
          "col-start-6",
          "col-start-7",
          "col-start-8",
          "col-start-9",
          "col-start-10",
          "col-start-11",
          "col-start-12",
          "col-start-13",
          "col-start-auto",
          "col-end-1",
          "col-end-2",
          "col-end-3",
          "col-end-4",
          "col-end-5",
          "col-end-6",
          "col-end-7",
          "col-end-8",
          "col-end-9",
          "col-end-10",
          "col-end-11",
          "col-end-12",
          "col-end-13",
          "col-end-auto",
        ],
      },
      {
        key: "grid-template-rows",
        placeholder: "Grid Template Rows",
        options: [
          "grid-rows-1",
          "grid-rows-2",
          "grid-rows-3",
          "grid-rows-4",
          "grid-rows-5",
          "grid-rows-6",
          "grid-rows-7",
          "grid-rows-8",
          "grid-rows-9",
          "grid-rows-10",
          "grid-rows-11",
          "grid-rows-12",
          "grid-rows-none",
          "grid-rows-subgrid",
        ],
      },
      {
        key: "grid-row",
        placeholder: "Grid Row Start / End",
        options: [
          "row-auto",
          "row-span-1",
          "row-span-2",
          "row-span-3",
          "row-span-4",
          "row-span-5",
          "row-span-6",
          "row-span-7",
          "row-span-8",
          "row-span-9",
          "row-span-10",
          "row-span-11",
          "row-span-12",
          "row-span-full",
          "row-start-1",
          "row-start-2",
          "row-start-3",
          "row-start-4",
          "row-start-5",
          "row-start-6",
          "row-start-7",
          "row-start-8",
          "row-start-9",
          "row-start-10",
          "row-start-11",
          "row-start-12",
          "row-start-13",
          "row-start-auto",
          "row-end-1",
          "row-end-2",
          "row-end-3",
          "row-end-4",
          "row-end-5",
          "row-end-6",
          "row-end-7",
          "row-end-8",
          "row-end-9",
          "row-end-10",
          "row-end-11",
          "row-end-12",
          "row-end-13",
          "row-end-auto",
        ],
      },
      {
        key: "grid-auto-flow",
        placeholder: "Grid Auto Flow",
        options: [
          "grid-flow-row",
          "grid-flow-col",
          "grid-flow-dense",
          "grid-flow-row-dense",
          "grid-flow-col-dense",
        ],
      },
      {
        key: "grid-auto-columns",
        placeholder: "Grid Auto Columns",
        options: [
          "auto-cols-auto",
          "auto-cols-min",
          "auto-cols-max",
          "auto-cols-fr",
        ],
      },
      {
        key: "grid-auto-rows",
        placeholder: "Grid Auto Rows",
        options: [
          "auto-rows-auto",
          "auto-rows-min",
          "auto-rows-max",
          "auto-rows-fr",
        ],
      },
      {
        key: "gap",
        placeholder: "Gap",
        options: [
          "gap-0",
          "gap-x-0",
          "gap-y-0",
          "gap-px",
          "gap-x-px",
          "gap-y-px",
          "gap-0.5",
          "gap-x-0.5",
          "gap-y-0.5",
          "gap-1",
          "gap-x-1",
          "gap-y-1",
          "gap-1.5",
          "gap-x-1.5",
          "gap-y-1.5",
          "gap-2",
          "gap-x-2",
          "gap-y-2",
          "gap-2.5",
          "gap-x-2.5",
          "gap-y-2.5",
          "gap-3",
          "gap-x-3",
          "gap-y-3",
          "gap-3.5",
          "gap-x-3.5",
          "gap-y-3.5",
          "gap-4",
          "gap-x-4",
          "gap-y-4",
          "gap-5",
          "gap-x-5",
          "gap-y-5",
          "gap-6",
          "gap-x-6",
          "gap-y-6",
          "gap-7",
          "gap-x-7",
          "gap-y-7",
          "gap-8",
          "gap-x-8",
          "gap-y-8",
          "gap-9",
          "gap-x-9",
          "gap-y-9",
          "gap-10",
          "gap-x-10",
          "gap-y-10",
          "gap-11",
          "gap-x-11",
          "gap-y-11",
          "gap-12",
          "gap-x-12",
          "gap-y-12",
          "gap-14",
          "gap-x-14",
          "gap-y-14",
          "gap-16",
          "gap-x-16",
          "gap-y-16",
          "gap-20",
          "gap-x-20",
          "gap-y-20",
          "gap-24",
          "gap-x-24",
          "gap-y-24",
          "gap-28",
          "gap-x-28",
          "gap-y-28",
          "gap-32",
          "gap-x-32",
          "gap-y-32",
          "gap-36",
          "gap-x-36",
          "gap-y-36",
          "gap-40",
          "gap-x-40",
          "gap-y-40",
          "gap-44",
          "gap-x-44",
          "gap-y-44",
          "gap-48",
          "gap-x-48",
          "gap-y-48",
          "gap-52",
          "gap-x-52",
          "gap-y-52",
          "gap-56",
          "gap-x-56",
          "gap-y-56",
          "gap-60",
          "gap-x-60",
          "gap-y-60",
          "gap-64",
          "gap-x-64",
          "gap-y-64",
          "gap-72",
          "gap-x-72",
          "gap-y-72",
          "gap-80",
          "gap-x-80",
          "gap-y-80",
          "gap-96",
          "gap-x-96",
          "gap-y-96",
        ],
      },
      {
        key: "justify-content",
        placeholder: "Justify Content",
        options: [
          "justify-normal",
          "justify-start",
          "justify-end",
          "justify-center",
          "justify-between",
          "justify-around",
          "justify-evenly",
          "justify-stretch",
        ],
      },
      {
        key: "justify-items",
        placeholder: "Justify Items",
        options: [
          "justify-items-start",
          "justify-items-end",
          "justify-items-center",
          "justify-items-stretch",
        ],
      },
      {
        key: "justify-self",
        placeholder: "Justify Self",
        options: [
          "justify-self-auto",
          "justify-self-start",
          "justify-self-end",
          "justify-self-center",
          "justify-self-stretch",
        ],
      },
      {
        key: "align-content",
        placeholder: "Align Content",
        options: [
          "content-normal",
          "content-center",
          "content-start",
          "content-end",
          "content-between",
          "content-around",
          "content-evenly",
          "content-baseline",
          "content-stretch",
        ],
      },
      {
        key: "align-items",
        placeholder: "Align Items",
        options: [
          "items-start",
          "items-end",
          "items-center",
          "items-baseline",
          "items-stretch",
        ],
      },
      {
        key: "align-self",
        placeholder: "Align Self",
        options: [
          "self-auto",
          "self-start",
          "self-end",
          "self-center",
          "self-stretch",
          "self-baseline",
        ],
      },
      {
        key: "place-content",
        placeholder: "Place Content",
        options: [
          "place-content-center",
          "place-content-start",
          "place-content-end",
          "place-content-between",
          "place-content-around",
          "place-content-evenly",
          "place-content-baseline",
          "place-content-stretch",
        ],
      },
      {
        key: "place-items",
        placeholder: "Place Items",
        options: [
          "place-items-start",
          "place-items-end",
          "place-items-center",
          "place-items-baseline",
          "place-items-stretch",
        ],
      },
      {
        key: "place-self",
        placeholder: "Place Self",
        options: [
          "place-self-auto",
          "place-self-start",
          "place-self-end",
          "place-self-center",
          "place-self-stretch",
        ],
      },
    ],
  },

  {
    title: "Background",
    properties: [
      {
        key: "background-attachment",
        placeholder: "Background Attachment",
        options: ["bg-fixed", "bg-local", "bg-scroll"],
      },
      {
        key: "background-clip",
        placeholder: "Background Clip",
        options: [
          "bg-clip-border",
          "bg-clip-padding",
          "bg-clip-content",
          "bg-clip-text",
        ],
      },

      {
        key: "background-color",
        placeholder: "Background Color",
        options: [],
        inputType: "color-picker",
        prefix: "bg",
        notAdvanced: true,
      },

      {
        key: "background-origin",
        placeholder: "Background Origin",
        options: ["bg-origin-border", "bg-origin-padding", "bg-origin-content"],
      },
      {
        key: "background-position",
        placeholder: "Background Position",
        options: [
          "bg-bottom",
          "bg-center",
          "bg-left",
          "bg-left-bottom",
          "bg-left-top",
          "bg-right",
          "bg-right-bottom",
          "bg-right-top",
          "bg-top",
        ],
      },
      {
        key: "background-repeat",
        placeholder: "Background Repeat",
        options: [
          "bg-repeat",
          "bg-no-repeat",
          "bg-repeat-x",
          "bg-repeat-y",
          "bg-repeat-round",
          "bg-repeat-space",
        ],
      },
      {
        key: "background-size",
        placeholder: "Background Size",
        options: ["bg-auto", "bg-cover", "bg-contain"],
      },
      {
        key: "background-image",
        placeholder: "Background Image",
        options: [
          "bg-none",
          "bg-gradient-to-t",
          "bg-gradient-to-tr",
          "bg-gradient-to-r",
          "bg-gradient-to-br",
          "bg-gradient-to-b",
          "bg-gradient-to-bl",
          "bg-gradient-to-l",
          "bg-gradient-to-tl",
        ],
      },
    ],
  },
];
