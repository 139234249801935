export const LeftArrow = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="16.5"
      stroke="var(--indigo-500)"
    />
    <path
      d="M15 12C15 12.53 14.4808 13.3214 13.9552 13.9857C13.2795 14.8429 12.472 15.5907 11.5462 16.1614C10.852 16.5893 10.0105 17 9.33337 17M9.33337 17C10.0105 17 10.8527 17.4107 11.5462 17.8386C12.472 18.41 13.2795 19.1579 13.9552 20.0136C14.4808 20.6786 15 21.4714 15 22M9.33337 17H26.3334"
      stroke="var(--indigo-500)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_333_1298"
        x1="0"
        y1="17"
        x2="34"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#134E4A" />
        <stop offset="1" stop-color="#2CB4AB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_333_1298"
        x1="9.33337"
        y1="17"
        x2="26.3334"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#134E4A" />
        <stop offset="1" stop-color="#2CB4AB" />
      </linearGradient>
    </defs>
  </svg>
);

export const RightArrow = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="33.5"
      y="33.5"
      width="33"
      height="33"
      rx="16.5"
      transform="rotate(-180 33.5 33.5)"
      fill="var(--indigo-500)"
    />
    <rect
      x="33.5"
      y="33.5"
      width="33"
      height="33"
      rx="16.5"
      transform="rotate(-180 33.5 33.5)"
      stroke="var(--indigo-500)"
    />
    <path
      d="M19 22C19 21.47 19.5192 20.6786 20.0448 20.0143C20.7205 19.1571 21.528 18.4093 22.4538 17.8386C23.148 17.4107 23.9895 17 24.6666 17M24.6666 17C23.9895 17 23.1473 16.5893 22.4538 16.1614C21.528 15.59 20.7205 14.8421 20.0448 13.9864C19.5192 13.3214 19 12.5286 19 12M24.6666 17H7.66663"
      stroke="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_333_1300"
        x1="34"
        y1="51"
        x2="68"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#134E4A" />
        <stop offset="1" stop-color="#2CB4AB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_333_1300"
        x1="34"
        y1="51"
        x2="68"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#134E4A" />
        <stop offset="1" stop-color="#2CB4AB" />
      </linearGradient>
    </defs>
  </svg>
);
