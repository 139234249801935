import { Card, Select, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ATSService from "../../../service/ATSService";
import UserService from "../../../service/UserService";

const adStatMapping = {
  "AD spent": "spend",
  "AD CPC": "cpc",
  "AD CPM": "cpm",
  "AD CTR": "ctr",
  "AD impressions": "impressions",
  "AD Clicks": "clicks",
  "AD frequency": "frequency",
};

const FunnelStage = ({ width, height, label, value, percentage }) => (
  <div
    className="relative mb-2"
    style={{ width: `${width}%`, height: `${height}px` }}
  >
    <div className="absolute inset-0 bg-blue-500 rounded-sm"></div>
    <div className="absolute inset-0 flex items-center justify-between px-4 text-white">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
    <div className="absolute left-full ml-4 top-1/2 transform -translate-y-1/2 text-sm">
      {percentage}% (
      {label === "Optins (Leads)" ? "New Leads (Optin)" : `${label}/ Optin`})
    </div>
  </div>
);

export default function FunnelStats() {
  const [timeframe, setTimeframe] = useState("day");
  const [trafficSource, setTrafficSource] = useState("All");
  const [funnelData, setFunnelData] = useState(null);
  const [adMetrics, setAdMetrics] = useState(null);
  const [metricsTimeline, setMetricsTimeline] = useState(null);
  const [adStats, setAdStats] = useState(null);
  let [searchParams] = useSearchParams();

  const loadData = useCallback(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setFunnelData(null);

    ATSService.getFunnelStats(id).then((res) => {
      if (!res.data) return;
      setFunnelData(res.data.funnelData);
      setAdMetrics(res.data.adMetrics);
      console.log(res.data.metricsTimeline);
      setMetricsTimeline(res.data.metricsTimeline); // Set timeline data
    });

    UserService.getVacancyCampaignStats(id).then((res) => {
      console.log(res.data);
      if (res.data?.insights?.[0]?._data)
        setAdStats(res.data.insights[0]._data);
      else setAdStats("Delivering");
    });
  }, [searchParams]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
    // Fetch and update data based on new timeframe
  };

  const handleTrafficSourceChange = (value) => {
    setTrafficSource(value);
    // Fetch and update data based on new traffic source
  };

  if (!funnelData || !adMetrics || !metricsTimeline) return <Skeleton active />;

  return (
    <div className="card w-full max-w-4xl">
      <div className="card-content">
        <div className="flex justify-end gap-1 mb-4">
          <Select
            placeholder="Select traffic source"
            className="select"
            onChange={handleTrafficSourceChange}
            defaultValue={trafficSource}
          >
            <Select.Option value="All">All Sources</Select.Option>
            <Select.Option value="Google">Google</Select.Option>
            <Select.Option value="Meta">Meta</Select.Option>
          </Select>
          <IoMdRefresh
            size={25}
            title="Refresh"
            className="cursor-pointer"
            onClick={() => loadData()}
          />
        </div>

        {/* Funnel Stages */}
        <div className="relative" style={{ paddingRight: "200px" }}>
          {funnelData.map((stage, index) => (
            <FunnelStage
              key={stage.name}
              width={100 - index * 15}
              height={80}
              label={stage.name}
              value={stage.value}
              percentage={stage.percentage}
            />
          ))}
        </div>

        {/* Timeline Chart */}
        <div className="mt-8" style={{ height: 400 }}>
          {metricsTimeline && metricsTimeline.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={metricsTimeline}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />

                <Line type="monotone" dataKey="Engaged" stroke="#cf7300" />
                <Line type="monotone" dataKey="Nurtured" stroke="#af7300" />
                <Line type="monotone" dataKey="Webinar" stroke="#8884d8" />
                <Line type="monotone" dataKey="Converted" stroke="#82ca9d" />
                <Line type="monotone" dataKey="Lost" stroke="#ff7300" />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div>No data available for the selected timeframe.</div>
          )}
        </div>

        {/* Ad Metrics */}
        {adStats !== "Delivering" && (
          <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(adMetrics).map(([key, value]) => (
              <Card className="card" key={key}>
                <div className="card-title text-sm font-medium">{key}</div>
                <div className="card-content">
                  <p className="text-2xl font-bold">
                    {adStats[adStatMapping[key]]?.toFixed?.(2)}{" "}
                    {!["frequency", "impressions", "clicks"].includes(
                      adStatMapping[key]
                    ) && adStats.account_currency}
                  </p>
                </div>
              </Card>
            ))}

            <Card className="card">
              <div className="card-title text-sm font-medium">
                Ad Spent / Conversion
              </div>
              <div className="card-content">
                <p className="text-2xl font-bold">
                  {(
                    adStats["spend"] /
                    (funnelData?.find?.((a) => a.name === "Converted")?.value ||
                      1)
                  )?.toFixed?.(2)}{" "}
                  {adStats.account_currency}
                </p>
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}
