import { Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import MultiStepComponent from "../../../components/MultiStepComponent";
import CrudService from "../../../service/CrudService";

const steps = () => [
  {
    id: "step1",
    name: "Task Description",
    form: [
      {
        fieldName: "onEnterCallScript",
        label: "Describe the task of your call agent",
        type: "textarea",
        placeholder: "Enter task description",
        rows: 6,
      },
    ],
  },

  {
    id: "step4",
    name: "Call Configuration",
    form: [
      {
        fieldName: "aiSpeed",
        label: "Speed",
        type: "select",
        options: [
          { value: 0.85, label: "Slow" },
          { value: 1, label: "Normal" },
          { value: 1.35, label: "Fast" },
          { value: 1.6, label: "Ultra Fast" },
        ],
        placeholder: "Select the speed of speech",
      },

      {
        fieldName: "aiLanguage",
        label: "Language",
        type: "select",
        options: [
          { value: "en-US", label: "American English" },
          { value: "en-GB", label: "British English" },
          { value: "nl", label: "Dutch" },
          { value: "fr", label: "French" },
          { value: "de", label: "German" },
          { value: "it", label: "Italian" },
          { value: "ja", label: "Japanese" },
          { value: "pl", label: "Polish" },
          { value: "pt", label: "Portuguese" },
          { value: "ru", label: "Russian" },
          { value: "es", label: "Spanish" },
          { value: "sv", label: "Swedish" },
          { value: "tr", label: "Turkish" },
          { value: "zh", label: "Chinese" },
        ],
        placeholder: "Select the speed of speech",
      },
      {
        fieldName: "aiMaxDuration",
        label: "Maximum Call Duration",
        type: "inputNumber",
        placeholder: "Specify the maximum duration of an interview in minutes",
      },
      {
        fieldName: "aiRecord",
        label: "Record Call",
        type: "switch",
        placeholder:
          "Specify whether you would like to create a voice recording of your call",
      },
    ],
  },
];

const AICallEdit = ({ id, onFinish }) => {
  const [vacancyData, setVacancyData] = useState(null);
  const vidRef = useRef();

  useEffect(() => {
    if (vidRef.current) vidRef.current.play();
  }, [vidRef]);

  useEffect(() => {
    if (!id) return;

    CrudService.getSingle("VacancyStage", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [id]);

  if (!vacancyData) return <Skeleton active />;

  return (
    <>
      <MultiStepComponent
        AIEnhancements={true}
        displayUndoRedo
        steps={steps()}
        defaultFormData={{
          ...vacancyData,
        }}
        onFinish={async (formData) => {
          if (!id) return;

          await CrudService.update("VacancyStage", id, {
            ...formData,
          });

          onFinish();
        }}
        onNext={async (formData) => {
          if (!id) return;

          await CrudService.update("VacancyStage", id, {
            ...formData,
          });
        }}
        buttomLineWrapperClass=" w-full bg-white dark:bg-gray-900 rounded-t-sm pl-[20px] pt-[20px] pb-[20px] pr-[80px] right-0	bottom-0"
        buttomLineInnerClass="items-end flex flex-col"
      />
    </>
  );
};

export default AICallEdit;
