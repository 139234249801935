import { Alert, Button, Skeleton, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleTextDecoration } from "../pages/Funnel";
import { Footer } from "../pages/Landing/Footer";
import { getPartner, selectLoading } from "../redux/auth/selectors";
import PublicService from "../service/PublicService";

export default function JobPortal() {
  const { recruiterId } = useParams();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const [searchTerm, setSearchTerm] = useState("");

  const [recruiter, setRecruiter] = useState(null);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    limit: 25,
  });
  const [jobs, setJobs] = useState(null);
  const partner = useSelector(getPartner);

  useEffect(() => {
    PublicService.getRecruiterData(recruiterId).then(({ data }) => {
      if (!data?.recruiter?.enableJobPortal)
        return navigate("/dashboard/vacancy");
      setRecruiter(data.recruiter);
      setPagination((p) => ({ ...p, limit: data.recruiter.jobsPerPage ?? 25 }));
    });
  }, [recruiterId, navigate]);

  const loadMore = useCallback(
    async ({ refresh = false, page = 1, text = "" }) => {
      if (!recruiter) return;

      const response = await PublicService.queryJobsOfRecruiter({
        page,
        limit: recruiter.jobsPerPage,
        recruiterId: recruiter._id,
        text,
      });

      const newJobs = response.data.result;
      setJobs((prevJobs) => [...(refresh ? [] : prevJobs ?? []), ...newJobs]);
      setPagination({
        total: response.data.total,
        currentPage: response.data.page,
        limit: response.data.limit,
      });
    },
    [recruiter]
  );

  // Function to perform the actual search
  const performSearch = useCallback(
    (text) => {
      loadMore({
        text: text ? text : undefined,
        refresh: true,
        page: 1,
      });
    },
    [loadMore]
  );
  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 1000);
  };

  useEffect(() => {
    loadMore({});
  }, [loadMore]);

  if (!partner) return <Skeleton active />;
  if (!recruiter) return <Skeleton active />;
  if (!jobs) return <Skeleton active />;

  return (
    <>
      <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
          <div className="mx-auto max-w-7xl">
            <div className="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
              <nav
                className="flex items-center justify-between lg:justify-start"
                aria-label="Global"
              >
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">
                    {recruiter?.companyName ?? ""}
                  </span>
                  <img
                    alt={`${recruiter?.companyName ?? ""}`}
                    className="h-8 w-auto"
                    src={recruiter?.logo}
                  />
                </a>
              </nav>
            </div>
          </div>
        </header>

        <div className="relative">
          <div className="mx-auto max-w-7xl">
            <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg
                className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-400  sm:text-6xl">
                    {recruiter?.jobPortalHeroTitle ?? ""}
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {recruiter?.jobPortalHeroDescription ?? ""}
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <a
                      href="#jobs"
                      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      style={{
                        background:
                          recruiter?.themeColor || partner?.themeColor,
                      }}
                    >
                      Get started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
              src={recruiter?.jobPortalHeroImage}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-900 py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-400  sm:text-4xl">
            {recruiter?.jobPortalJobSectionTitle ?? ""}
          </h2>
          <p className="mt-6 text-xl leading-8 text-gray-600">
            {recruiter?.jobPortalJobSectionDescription ?? ""}
          </p>

          <div className="mt-5">
            <input
              type="text"
              placeholder="Search Jobs"
              className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>

          <div className=" mb-10 mx-auto flex max-w-2xl flex-col  justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row items-start mt-16">
            <div className="w-full lg:max-w-lg lg:flex-auto">
              <img
                src={recruiter?.jobPortalJobsSectionImage}
                alt=""
                className=" aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
              />
            </div>
            <div
              className="w-full lg:max-w-xl flex-auto relative"
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <h3 className="text-xl font-bold mb-3">Job openings</h3>
              {loading && (
                <div className="absolute ml-[50%] mt-5">
                  <Spin />
                </div>
              )}
              {jobs?.length === 0 ? (
                <Alert
                  type="info"
                  className="-my-8 divide-y divide-gray-100 mt-5"
                  message={`At this time, we do not have any vacant positions available${
                    searchTerm ? " for " + searchTerm + "." : "."
                  }`}
                />
              ) : (
                <ul className="-my-8 divide-y divide-gray-100" id="jobs">
                  {jobs?.slice?.(0, 3)?.map?.((opening) => (
                    <li key={opening._id} className="py-8">
                      <dl className="relative flex flex-wrap gap-x-3">
                        <dt className="sr-only">Role</dt>
                        <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-400 ">
                          <a href={`/page/${opening._id}`} target="_blank">
                            {opening.name}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                          </a>
                        </dd>
                        <dt className="sr-only">Description</dt>
                        <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">
                          {opening.valueProposition}
                        </dd>
                        <dt className="sr-only">Contract Type</dt>
                        <dd className="mt-4 text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
                          {opening.contractType}
                        </dd>
                        <dt className="sr-only">Location</dt>
                        <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                          <svg
                            viewBox="0 0 2 2"
                            className="h-0.5 w-0.5 flex-none fill-gray-300"
                            aria-hidden="true"
                          >
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          {opening.location}
                        </dd>
                      </dl>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <ul className="-my-8 divide-y divide-gray-100 mt-10">
            {jobs?.slice?.(3)?.map?.((opening) => (
              <li key={opening._id} className="py-8">
                <dl className="relative flex flex-wrap gap-x-3">
                  <dt className="sr-only">Role</dt>
                  <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-400 ">
                    <a href={`/page/${opening._id}`} target="_blank">
                      {opening.alternativeName || opening.name}
                      <span className="absolute inset-0" aria-hidden="true" />
                    </a>
                  </dd>
                  <dt className="sr-only">Description</dt>
                  <dd
                    className="mt-2 w-full flex-none text-base leading-7 text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: handleTextDecoration(opening.valueProposition),
                    }}
                  ></dd>
                  <dt className="sr-only">Contract Type</dt>{" "}
                  <dd className="mt-4 text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
                    {opening.contractType}
                  </dd>
                  <dt className="sr-only">Location</dt>
                  <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                    <svg
                      viewBox="0 0 2 2"
                      className="h-0.5 w-0.5 flex-none fill-gray-300"
                      aria-hidden="true"
                    >
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    {opening.location}
                  </dd>
                </dl>
              </li>
            ))}
          </ul>

          {pagination.total >=
            recruiter?.jobsPerPage * pagination.currentPage && (
            <div className="flex justify-center mt-10">
              <Button
                className="rounded-md bg-indigo-500 px-3.5  text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 !hover:text-white"
                style={{
                  background: recruiter?.themeColor || partner?.themeColor,
                }}
                loading={loading}
                onClick={() => loadMore({ page: pagination.currentPage + 1 })}
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
