import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class UserService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  searchUsers(data) {
    return this.api.post("/searchUsers", data);
  }
  updateUser(id, data) {
    return this.api.put(`/updateUser?id=${id}`, data);
  }
  deleteTeamMember(id) {
    return this.api.delete(`/deleteTeamMember?id=${id}`);
  }
  updateAccessLevel(id, accessLevel = "read-write") {
    return this.api.put(`/updateAccessLevel?id=${id}`, { accessLevel });
  }
  contactDeveloper(title, message) {
    return this.api.post(`/contactDeveloper`, { title, message });
  }

  inviteUser(data) {
    return this.api.post(`/inviteUser`, {
      ...data,
      origin: window.location.origin,
    });
  }

  deleteFile(publicId) {
    return this.api.delete(`/deleteFile?publicId=${publicId}`);
  }

  postAd(data) {
    return this.api.post(`/postAd`, data);
  }
  changeCampaignStatus({ active, campaignId, _id }) {
    return this.api.post(`/changeCampaignStatus`, {
      active,
      campaignId,
      _id,
    });
  }
  extendTokenTime() {
    return this.api.post(`/extendTokenTime`);
  }
  getOauthUri() {
    return this.api.get(`/getOauthUri`);
  }
  getCampaignStats(campaignId) {
    return this.api.get(`/getCampaignStats?campaignId=${campaignId}`);
  }
  getVacancyCampaignStats(vacancyId) {
    return this.api.get(`/getVacancyCampaignStats?vacancyId=${vacancyId}`);
  }
}

export default new UserService(`${getBackendUrl()}/user`);
