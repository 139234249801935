import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import JobOpenings from "../../JobPortal/JobOpenings";
import { login, logout } from "../../redux/auth/actions";
import { getPartner } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import CreateVacancy from "../Dashboard/vacancies/CreateVacancy";
import { Button } from "./Button";
import { Container } from "./Container";
import logoLaravel from "./images/logos/laravel.svg";
import logoMirage from "./images/logos/mirage.svg";
import logoStatamic from "./images/logos/statamic.svg";
import logoStaticKit from "./images/logos/statickit.svg";
import logoTransistor from "./images/logos/transistor.svg";
import logoTuple from "./images/logos/tuple.svg";

export function Hero({ data }) {
  const partner = data || useSelector(getPartner);

  return (
    <Container className="pb-8 pt-4 text-center lg:pt-6 mb-16 sm:mb-8">
      <div
        className="bg-black text-white h-[80vh] flex flex-col justify-center items-center space-y-8 px-6 rounded-3xl pt-16"
        style={{ height: "fit-content" }}
      >
        <h1
          className="text-3xl md:text-5xl lg:text-6xl bg-clip-text text-transparent font-semibold m-0 bg-gradient-to-r dark:from-white from-35% dark:to-slate-500 from-slate-400 to-slate-700 tracking-[-2.56px] md:leading-tight lg:leading-tight"
          dangerouslySetInnerHTML={{
            __html: partner?.heroTitle?.replace?.(
              /\*\*(.*?)\*\*/g,
              function (match, group1) {
                return `
              <span class="relative whitespace-nowrap text-slate-200">
              <svg
                aria-hidden="true"
                view-box="0 0 418 42"
                class="absolute left-0 top-2/3 h-[0.58em] w-full fill-slate-200"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span class="relative">${group1}</span>
            </span>
              `;
              }
            ),
          }}
        ></h1>
        <p className="text-md md:text-lg lg:text-xl text-secondary max-w-[650px] -tracking-[0.2px] leading-tight md:leading-tight lg:leading-tight">
          {partner?.heroSubline}
        </p>
        <div className="flex flex-col space-y-4 w-[75%] min-w-[250px]">
          {partner?.activateLandingPageDemo && (
            <div
              className="mt-2 flex justify-center w-full"
              onClick={() => {
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                store.dispatch(logout());
                localStorage.demoVacancy = "";
              }}
            >
              <CreateVacancy GenerateSectionOnly />
            </div>
          )}
        </div>

        {partner?.videoLink && (
          <div className=" flex justify-center gap-x-6 relative top-16">
            <iframe
              width={720}
              height={405}
              style={{ maxWidth: "100%" }}
              src={partner.videoLink.replace("watch?v=", "embed/")}
              title="YouTube video player"
              className="rounded-lg"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        )}
      </div>
    </Container>
  );
}
