import {
  Button,
  Image,
  List,
  Popconfirm,
  Skeleton,
  Upload,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { countries } from "../../../data/constants";
import { login } from "../../../redux/auth/actions";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import GoogleService from "../../../service/GoogleService";
import PublicService from "../../../service/PublicService";
import UploadService from "../../../service/UploadService";
import UserService from "../../../service/UserService";

const CreateAdCreatives = () => {
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const location = useLocation();
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = (pref_id) => {
      const id = pref_id ?? searchParams.get("id");
      if (id) {
        setFormData(null);

        CrudService.getSingle("AdCampaign", id).then((res) => {
          if (res.data) setFormData(res.data);
          setGeneratedImages(res.data?.adImages ?? []);
        });
      }
    };

    getData();
  }, [location, user]);

  const handleFinish = useCallback(async () => {
    if (loading) return;

    const id = searchParams.get("id");
    const res = await CrudService.getSingle("AdCampaign", id);
    const data = res.data;

    try {
      await UserService.extendTokenTime();
    } catch (e) {
      await AuthService.updateMe({ silent: true, metaAccessToken: "" });

      const me = await AuthService.me();
      store.dispatch(login(me.data.me));
      navigate("/dashboard/createAd?defaultStep=2");
    }
    console.log(data);
    const funnel = await CrudService.getSingle("Vacancy", data.vacancy);
    if (funnel.data?.length < 1)
      return message.error("At least 1 image is required");

    if (user?.metaAccessToken) {
      await UserService.postAd({
        adImages: data?.adImages,
        adBudget: data?.monthlyBudget / 30,
        promote: funnel.data,
        adHeadline: data?.adHeadline,
        adDescription: data?.adDescription,
        adPrimaryText: data?.adPrimaryText,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaign: res.data?.campaign?.id,
        });
      });
    }

    if (user?.connectGoogle) {
      await GoogleService.createAd({
        days: 30,
        adDescription: [data?.adDescription],
        adHeadline: [data?.adHeadline],
        vacancyLocation:
          countries.find((c) => c.value === user.country)?.label ??
          "United States",
        path1: "thesalesai",
        path2: "page",
        final_urls: [`https://thesalesai.ai/page/${funnel?.data?._id}`],
        budget: data?.monthlyBudget / 30,
      }).then(async (res) => {
        await CrudService.update("AdCampaign", data._id, {
          campaignGoogle: res.data?.campaign?.id,
        });
      });
    }

    await CrudService.update("AdCampaign", data._id, {
      enabled: true,
    });

    navigate("/dashboard/adautomation");
  }, [loading, navigate, searchParams, user]);

  const generateImage = async () => {
    const id = searchParams.get("id");
    const res = await CrudService.getSingle("AdCampaign", id);
    const data = res.data;
    if (data.adImages.length > 4)
      return message.error("Maximum 5 creatives are allowed");
    const funnel = await CrudService.getSingle("Vacancy", data.vacancy);

    const response = await PublicService.replicateGen({
      input: {
        prompt: `${JSON.stringify(funnel.data.pageContent).slice(0, 1000)}

For this landing page, we need a picture that describes that serves as an ad creative. Can you generate it?

The generated ad creative must not have any text or letters
`,
      },
      id: "bytedance/sdxl-lightning-4step:5f24084160c9089501c1b3545d9be3c27883ae2239b6f412990e82d4a6210f8f",
    });

    const generatedImage = response?.data?.output?.[0];
    if (generatedImage) {
      setGeneratedImages([...generatedImages, generatedImage]);
      await addImageToCampaignImages(generatedImage);
    }
  };

  const handleFileUpload = useCallback(
    async ({ file }) => {
      if (generatedImages.length >= 5)
        return message.error("Maximum 5 creatives are allowed");

      try {
        const result = await UploadService.upload(file);
        const imageUrl = result.data.secure_url;
        setGeneratedImages([...generatedImages, imageUrl]);
        await addImageToCampaignImages(imageUrl);
      } catch (error) {
        message.error("Failed to upload image");
      }
    },
    [generatedImages]
  );

  const addImageToCampaignImages = async (imageUrl) => {
    const id = searchParams.get("id");
    setFormData((formData) => ({
      ...formData,
      adImages: [...(formData?.adImages || []), imageUrl],
    }));

    const res = await CrudService.getSingle("AdCampaign", id);
    await CrudService.update("AdCampaign", id, {
      adImages: [...(res.data?.adImages || []), imageUrl],
    });
  };

  const deleteImage = async (imageUrl) => {
    const id = searchParams.get("id");
    setFormData((formData) => ({
      ...formData,
      adImages: formData?.adImages.filter((image) => image !== imageUrl),
    }));

    const res = await CrudService.getSingle("AdCampaign", id);
    await CrudService.update("AdCampaign", id, {
      adImages: res.data?.adImages.filter((image) => image !== imageUrl),
    });

    setGeneratedImages(generatedImages.filter((image) => image !== imageUrl));
  };

  if (!formData) return <Skeleton active />;

  return (
    <div className="p-4">
      <Button type="primary" onClick={generateImage} className="mb-4">
        Generate New Image
      </Button>
      <Upload
        accept="image/*"
        showUploadList={false}
        customRequest={handleFileUpload}
      >
        <Button type="dashed" className="mb-4">
          Upload Image
        </Button>
      </Upload>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={generatedImages}
        renderItem={(image) => (
          <List.Item>
            <Image src={image} alt="Generated" />

            <Popconfirm
              title="Are you sure you want to delete this image?"
              onConfirm={() => deleteImage(image)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger className="mt-2">
                Delete
              </Button>
            </Popconfirm>
          </List.Item>
        )}
      />
      <Popconfirm
        title="Are you sure you want to publish the campaign?"
        onConfirm={handleFinish}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" className="mt-4">
          Publish Campaign
        </Button>
      </Popconfirm>
    </div>
  );
};

export default CreateAdCreatives;
