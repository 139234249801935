import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Progress, Skeleton, Spin, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { currencies } from "../../data/currencies";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import { featureMap } from "../Landing/Pricing";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Billing = () => {
  const [me, setMe] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const partner = useSelector(getPartner);
  const [frequency, setFrequency] = useState(0);
  const loading = useSelector(selectLoading);

  const myCurrency = useMemo(
    () => currencies.find((c) => c.iso === partner?.currency)?.symbol ?? "$",
    [partner]
  );

  useEffect(() => {
    AuthService.me().then((data) => {
      setMe(data.data);
    });
    AuthService.getSubscription().then((data) => setSubscription(data.data));
  }, []);

  const displayablePricing = partner?.pricing?.filter?.(
    (t) =>
      // If a value here is null, it means unlimited
      !me?.tier?._id ||
      (t._id !== me?.tier?._id &&
        (t.maxFunnels === null ||
          (typeof me?.tier?.maxFunnels === "number" &&
            t.maxFunnels >= me?.tier?.maxFunnels)) &&
        (t.maxCandidates === null ||
          (typeof me?.tier?.maxCandidates === "number" &&
            t.maxCandidates >= me?.tier?.maxCandidates)) &&
        (t.maxMessaging === null ||
          (typeof me?.tier?.maxMessaging === "number" &&
            t.maxMessaging >= me?.tier?.maxMessaging)) &&
        (t.maxTeamSize === null ||
          (typeof me?.tier?.maxTeamSize === "number" &&
            t.maxTeamSize >= me?.tier?.maxTeamSize)))
  );

  if (!me) return <Skeleton active />;
  return (
    <>
      <div className="bg-transparent sm:p-8">
        <div className="bg-white dark:bg-gray-900 py-4 sm:py-8 rounded-lg">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Billing
              </h2>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Here you can manage your subscription plan.
            </p>
            <div className="mt-16 flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">
                  Payment frequency
                </RadioGroup.Label>
                {[0, 1].map((option) => (
                  <RadioGroup.Option
                    key={option}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? "bg-indigo-600 text-white" : "text-gray-500",
                        "cursor-pointer rounded-full px-2.5 py-1"
                      )
                    }
                  >
                    <span>{option === 0 ? "Monthly" : "Annually"}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div
              className={`isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none ${
                (displayablePricing?.length + (me?.tier?.features ? 1 : 0)) %
                  3 ===
                0
                  ? "lg:grid-cols-3"
                  : ""
              }`}
            >
              {me?.tier?.features && (
                <div
                  className={classNames(
                    "ring-2 ring-indigo-700",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      className={classNames(
                        "text-indigo-700",
                        "text-lg font-semibold leading-8"
                      )}
                    >
                      {me?.tier?.name}
                    </h3>
                    <p className="rounded-full bg-indigo-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                      Current
                    </p>
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">
                    {me?.tier?.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-400 ">
                      {myCurrency}
                      {me?.tier?.[
                        frequency === 0 ? "monthlyPrice" : "annualPrice"
                      ] / 100}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      / {frequency === 0 ? "month" : "year"}
                    </span>
                  </p>

                  <p className="order-first font-display text-sm font-light tracking-tight text-black dark:text-gray-400 ">
                    + {myCurrency}
                    {me?.tier?.[
                      frequency === 0
                        ? "extraPerTeamMonthly"
                        : "extraPerTeamAnnual"
                    ] / 100}{" "}
                    per team mate
                  </p>

                  {!!me?.tier?.maxFunnels &&
                    me?.tier?.maxFunnels < 999999999 && (
                      <div className="w-[80%] mt-5">
                        <label>Funnels created</label>
                        <div>
                          <Progress
                            percent={
                              100 * (me?.vacancyNum / me?.tier?.maxFunnels)
                            }
                            status="active"
                            format={(percent) =>
                              `${me?.vacancyNum} / ${me?.tier?.maxFunnels}`
                            }
                          />
                        </div>
                      </div>
                    )}
                  {!!me?.tier?.maxCandidates &&
                    me?.tier?.maxCandidates < 999999999 && (
                      <div className="w-[80%] mt-5">
                        <label>Leads</label>
                        <div>
                          <Progress
                            percent={
                              100 * (me?.candidateNum / me?.tier?.maxCandidates)
                            }
                            status="active"
                            format={(percent) =>
                              `${me?.candidateNum} / ${me?.tier?.maxCandidates}`
                            }
                          />
                        </div>
                      </div>
                    )}
                  {!!me?.tier?.maxMessaging &&
                    me?.tier?.maxMessaging < 999999999 && (
                      <div className="w-[80%] mt-5">
                        <label>Messaging templates stored</label>
                        <div>
                          <Progress
                            percent={
                              100 * (me?.messageNum / me?.tier?.maxMessaging)
                            }
                            status="active"
                            format={(percent) =>
                              `${me?.messageNum} / ${me?.tier?.maxMessaging}`
                            }
                          />
                        </div>
                      </div>
                    )}
                  {!!me?.tier?.maxTeamSize &&
                    me?.tier?.maxTeamSize < 999999999 && (
                      <div className="w-[80%] mt-5">
                        <label>Team mates</label>
                        <div>
                          <Progress
                            percent={
                              100 * (me?.teamNum / me?.tier?.maxTeamSize)
                            }
                            status="active"
                            format={(percent) =>
                              `${me?.teamNum} / ${me?.tier?.maxTeamSize}`
                            }
                          />
                        </div>
                      </div>
                    )}

                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                  >
                    {me?.tier?.features?.map?.((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {featureMap[feature]}
                      </li>
                    ))}
                    {[
                      "AI Campaign Generator",
                      "AI Ad Automation",
                      "AI Cold Calling",
                      "AI Email Marketing",
                      "AI Webinar Automation",
                      "AI Lead Generation",
                      "E-Learning",
                    ].map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {displayablePricing?.map((tier, i) => (
                <div
                  key={i}
                  className={classNames(
                    tier.featured
                      ? "ring-2 ring-indigo-600"
                      : "ring-1 ring-gray-200",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      id={i}
                      className={classNames(
                        tier.featured
                          ? "text-indigo-600"
                          : "text-gray-900 dark:text-gray-400 ",
                        "text-lg font-semibold leading-8"
                      )}
                    >
                      {tier.name}
                    </h3>
                    {tier.featured ? (
                      <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-400 ">
                      {myCurrency}
                      {tier[frequency === 0 ? "monthlyPrice" : "annualPrice"] /
                        100}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      / {frequency === 0 ? "month" : "year"}
                    </span>
                  </p>

                  <p className="order-first font-display text-sm font-light tracking-tight text-black dark:text-gray-400 ">
                    + {myCurrency}
                    {tier[
                      frequency === 0
                        ? "extraPerTeamMonthly"
                        : "extraPerTeamAnnual"
                    ] / 100}{" "}
                    per team mate
                  </p>

                  <a
                    onClick={async () => {
                      if (loading) return;

                      const res = await AuthService.createSubscription({
                        tier: tier._id,
                        return_url: window.location.href,
                        interval: frequency === 0 ? "month" : "year",
                      });

                      if (res.data.paymentLink)
                        window.location.href = res.data.paymentLink;
                      else {
                        message.success("Your plan has been upgraded");

                        AuthService.me().then((data) => {
                          setMe(data.data);
                        });
                        AuthService.getSubscription().then((data) =>
                          setSubscription(data.data)
                        );
                      }
                    }}
                    aria-describedby={i}
                    className={classNames(
                      tier.featured
                        ? "bg-indigo-600 text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500"
                        : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    {loading ? <Spin>Upgrade Plan</Spin> : <>Upgrade plan</>}
                  </a>
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {featureMap[feature]}
                      </li>
                    ))}

                    {[
                      "AI Campaign Generator",
                      "AI Ad Automation",
                      "AI Cold Calling",
                      "AI Email Marketing",
                      "AI Webinar Automation",
                      "AI Lead Generation",
                      "E-Learning",
                    ].map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        <a
          onClick={async () => {
            if (subscription?.link) window.location.href = subscription?.link;
            else {
              const res = await AuthService.userActivation({
                return_url: window.location.href,
              });
              window.location.href = res.data.link;
            }
          }}
          className={classNames(
            "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
            "cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          )}
        >
          Billing Management
        </a>
        <a
          onClick={async () => {
            if (subscription?.link) window.location.href = subscription?.link;
            else {
              const res = await AuthService.userActivation({
                return_url: window.location.href,
              });
              window.location.href = res.data.link;
            }
          }}
          className={classNames(
            "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
            "cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          )}
        >
          Invoices
        </a>
      </div>
    </>
  );
};

export default Billing;
