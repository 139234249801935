import {
  Alert,
  Breadcrumb,
  Drawer,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import Cookies from "js-cookie";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BsBarChartSteps } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid"; // Import uuid to generate unique keys

import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoMdAddCircleOutline, IoMdArrowDropright } from "react-icons/io";
import { IoEyeSharp, IoReorderFour } from "react-icons/io5";
import {
  MdDeleteForever,
  MdEdit,
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowRight,
  MdPreview,
} from "react-icons/md";
import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { selectLoading, selectUser } from "../../redux/auth/selectors";
import CrudService from "../../service/CrudService";
import PublicService from "../../service/PublicService";
import { changeIndigoShades, generateTailwindPalette } from "../Dashboard";
import MediaLibrary from "../Dashboard/vacancies/MediaLibrary";
import { TextField } from "../Landing/Fields";
import DragDropEditor from "./DragDropEditor";
import FunnelSteps from "./FunnelSteps";
import { LeftArrow, RightArrow } from "./SVGs";
import { Button, Heading, Img, RatingBar, Text } from "./components";
import { exampleData } from "./exampleData";

const nameRender = {
  AdminFeatures: "Features",
  CustomerPainPoints: "Customer Struggles",
  CallToAction: "CTA",
  FeatureSlider: "Features Slider",
  TestemonialSectionSlider: "Testimonials",
  DivideLine: "Divider",
  LeadForm: "Lead Form",
  ValueProp: "Value Proposition",
};
function safeConvertToNumber(str) {
  // Check if the string contains only digits (and possibly one decimal point)
  const actualString = str.replace(/\,/g, ".");
  if (/^\d*\.?\d+$/.test(actualString)) {
    return parseFloat(actualString);
  } else {
    return 0; // Default to 0 if the input is not a valid number
  }
}

function isEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

const OneSlide = ({
  slide,
  index,
  onChange,
  isEditMode,
  setPictureChangeModal,
  content_id,
}) => {
  return (
    <div className="flex flex-row w-[90%] mx-auto   px-8 mdx:p-5 smx:px-5 mdx:flex-col pt-20">
      <div className="flex flex-col w-2/3 gap-2 pb-10 mdx:w-full justify-evenly mdx:min-h-fit min-h-fit">
        <div className="flex self-start ">
          {isEditMode ? (
            <DragDropEditor
              defauldClassname="text-indigo-500 uppercase text-base font-semibold text-gray-900_02 font-inter"
              initialComponents={slide.title}
              onChange={(e) => {
                onChange(e, `slides.${index}.title`);
              }}
            />
          ) : typeof slide.title === "string" ? (
            <span className="text-indigo-500 uppercase text-base font-semibold text-gray-900_02 font-inter">
              {slide.title}
            </span>
          ) : (
            <DragDropEditor initialComponents={slide.title} readOnly />
          )}
        </div>
        <div className="  flex max-w-[740px]  flex-col gap-5">
          {isEditMode ? (
            <DragDropEditor
              defauldClassname="leading-[44px] tracking-[-0.72px] smx:text-2xl text-gray-900_02 font-inter text-4xl font-semibold md:text-[34px] sm:text-[32px]"
              initialComponents={slide.description}
              onChange={(e) => {
                onChange(e, `slides.${index}.description`);
              }}
            />
          ) : typeof slide.description === "string" ? (
            <span className="leading-[44px] tracking-[-0.72px] smx:text-2xl text-gray-900_02 font-inter text-4xl font-semibold md:text-[34px] sm:text-[32px]">
              {slide.description}
            </span>
          ) : (
            <DragDropEditor initialComponents={slide.description} readOnly />
          )}
        </div>
      </div>
      <div className="flex items-center w-1/3 mdx:w-full">
        <div className="relative flex ml-auto  rounded-md mdx:mx-auto p-1 bg-indigo-500 ">
          <svg
            width="134"
            height="134"
            viewBox="0 0 134 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -top-16 -right-20"
          >
            <path
              d="M86.0551 19.3647L82.0758 36.7392C78.5168 52.2789 85.2584 68.3584 98.8356 76.7135L114.016 86.055L96.6415 82.0758C81.1018 78.5167 65.0222 85.2583 56.6672 98.8356L47.3256 114.016L51.3049 96.6415C54.8639 81.1017 48.1224 65.0222 34.5451 56.6671L19.3648 47.3256L36.7392 51.3048C52.2789 54.8639 68.3585 48.1223 76.7136 34.545L86.0551 19.3647Z"
              fill="var(--indigo-500)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_333_1325"
                x1="19.3648"
                y1="47.3256"
                x2="114.016"
                y2="86.055"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#134E4A" />
                <stop offset="1" stop-color="#2CB4AB" />
              </linearGradient>
            </defs>
          </svg>

          <Img
            src={slide.image}
            alt="screenmockup"
            className=" w-full max-w-[450px]  md:aspect-[333/222]  object-fit mdx:h-auto my-auto"
          />
        </div>

        {isEditMode && (
          <div className="w-full flex justify-center">
            <Button
              className="mt-[-15px]"
              onClick={() =>
                setPictureChangeModal({
                  key: `slides.${index}.image`,
                  content_id,
                })
              }
            >
              Change Image
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Tabs = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <div className="mb-8 flex  flex-col gap-[73px] self-end mdx:w-full mdx:gap-[54px] mdx:p-5 smx:gap-9">
      <div className="w-full overflow-hidden scrollbar-hidden">
        <div className="flex w-full overflow-x-auto container-xs ">
          {tabs.map((tab, index) => {
            return (
              <Heading
                key={index}
                as="h5"
                className={`w-full text-base mdx:text-sm relative 
              ${
                currentTab.name == tab.name
                  ? "border-green-400"
                  : "border-gray-100_01"
              } 
              transition-all duration-300 ease-linear border-gray-100_01 border-t-4 cursor-pointer border-solid  mdx:px-5 px-[35px] pb-px pt-7 text-center capitalize whitespace-nowrap  smx:pt-5`}
              >
                <button onClick={() => setCurrentTab(tab)}> {tab.name}</button>
              </Heading>
            );
          })}
        </div>
      </div>
      {currentTab.component}
    </div>
  );
};

const LeadForm = ({
  headline,
  description,
  privacyPolicyLink,
  onSubmitStepId,
  isEditMode,
  addNextStep,
  content_id,
  availableFunnelSteps,
}) => {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) return;
      if (isEditMode) return;
      if (!id || id === "demo") return;

      const fullname = e.target[0].value;
      const firstname = fullname?.split?.(" ")?.[0];
      const lastname = fullname?.split?.(" ")?.slice?.(1)?.join?.(" ");
      const email = e.target[1].value;
      const phone = e.target[2].value;

      await PublicService.createVacancySubmission({
        VacancyId: id,
        formData: {
          firstname,
          lastname,
          email,
          phone,
        },
        funnelUUID: localStorage?.[`funnelUUID_${id}`],
        tracking: {
          utm_campaign: searchParams.get("utm_campaign") ?? null,
          utm_source: searchParams.get("utm_source") ?? null,
          utm_medium: searchParams.get("utm_medium") ?? null,
          utm_content: searchParams.get("utm_content") ?? null,
          utm_term: searchParams.get("utm_term") ?? null,
          salesforce_uuid: searchParams.get("salesforce_uuid") ?? null,
        },
      });

      e.target[0].value = "";
      e.target[1].value = "";
      e.target[2].value = "";

      if (onSubmitStepId) {
        navigate(`/page/${onSubmitStepId}`);
      } else {
        message.success("Thanks for signing up!");
      }
    },
    [id, loading]
  );
  return (
    <div id="contact" className="relative">
      <div
        className="w-full border-box pt-6 pl-4 pr-4 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div
              className="wrapper break-words text-center svelte-tb17ze responsive"
              style={{ textcolor: "#343435", linkcolor: "#2542aa" }}
            >
              <h2>
                <strong>{headline}</strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full border-box pt-4 pl-6 pr-6 pb-4"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full">
          <div className="transition-wrapper" style={{}}>
            <div
              className="w-full border-box pb-6"
              style={{
                background: "center center / cover no-repeat transparent",
              }}
            >
              <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                <div className="transition-wrapper" style={{}}>
                  <div
                    className="wrapper break-words text-center svelte-tb17ze responsive"
                    style={{ textcolor: "#343435", linkcolor: "#0076FF" }}
                  >
                    <h4>{description}</h4>
                  </div>
                </div>
              </div>
            </div>{" "}
            <form onSubmit={handleSubmit} className="sm:mt-2">
              <div className="wrapper flex items-center relative box-border overflow-hidden rounded-t-lg svelte-1ruxt0u">
                <u className="absolute no-underline h-[18px] left-6 select-none flex items-center">
                  <em-emoji id="wave" size="24px">
                    <span className="emoji-mart-emoji" data-emoji-set="native">
                      <span
                        style={{
                          fontSize: "24px",
                          fontFamily:
                            'EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"',
                        }}
                      >
                        👋
                      </span>
                    </span>
                  </em-emoji>
                </u>{" "}
                <div className="line svelte-r3jp73">
                  <svg viewBox="0 0 64 20" className="svelte-r3jp73">
                    <path d="M 0,14.5 C 1.8,14.5 7.2,14.5 12,14.5 C 16.8,14.5 26,14.5 32,14.5 C 38,14.5 47.2,14.5 52,14.5 C 56.8,14.5 62.2,14.5 64,14.5" />
                  </svg>
                </div>{" "}
                <input
                  className="input placeholder-gray-400 box-border w-full h-[64px] px-6 border-0 border-l border-r border-t mb-0 font-ui text-base align-middle bg-white transition appearance-none font-light focus:outline-none focus:bg-gray-50 rounded-none border-black/10 rounded-t-lg pl-[70px] svelte-1ruxt0u"
                  type="text"
                  placeholder="Your Name"
                  name="fname"
                  required={!isEditMode}
                />
              </div>
              <div className="wrapper flex items-center relative box-border overflow-hidden svelte-1ruxt0u">
                <u className="absolute no-underline h-[18px] left-6 select-none flex items-center">
                  <em-emoji id="email" size="24px">
                    <span className="emoji-mart-emoji" data-emoji-set="native">
                      <span
                        style={{
                          fontSize: "24px",
                          fontFamily:
                            'EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"',
                        }}
                      >
                        ✉️
                      </span>
                    </span>
                  </em-emoji>
                </u>{" "}
                <div className="line svelte-r3jp73">
                  <svg viewBox="0 0 64 20" className="svelte-r3jp73">
                    <path d="M 0,14.5 C 1.8,14.5 7.2,14.5 12,14.5 C 16.8,14.5 26,14.5 32,14.5 C 38,14.5 47.2,14.5 52,14.5 C 56.8,14.5 62.2,14.5 64,14.5" />
                  </svg>
                </div>{" "}
                <input
                  className="input placeholder-gray-400 box-border w-full h-[64px] px-6 border-0 border-l border-r border-t mb-0 font-ui text-base align-middle bg-white transition appearance-none font-light focus:outline-none focus:bg-gray-50 rounded-none border-black/10 pl-[70px] svelte-1ruxt0u"
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  required={!isEditMode}
                />
              </div>
              <div className="wrapper flex items-center relative box-border overflow-hidden svelte-1ruxt0u">
                <u className="absolute no-underline h-[18px] left-6 select-none flex items-center">
                  <em-emoji id="email" size="24px">
                    <span className="emoji-mart-emoji" data-emoji-set="native">
                      <span
                        style={{
                          fontSize: "24px",
                          fontFamily:
                            'EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"',
                        }}
                      >
                        📞
                      </span>
                    </span>
                  </em-emoji>
                </u>{" "}
                <div className="line svelte-r3jp73">
                  <svg viewBox="0 0 64 20" className="svelte-r3jp73">
                    <path d="M 0,14.5 C 1.8,14.5 7.2,14.5 12,14.5 C 16.8,14.5 26,14.5 32,14.5 C 38,14.5 47.2,14.5 52,14.5 C 56.8,14.5 62.2,14.5 64,14.5" />
                  </svg>
                </div>{" "}
                <input
                  className="input placeholder-gray-400 box-border w-full h-[64px] px-6 border-0 border-l border-r border-t mb-0 font-ui text-base align-middle bg-white transition appearance-none font-light focus:outline-none focus:bg-gray-50 rounded-none border-black/10 pl-[70px] svelte-1ruxt0u"
                  placeholder="Your Phone"
                  name="phone"
                />
              </div>
              <div className="wrapper checkbox flex items-center px-6 min-h-[68px] relative box-border overflow-hidden bg-white border-t border-l border-r border-b rounded-b-lg border-black/10 svelte-7xyl6n">
                <div
                  id
                  className="checkbox undefined svelte-kwd24b"
                  style={{
                    width: "24px",
                    height: "24px",
                    checkboxColorPrimary: "#0BB648",
                    checkboxColorSecondary: "#D2D2D2",
                  }}
                >
                  <input
                    id
                    required={!isEditMode}
                    type="checkbox"
                    name
                    className="svelte-kwd24b"
                  />{" "}
                </div>{" "}
                <div className="text">
                  <div
                    className="w-full border-box pt-4 pl-4 pr-4 pb-4"
                    style={{
                      background: "center center / cover no-repeat transparent",
                    }}
                  >
                    <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                      <div className="transition-wrapper" style={{}}>
                        <div
                          className="wrapper break-words text-left svelte-tb17ze responsive"
                          style={{
                            textcolor: "#0F0F0F",
                            linkcolor: "#0F0F0F",
                          }}
                        >
                          <h5>
                            I accept the{" "}
                            <a href={privacyPolicyLink} target="_blank">
                              privacy policy
                            </a>
                            .{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="mt-4">
                <div
                  className="w-full border-box "
                  style={{
                    background: "center center / cover no-repeat transparent",
                  }}
                >
                  <div className="mx-auto max-w-md sm:max-w-xl w-full">
                    <div className="transition-wrapper" style={{}}>
                      {isEditMode ? (
                        <button
                          onClick={() =>
                            addNextStep(content_id, onSubmitStepId)
                          }
                          className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button  bg-indigo-500 text-white dark:text-black font-semibold"
                          style={{
                            buttoncolor: "#F5F5F5",
                            buttonbgcolor: "#2542aa",
                            buttonbgcolordark: "hsla(227, 64%, 31%, 1)",
                          }}
                        >
                          <div
                            className="w-full border-box "
                            style={{
                              background:
                                "center center / cover no-repeat transparent",
                            }}
                          >
                            <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                              <div className="transition-wrapper" style={{}}>
                                <div
                                  className="wrapper break-words text-center svelte-tb17ze"
                                  style={{
                                    textcolor: "#F5F5F5",
                                    linkcolor: "#0076FF",
                                  }}
                                >
                                  <h4>
                                    Click to {onSubmitStepId ? "edit" : "add"}{" "}
                                    page after submit
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button
                          className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 svelte-k9qwtc bg-indigo-500 text-white dark:text-black font-semibold"
                          type="submit"
                          style={{
                            buttoncolor: "#F5F5F5",
                            buttonbgcolor: "#2542aa",
                            buttonbgcolordark: "hsla(227, 64%, 31%, 1)",
                          }}
                        >
                          <div
                            className="w-full border-box "
                            style={{
                              background:
                                "center center / cover no-repeat transparent",
                            }}
                          >
                            <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                              <div className="transition-wrapper" style={{}}>
                                <div
                                  className="wrapper break-words text-center svelte-tb17ze"
                                  style={{
                                    textcolor: "#F5F5F5",
                                    linkcolor: "#0076FF",
                                  }}
                                >
                                  <h4>Sign up</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {onSubmitStepId &&
        isEditMode &&
        availableFunnelSteps?.find?.((a) => a._id === onSubmitStepId)
          ?.funnelStepName && (
          <div
            className="w-full border-box pt-2 pl-8 pr-8 pb-2"
            style={{
              background: "center center / cover no-repeat transparent",
            }}
          >
            <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
              <div className="transition-wrapper" style={{}}>
                <div
                  className="wrapper break-words text-center svelte-tb17ze responsive"
                  style={{ textcolor: "#343435", linkcolor: "#2542aa" }}
                >
                  <h4>
                    This form will send visitors into:{" "}
                    {
                      availableFunnelSteps?.find?.(
                        (a) => a._id === onSubmitStepId
                      )?.funnelStepName
                    }
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
      {!isEditMode && (
        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-2"
          style={{ background: "center center / cover no-repeat transparent" }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div
                className="wrapper break-words text-center svelte-tb17ze responsive"
                style={{ textcolor: "#343435", linkcolor: "#2542aa" }}
              >
                <h4>Your data is secured 🔐</h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const DivideLine = () => {
  return (
    <>
      <div
        className="w-full border-box pt-4 pl-8 pr-8 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div
              className="divider divider h-0 leading-none text-black/85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent svelte-79cxf8"
              style={{ dividercolor: "#343435" }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Benefits = ({ title, description, benefits, onChange, isEditMode }) => {
  return (
    <>
      <div
        className="w-full border-box pt-2 pl-8 pr-8 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            {isEditMode ? (
              <DragDropEditor
                defauldClassname=""
                initialComponents={title}
                onChange={(e) => {
                  onChange(e, `title`);
                }}
              />
            ) : typeof title === "string" ? (
              <span className="">{title}</span>
            ) : (
              <DragDropEditor initialComponents={title} readOnly />
            )}
          </div>
        </div>
      </div>

      <div
        className="w-full border-box pt-2 pl-8 pr-8 pb-4"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            {isEditMode ? (
              <DragDropEditor
                defauldClassname="wrapper break-words text-center svelte-tb17ze responsive text-[]"
                initialComponents={description}
                onChange={(e) => {
                  onChange(e, `description`);
                }}
              />
            ) : typeof description === "string" ? (
              <span className="wrapper break-words text-center svelte-tb17ze responsive text-[#343435]">
                {description}
              </span>
            ) : (
              <DragDropEditor initialComponents={description} readOnly />
            )}
          </div>
        </div>
      </div>
      <div
        className="w-full border-box pt-6 pl-6 pr-6 pb-6"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div className="grid text-left gap-6 grid-cols-1 md:grid-cols-2">
              {benefits?.map?.((benefit, i) => (
                <div
                  key={i}
                  className="flex box-border w-full align-top items-start"
                >
                  <div
                    className="flex-shrink-0 flex items-center justify-center svelte-10vu51z"
                    style={{ width: "48px", height: "48px" }}
                  >
                    <em-emoji id="zap" size="32px">
                      <span
                        className="emoji-mart-emoji"
                        data-emoji-set="native"
                      >
                        <span
                          style={{
                            fontSize: "32px",
                            fontFamily:
                              'EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"',
                          }}
                        >
                          {isEditMode ? (
                            <DragDropEditor
                              defauldClassname="wrapper break-words text-center svelte-tb17ze responsive text-[]"
                              initialComponents={benefit.emoji}
                              onChange={(e) => {
                                onChange(e, `benefits.${i}.emoji`);
                              }}
                            />
                          ) : typeof benefit.emoji === "string" ? (
                            <span className="wrapper break-words text-center svelte-tb17ze responsive text-[#3B5998]">
                              {benefit.emoji}
                            </span>
                          ) : (
                            <DragDropEditor
                              initialComponents={benefit.emoji}
                              readOnly
                            />
                          )}
                        </span>
                      </span>
                    </em-emoji>
                  </div>{" "}
                  <div
                    className="text flex items-center flex-grow break-words ml-6 min-w-0 svelte-10vu51z"
                    style={{ minHeight: "48px", fontSize: "16px" }}
                  >
                    <div
                      className="w-full border-box "
                      style={{
                        background:
                          "center center / cover no-repeat transparent",
                      }}
                    >
                      <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                        <div className="transition-wrapper" style={{}}>
                          <div
                            className="wrapper break-words text-left svelte-tb17ze inheritSize responsive"
                            style={{
                              textcolor: "#343435",
                              linkcolor: "#0076FF",
                            }}
                          >
                            <h4>
                              <strong>
                                {isEditMode ? (
                                  <DragDropEditor
                                    defauldClassname=""
                                    initialComponents={benefit.title}
                                    onChange={(e) => {
                                      onChange(
                                        e,
                                        `benefits.${i}.benefit.title`
                                      );
                                    }}
                                  />
                                ) : typeof benefit.title === "string" ? (
                                  <span className="">{benefit.title}</span>
                                ) : (
                                  <DragDropEditor
                                    initialComponents={benefit.title}
                                    readOnly
                                  />
                                )}{" "}
                              </strong>
                              {isEditMode ? (
                                <DragDropEditor
                                  defauldClassname=""
                                  initialComponents={benefit.description}
                                  onChange={(e) => {
                                    onChange(e, `benefits.${i}.description`);
                                  }}
                                />
                              ) : typeof benefit.description === "string" ? (
                                <span className="">{benefit.description}</span>
                              ) : (
                                <DragDropEditor
                                  initialComponents={benefit.description}
                                  readOnly
                                />
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const ValueProp = ({
  title,
  description,
  image,
  onChange,
  isEditMode,
  setPictureChangeModal,
  content_id,
}) => {
  return (
    <>
      <div
        className="w-full border-box pt-2 pl-8 pr-8 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div
              className="wrapper break-words text-center svelte-tb17ze responsive"
              style={{ textcolor: "#2542aa", linkcolor: "#2542aa" }}
            >
              <h4>
                <strong>
                  {isEditMode ? (
                    <DragDropEditor
                      defauldClassname=""
                      initialComponents={title}
                      onChange={(e) => {
                        onChange(e, `title`);
                      }}
                    />
                  ) : typeof title === "string" ? (
                    <span className="">{title}</span>
                  ) : (
                    <DragDropEditor initialComponents={title} readOnly />
                  )}
                </strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full border-box pt-2 pl-8 pr-8 pb-4"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div
              className="wrapper break-words text-center svelte-tb17ze responsive"
              style={{ textcolor: "#343435", linkcolor: "#2542aa" }}
            >
              <h2>
                <strong>
                  {isEditMode ? (
                    <DragDropEditor
                      defauldClassname=""
                      initialComponents={description}
                      onChange={(e) => {
                        onChange(e, `description`);
                      }}
                    />
                  ) : typeof description === "string" ? (
                    <span className="">{description}</span>
                  ) : (
                    <DragDropEditor initialComponents={description} readOnly />
                  )}
                </strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full h-full bg-no-repeat"
        style={{ background: "transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl">
          <div className="transition-wrapper" style={{}}>
            <div className="p-6 sm:px-12 sm:py-6 md:px-20 md:py-10">
              <div className="imageWrapper overflow-hidden leading-[0] rounded">
                <div
                  className="wrapper   svelte-pj5vj2"
                  style={{ width: "100%" }}
                >
                  <div
                    className="svelte-k8l55j loaded"
                    style={{ position: "relative", width: "100%" }}
                  >
                    <div style={{ position: "relative", overflow: "hidden" }}>
                      <picture>
                        <source sizes="(max-width: 927px) 375px, 608px" />{" "}
                        <img
                          src={image}
                          alt=""
                          className="main svelte-k8l55j"
                          style={{ duration: "200ms" }}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                {isEditMode && (
                  <div className="w-full flex justify-center">
                    <Button
                      onClick={() =>
                        setPictureChangeModal({
                          key: `image`,
                          content_id,
                        })
                      }
                    >
                      Change Image
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomComponent = ({ onChange, isEditMode, description }) => {
  return (
    <div className="p-[12px] smx:p-5">
      {isEditMode ? (
        <DragDropEditor
          defauldClassname=""
          initialComponents={description}
          onChange={(e) => {
            onChange(e, `description`);
          }}
        />
      ) : typeof description === "string" ? (
        <span className="">{description}</span>
      ) : (
        <DragDropEditor initialComponents={description} readOnly />
      )}
    </div>
  );
};

const HeadlineComponent = ({ onChange, isEditMode, description }) => {
  return (
    <div className="p-[12px] smx:p-5">
      {isEditMode ? (
        <DragDropEditor
          defauldClassname=""
          initialComponents={description}
          onChange={(e) => {
            onChange(e, `description`);
          }}
        />
      ) : typeof description === "string" ? (
        <span className="">{description}</span>
      ) : (
        <DragDropEditor initialComponents={description} readOnly />
      )}
    </div>
  );
};

const Hero = ({
  headline,
  subline,
  description,
  image,
  video,
  benefits,
  onChange,
  isEditMode,
  content_id,
  setModal,
  setPictureChangeModal,
}) => {
  return (
    <>
      <div className="flex w-full flex-col items-end p-[12px] pt-1 smx:p-5   ">
        {/* features overview section */}

        <div className="relative flex flex-col gap-8 mx-auto mt-0 mdx:w-full smx:gap-8 isolate ">
          {/* product advantages section */}
          <div className="  mx-auto flex w-full max-w-[1960px] items-start gap-[42px] mdx:flex-col mdx:text-center mdx:p-5 ">
            <div className="flex w-[48%]   flex-col gap-6 mdx:w-full smx:pt-16 pt-24">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-[#475467] font-semibold smx:text-sm text-base"
                  initialComponents={description}
                  onChange={(e) => {
                    onChange(e, "description");
                  }}
                />
              ) : typeof description === "string" ? (
                <span className="text-[#475467] font-semibold smx:text-sm text-base">
                  {description}
                </span>
              ) : (
                <DragDropEditor initialComponents={description} readOnly />
              )}
            </div>
            <div className="mb-[55px]  flex flex-1 flex-col items-start gap-3  mdx:self-stretch smx:pt-16 pt-24  relative">
              <div className="flex w-[87%]  mx-auto justify-center rounded-[24px] bg-gray-200 p-10  mdx:w-full smx:p-5 ">
                <div className=" relative h-[320px] w-full rounded-lg  flex items-center justify-center p-1 bg-indigo-500 mdx:h-auto">
                  <Img
                    src={image}
                    alt="screenmockup"
                    className="object-cover w-full h-full "
                  />
                  {video && (
                    <Button
                      size="2xl"
                      shape="square"
                      className="absolute bottom-0 left-0 right-0 top-0 m-auto w-[80px]"
                    >
                      <Link to={video} target="_blank">
                        <Img src="/dhimages/img_button.svg" />
                      </Link>
                    </Button>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-center">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setModal([
                        {
                          label: "Video URL",
                          value: video,
                          key: "video",
                          content_id,
                        },
                      ])
                    }
                  >
                    Change Video
                  </Button>
                )}
              </div>
              <div className="w-full flex justify-center">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setPictureChangeModal({
                        key: "image",
                        content_id,
                      })
                    }
                  >
                    Change Thumbnail
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* benefits list section */}
          <div className="px-8 container-sm mdx:p-5 smx:px-5">
            <div className="flex gap-6 mdx:flex-col">
              {benefits?.map?.((d, index) => (
                <div
                  key={"container" + index}
                  className="flex w-full items-center justify-center gap-3 rounded-[12px] border-[1.5px] border-solid border-gray-200 bg-gray-200 p-3"
                >
                  <Button
                    shape="circle"
                    color="indigo-500"
                    className="w-[24px] !rounded-[12px]"
                  >
                    <Img src="/dhimages/img_checkmark.svg" />
                  </Button>

                  {isEditMode ? (
                    <DragDropEditor
                      defauldClassname="w-[93%] leading-[26px] whitespace-nowrap text-base font-normal text-blue_gray-700 font-inter"
                      initialComponents={d.text}
                      onChange={(e) => {
                        onChange(e, `benefits.${index}.text`);
                      }}
                    />
                  ) : typeof d.text === "string" ? (
                    <span className="w-[93%] leading-[26px] whitespace-nowrap text-base font-normal text-blue_gray-700 font-inter">
                      {d.text}
                    </span>
                  ) : (
                    <DragDropEditor initialComponents={d.text} readOnly />
                  )}

                  <Popconfirm
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    title="Are you sure?"
                    onConfirm={(e) => {
                      onChange(
                        benefits.filter((_, x) => x !== index),
                        "benefits"
                      );
                    }}
                    className="text-xs"
                  >
                    <MdDeleteForever className="cursor-pointer" size={15} />
                  </Popconfirm>
                </div>
              ))}
              <div className="w-full flex justify-center">
                <IoMdAddCircleOutline
                  className="cursor-pointer"
                  onClick={() => {
                    onChange([...benefits, { text: "" }], "benefits");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomerPainPoints = ({
  featuredShowcase1Text,
  featuredShowcase2Text,
  featuredShowcase3Text,
  featuredShowcase1Img,
  featuredShowcase4Text,
  featuredShowcase5Text,
  featuredShowcase2Img,
  featuredShowcase6Text,
  featuredShowcase7Text,
  featuredShowcase3Img,
  featuredShowcase8Text,
  featuredShowcase9Text,
  featuredShowcase4Img,
  onChange,
  isEditMode,
  content_id,
  setModal,
  setPictureChangeModal,
}) => {
  return (
    <>
      <section
        id="features-showcase"
        className="relative overflow-hidden  my-12"
      >
        <div className="container mx-auto px-4">
          <header className="py-5">
            {isEditMode ? (
              <DragDropEditor
                defauldClassname="text-3xl font-bold"
                initialComponents={featuredShowcase1Text}
                onChange={(e) => {
                  onChange(e, "featuredShowcase1Text");
                }}
              />
            ) : typeof featuredShowcase1Text === "string" ? (
              <span className="text-3xl font-bold">
                {featuredShowcase1Text}
              </span>
            ) : (
              <DragDropEditor
                initialComponents={featuredShowcase1Text}
                readOnly
              />
            )}
            {isEditMode ? (
              <DragDropEditor
                defauldClassname=""
                initialComponents={featuredShowcase2Text}
                onChange={(e) => {
                  onChange(e, "featuredShowcase2Text");
                }}
              />
            ) : typeof featuredShowcase2Text === "string" ? (
              <span className="">{featuredShowcase2Text}</span>
            ) : (
              <DragDropEditor
                initialComponents={featuredShowcase2Text}
                readOnly
              />
            )}
          </header>
          {/* Main Section */}
          <div className="flex flex-wrap lg:flex-nowrap">
            {/* Left Panel */}
            <div className="w-full lg:w-1/2 bg-white p-4 shadow-md">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-xl font-semibold mb-4"
                  initialComponents={featuredShowcase3Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase3Text");
                  }}
                />
              ) : typeof featuredShowcase3Text === "string" ? (
                <span className="text-xl font-semibold mb-4">
                  {featuredShowcase3Text}
                </span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase3Text}
                  readOnly
                />
              )}
              <img className="mt-1" src={featuredShowcase1Img} />
              <div className="w-full flex justify-center mt-5">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setPictureChangeModal({
                        key: "featuredShowcase1Img",
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                )}
              </div>
            </div>
            {/* Right Panel */}
            <div className="w-full lg:w-1/2 bg-white p-4 shadow-md mt-4 lg:mt-0 lg:ml-4">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-xl font-semibold mb-4"
                  initialComponents={featuredShowcase4Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase4Text");
                  }}
                />
              ) : typeof featuredShowcase4Text === "string" ? (
                <span className="text-xl font-semibold mb-4">
                  {featuredShowcase4Text}
                </span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase4Text}
                  readOnly
                />
              )}

              {isEditMode ? (
                <DragDropEditor
                  defauldClassname=""
                  initialComponents={featuredShowcase5Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase5Text");
                  }}
                />
              ) : typeof featuredShowcase5Text === "string" ? (
                <span className="">{featuredShowcase5Text}</span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase5Text}
                  readOnly
                />
              )}
              <img className="mt-1" src={featuredShowcase2Img} />
              <div className="w-full flex justify-center mt-5">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setPictureChangeModal({
                        key: "featuredShowcase2Img",
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap mt-10">
            {/* Left Panel */}
            <div className="w-full lg:w-1/2 bg-white p-4 shadow-md">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-xl font-semibold mb-4"
                  initialComponents={featuredShowcase6Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase6Text");
                  }}
                />
              ) : typeof featuredShowcase6Text === "string" ? (
                <span className="text-xl font-semibold mb-4">
                  {featuredShowcase6Text}
                </span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase6Text}
                  readOnly
                />
              )}

              {isEditMode ? (
                <DragDropEditor
                  defauldClassname=""
                  initialComponents={featuredShowcase7Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase7Text");
                  }}
                />
              ) : typeof featuredShowcase7Text === "string" ? (
                <span className="">{featuredShowcase7Text}</span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase7Text}
                  readOnly
                />
              )}
              <img className="mt-1" src={featuredShowcase3Img} />
              <div className="w-full flex justify-center mt-5">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setPictureChangeModal({
                        key: "featuredShowcase3Img",
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                )}
              </div>
            </div>
            {/* Right Panel */}
            <div className="w-full lg:w-1/2 bg-white p-4 shadow-md mt-4 lg:mt-0 lg:ml-4">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-xl font-semibold mb-4"
                  initialComponents={featuredShowcase8Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase8Text");
                  }}
                />
              ) : typeof featuredShowcase8Text === "string" ? (
                <span className="text-xl font-semibold mb-4">
                  {featuredShowcase8Text}
                </span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase8Text}
                  readOnly
                />
              )}

              {isEditMode ? (
                <DragDropEditor
                  defauldClassname=""
                  initialComponents={featuredShowcase9Text}
                  onChange={(e) => {
                    onChange(e, "featuredShowcase9Text");
                  }}
                />
              ) : typeof featuredShowcase9Text === "string" ? (
                <span className="">{featuredShowcase9Text}</span>
              ) : (
                <DragDropEditor
                  initialComponents={featuredShowcase9Text}
                  readOnly
                />
              )}
              <img className="mt-1" src={featuredShowcase4Img} />
              <div className="w-full flex justify-center mt-5">
                {isEditMode && (
                  <Button
                    className="mt-[-15px]"
                    onClick={() =>
                      setPictureChangeModal({
                        key: "featuredShowcase4Img",
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const CallToAction = ({
  headline,
  description,
  secondaryButtonText,
  secondaryButtonLink,
  primaryButtonLink,
  primaryButtonText,
  onChange,
  isEditMode,
  setModal,
  content_id,
}) => {
  return (
    <>
      <div className="flex justify-center w-full py-20 bg-gray-50 mdx:py-5 mt-10">
        <div className="flex justify-center px-8 my-4 container-sm mdx:p-5 smx:px-5">
          <div className="flex flex-col items-center w-full gap-10">
            <div className="flex flex-col items-center gap-[22px] self-stretch">
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-center leading-[44px] tracking-[-0.72px] smx:text-2xl text-gray-900_02 font-inter text-4xl font-semibold md:text-[34px] sm:text-[32px]"
                  initialComponents={headline}
                  onChange={(e) => {
                    onChange(e, `headline`);
                  }}
                />
              ) : typeof headline === "string" ? (
                <span className="text-center leading-[44px] tracking-[-0.72px] smx:text-2xl text-gray-900_02 font-inter text-4xl font-semibold md:text-[34px] sm:text-[32px]">
                  {headline}
                </span>
              ) : (
                <DragDropEditor initialComponents={headline} readOnly />
              )}
              {isEditMode ? (
                <DragDropEditor
                  defauldClassname="text-center text-blue_gray-700 font-inter"
                  initialComponents={description}
                  onChange={(e) => {
                    onChange(e, `description`);
                  }}
                />
              ) : typeof description === "string" ? (
                <span className="text-center text-blue_gray-700 font-inter">
                  {description}
                </span>
              ) : (
                <DragDropEditor initialComponents={description} readOnly />
              )}
            </div>
            <div className="flex gap-3">
              {secondaryButtonText && secondaryButtonLink && (
                <a
                  href={secondaryButtonLink}
                  target={
                    secondaryButtonLink?.includes?.("http") ? "_blank" : "_self"
                  }
                >
                  <Button
                    size="xl"
                    variant="fill"
                    shape="round"
                    className="min-w-[128px] border border-solid border-blue_gray-100 font-semibold"
                  >
                    {secondaryButtonText}
                  </Button>
                </a>
              )}
              {primaryButtonText && primaryButtonLink && (
                <a
                  href={primaryButtonLink}
                  target={
                    primaryButtonLink?.includes?.("http") ? "_blank" : "_self"
                  }
                >
                  <Button
                    size="xl"
                    variant="outline"
                    shape="round"
                    color="indigo-500"
                    className="min-w-[125px] font-semibold !text-white whitespace-nowrap"
                  >
                    {primaryButtonText}
                  </Button>
                </a>
              )}
            </div>
            {isEditMode && (
              <Button
                className="mt-[-15px]"
                onClick={() =>
                  setModal([
                    {
                      label: "Secondary Button Text",
                      value: secondaryButtonText,
                      key: "secondaryButtonText",
                      content_id,
                    },
                    {
                      label: "Secondary Button Link",
                      value: secondaryButtonLink,
                      key: "secondaryButtonLink",
                      content_id,
                    },
                    {
                      label: "Primary Button Text",
                      value: primaryButtonText,
                      key: "primaryButtonText",
                      content_id,
                    },
                    {
                      label: "Primary Button Link",
                      value: primaryButtonLink,
                      key: "primaryButtonLink",
                      content_id,
                    },
                  ])
                }
              >
                Edit Buttons
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const FeatureSlider = ({
  slides,
  onChange,
  isEditMode,
  setPictureChangeModal,
  content_id,
}) => {
  const swiperRef = useRef();

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col w-full overflow-hidden">
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="flex w-full overflow-hidden"
          >
            {slides?.map?.((slide, i) => (
              <SwiperSlide key={i} className="flex justify-center w-full ">
                <OneSlide
                  slide={slide}
                  index={i}
                  onChange={onChange}
                  isEditMode={isEditMode}
                  setPictureChangeModal={setPictureChangeModal}
                  content_id={content_id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="w-[90%] mx-auto px-8">
            <div className="relative z-10 flex items-center gap-3 -mt-10 mdx:-mt-0">
              <button
                onClick={() => {
                  swiperRef.current?.slidePrev();
                }}
              >
                <LeftArrow />
              </button>
              <button
                onClick={() => {
                  swiperRef.current?.slideNext();
                }}
              >
                <RightArrow />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TestemonialSectionSlider = ({ testimonials, onChange, isEditMode }) => {
  return (
    <>
      <div className="relative flex flex-col w-full pr-0 mt-10 pl-28 mdx:pl-5 mdx:pr-5 mdx:pt-10">
        <Swiper
          modules={[Autoplay]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="grid w-full "
        >
          {testimonials.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="flex justify-center flex-grow b"
              >
                <div className=" flex w-full items-center justify-between gap-5 rounded-[20px]  bg-gray-50 py-12 px-12 mdx:flex-col mdx:py-5 mdx:px-5 relative">
                  <div className="mb-1.5 flex w-[95%] items-start justify-between gap-5 self-end mdx:w-full mdx:flex-col">
                    <div className="flex w-[71%] flex-col gap-[13px] mdx:w-full">
                      {isEditMode ? (
                        <DragDropEditor
                          defauldClassname="text-3xl font-semibold md:text-[28px] sm:text-[26px] text-gray-900_02 font-inter"
                          initialComponents={item.title}
                          onChange={(e) => {
                            onChange(e, `testimonials.${index}.title`);
                          }}
                        />
                      ) : typeof item.title === "string" ? (
                        <span className="text-3xl font-semibold md:text-[28px] sm:text-[26px] text-gray-900_02 font-inter">
                          {item.title}
                        </span>
                      ) : (
                        <DragDropEditor
                          initialComponents={item.title}
                          readOnly
                        />
                      )}

                      {isEditMode ? (
                        <DragDropEditor
                          defauldClassname="leading-7 text-lg font-normal text-blue_gray-700 font-inter"
                          initialComponents={item.description}
                          onChange={(e) => {
                            onChange(e, `testimonials.${index}.description`);
                          }}
                        />
                      ) : typeof item.description === "string" ? (
                        <span className="leading-7 text-lg font-normal text-blue_gray-700 font-inter">
                          {item.description}
                        </span>
                      ) : (
                        <DragDropEditor
                          initialComponents={item.description}
                          readOnly
                        />
                      )}
                    </div>
                    <div className="flex w-[19%] flex-col mdx:w-full ml-auto justify-end  items-end ">
                      <div className="mt-[29px] flex gap-2 items-center ">
                        <RatingBar
                          value={parseInt(item.rate) || 0}
                          isEditable={false}
                          size={20}
                          className="flex justify-between flex-1"
                        />
                        {isEditMode ? (
                          <Typography.Paragraph
                            editable={{
                              onChange: (e) => {
                                onChange(
                                  safeConvertToNumber(e),
                                  `testimonials.${index}.rate`
                                );
                              },
                            }}
                          >
                            <Heading
                              size="xs"
                              as="h5"
                              className="!text-blue_gray-800 whitespace-nowrap"
                            >
                              {parseFloat(item.rate)?.toFixed?.(1)}
                            </Heading>
                          </Typography.Paragraph>
                        ) : (
                          <Heading
                            size="xs"
                            as="h5"
                            className="!text-blue_gray-800 whitespace-nowrap"
                          >
                            {parseFloat(item.rate)?.toFixed?.(1)}
                          </Heading>
                        )}
                      </div>
                      <Text
                        size="md"
                        as="p"
                        className="mt-1.5 !font-medium whitespace-nowrap"
                      >
                        {isEditMode ? (
                          <Typography.Paragraph
                            editable={{
                              onChange: (e) => {
                                onChange(
                                  safeConvertToNumber(e),
                                  `testimonials.${index}.totalReviewers`
                                );
                              },
                            }}
                          >
                            from {item.totalReviewers}+ reviews
                          </Typography.Paragraph>
                        ) : (
                          <>from {item.totalReviewers}+ reviews</>
                        )}
                      </Text>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

const AdminFeatures = ({
  title,
  features,
  image,
  imageLeft,
  isEditMode,
  onChange,
  content_id,
  setPictureChangeModal,
  pageData,
  setPageData,
  id,
}) => {
  // State to manage drawer visibility and image position
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Function to toggle image position
  const toggleImagePosition = useCallback(async () => {
    if (!pageData) return;

    const idx = content_id;
    if (!idx === -1) return console.error("Index out of bound");

    setPageData((data) => {
      let query = {};
      if (imageLeft) {
        query = {
          imageLeft: "",
          image: imageLeft,
        };
      } else if (image) {
        query = {
          imageLeft: image,
          image: "",
        };
      }

      const pageContent = [...data.pageContent].map((item, i) =>
        i === idx
          ? {
              ...item,
              data: {
                ...item.data,
                ...query,
              },
            }
          : item
      );

      if (id === "demo") {
        try {
          localStorage.demoVacancy = JSON.stringify({
            ...JSON.parse(localStorage.demoVacancy),
            pageContent,
          });
        } catch (e) {}
      }

      return {
        ...data,
        pageContent,
      };
    });

    if (imageLeft) {
      await CrudService.update("Vacancy", pageData._id, {
        [`pageContent.${idx}.data.imageLeft`]: "",
        [`pageContent.${idx}.data.image`]: imageLeft,
      });
    } else if (image) {
      await CrudService.update("Vacancy", pageData._id, {
        [`pageContent.${idx}.data.imageLeft`]: image,
        [`pageContent.${idx}.data.image`]: "",
      });
    }
  }, [imageLeft, image, onChange, content_id]);

  // Toggle drawer visibility
  const handleMouseEnter = useCallback(() => {
    if (isEditMode) {
      setDrawerOpen(true);
    }
  }, [isEditMode]);

  const handleMouseLeave = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <div
        className="flex w-full  flex-col gap-24 bg-white py-24 mdx:gap-[72px] mdx:py-5 smx:gap-12 relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex mdx:flex-col">
          {imageLeft && (
            <div className="h-[500px] w-[41%] object-cover mdx:w-full flex flex-col items-center">
              <Img
                src={imageLeft}
                alt="image_seven"
                className="object-cover max-h-full"
              />
              {isEditMode && (
                <div className="w-full flex justify-center">
                  <Button
                    onClick={() =>
                      setPictureChangeModal({
                        key: `imageLeft`,
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className="flex items-start justify-center flex-1 p-6 mdx:flex-col mdx:self-stretch mdx:p-5">
            <div className="my-[61px] ml-[23px] flex w-[74%] flex-col gap-8 mdx:ml-0 mdx:w-full">
              <div className="flex self-start mdx:self-center ">
                {isEditMode ? (
                  <DragDropEditor
                    defauldClassname="tracking-[-0.72px] mdx:text-center text-4xl font-semibold md:text-[34px] sm:text-[32px]"
                    initialComponents={title}
                    onChange={(e) => {
                      onChange(e, `title`);
                    }}
                  />
                ) : typeof title === "string" ? (
                  <span className="tracking-[-0.72px] mdx:text-center text-4xl font-semibold md:text-[34px] sm:text-[32px]">
                    {title}
                  </span>
                ) : (
                  <DragDropEditor initialComponents={title} readOnly />
                )}
              </div>
              <div className="flex flex-col gap-5">
                {features?.map?.((feature, i) => (
                  <div className="flex items-center justify-center gap-3 rounded-[12px] border-[1.5px] border-solid border-gray-200 bg-gray-200 p-3 smx:flex-col whitespace-nowrap">
                    <Button
                      shape="circle"
                      color="indigo-500"
                      className="w-[24px] !rounded-[12px] "
                    >
                      <Img
                        src="/dhimages/img_checkmark.svg"
                        width="24"
                        height="24"
                      />
                    </Button>
                    <span className="truncate smx:w-full">
                      {isEditMode ? (
                        <DragDropEditor
                          defauldClassname="w-[95%] leading-7 text-lg font-normal text-blue_gray-700 font-inter"
                          initialComponents={feature.description}
                          onChange={(e) => {
                            onChange(e, `features.${i}.description`);
                          }}
                        />
                      ) : typeof feature.description === "string" ? (
                        <span className="w-[95%] leading-7 text-lg font-normal text-blue_gray-700 font-inter">
                          {feature.description}
                        </span>
                      ) : (
                        <DragDropEditor
                          initialComponents={feature.description}
                          readOnly
                        />
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {image && (
            <div className="h-[500px] w-[41%] object-cover mdx:w-full flex flex-col items-center">
              <Img
                src={image}
                alt="image_seven"
                className="object-cover max-h-full"
              />

              {isEditMode && (
                <div className="w-full flex justify-center">
                  <Button
                    onClick={() =>
                      setPictureChangeModal({
                        key: `image`,
                        content_id,
                      })
                    }
                  >
                    Change Image
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>

        {isDrawerOpen && isEditMode && (
          <div className="absolute start-4 bottom-0 p-1 bg-white border">
            <Button onClick={toggleImagePosition}>Toggle Image Position</Button>
          </div>
        )}
      </div>
    </>
  );
};

const SectionMapping = {
  Hero,
  CustomerPainPoints,
  CallToAction,
  FeatureSlider,
  TestemonialSectionSlider,
  AdminFeatures,
  DivideLine,
  LeadForm,
  Benefits,
  ValueProp,
  CustomComponent,
  HeadlineComponent,
};
const DefaultComponent = () => <div />;

const Reorder = ({ pageData, setPageData, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(selectUser);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleComponentSelect = (componentType) => {
    const newContent = {
      id: componentType,
      data:
        exampleData({ user }).find((a) => a.id === componentType)?.data ?? {}, // Initial data structure for the component
    };
    const newList = [...pageData.pageContent, newContent];
    setPageData((data) => ({
      ...data,
      pageContent: newList,
    }));
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight * 2,
          behavior: "smooth",
        }),
      500
    );
    if (id === "demo") {
      try {
        localStorage.demoVacancy = JSON.stringify({
          ...JSON.parse(localStorage.demoVacancy),
          pageContent: newList,
        });
      } catch (e) {}
    } else {
      CrudService.update("Vacancy", pageData._id, { pageContent: newList });
    }

    closeModal();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newOrder = reorder(
      pageData.pageContent,
      result.source.index,
      result.destination.index
    );

    setPageData((data) => {
      return {
        ...data,
        pageContent: newOrder,
      };
    });

    if (id === "demo") {
      try {
        localStorage.demoVacancy = JSON.stringify({
          ...JSON.parse(localStorage.demoVacancy),
          pageContent: newOrder,
        });
      } catch (e) {}
    } else {
      await CrudService.update("Vacancy", pageData._id, {
        [`pageContent`]: newOrder,
      });
    }
  };

  const removeItem = (index) => {
    const newList = [...pageData.pageContent];
    newList.splice(index, 1);
    setPageData((data) => ({
      ...data,
      pageContent: newList,
    }));

    if (id === "demo") {
      try {
        localStorage.demoVacancy = JSON.stringify({
          ...JSON.parse(localStorage.demoVacancy),
          pageContent: newList,
        });
      } catch (e) {}
    } else {
      CrudService.update("Vacancy", pageData._id, {
        pageContent: newList,
      });
    }
  };

  // In your JSX
  return (
    <div className="w-full p-5">
      <div className="font-bold text-lg mb-1">Reorder Items</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {pageData.pageContent.map((item, index) => {
                return (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex items-center justify-between p-2 container"
                      >
                        <span className="flex-grow flex items-center space-x-3">
                          <div className="mb-2">
                            <FaAngleUp
                              className="cursor-pointer"
                              onClick={async () => {
                                if (index === 0) return;
                                const newOrder = reorder(
                                  pageData.pageContent,
                                  index,
                                  index - 1
                                );

                                setPageData((data) => {
                                  return {
                                    ...data,
                                    pageContent: newOrder,
                                  };
                                });

                                if (id === "demo") {
                                  try {
                                    localStorage.demoVacancy = JSON.stringify({
                                      ...JSON.parse(localStorage.demoVacancy),
                                      pageContent: newOrder,
                                    });
                                  } catch (e) {}
                                } else {
                                  await CrudService.update(
                                    "Vacancy",
                                    pageData._id,
                                    {
                                      [`pageContent`]: newOrder,
                                    }
                                  );
                                }
                              }}
                            />
                            <FaAngleDown
                              className="cursor-pointer"
                              onClick={async () => {
                                if (index === pageData.pageContent.length - 1)
                                  return;
                                const newOrder = reorder(
                                  pageData.pageContent,
                                  index,
                                  index + 1
                                );

                                setPageData((data) => {
                                  return {
                                    ...data,
                                    pageContent: newOrder,
                                  };
                                });

                                if (id === "demo") {
                                  try {
                                    localStorage.demoVacancy = JSON.stringify({
                                      ...JSON.parse(localStorage.demoVacancy),
                                      pageContent: newOrder,
                                    });
                                  } catch (e) {}
                                } else {
                                  await CrudService.update(
                                    "Vacancy",
                                    pageData._id,
                                    {
                                      [`pageContent`]: newOrder,
                                    }
                                  );
                                }
                              }}
                            />
                          </div>

                          <span>
                            <Tooltip
                              overlayStyle={{ minWidth: "50vw" }}
                              placement="left"
                              title={
                                <img
                                  src={`/components/${item?.id}.png`}
                                  className="rounded-md "
                                />
                              }
                            >
                              <div>{nameRender?.[item?.id] ?? item?.id}</div>
                            </Tooltip>
                          </span>
                        </span>
                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={() => removeItem(index)}
                        >
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                            Remove
                          </button>
                        </Popconfirm>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex justify-end w-full">
        <button
          onClick={openModal}
          className="mt-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Component
        </button>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        footer={[]}
        title="Select a Component to Add"
      >
        <div className="flex flex-col">
          {Object.keys(SectionMapping).map((key) => (
            <button
              key={key}
              onClick={() => handleComponentSelect(key)}
              className="my-2 p-2 bg-gray-200 hover:bg-gray-300 rounded"
            >
              <div className="w-full flex justify-center">
                <img src={`/components/${key}.png`} />
              </div>
              <div>{key}</div>
            </button>
          ))}
        </div>
      </Modal>
    </div>
  );
};

const Page = () => {
  let { id } = useParams();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [pageData, setPageData] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reorder, setReorder] = useState(false);
  const [funnelSteps, setFunnelSteps] = useState(false);
  const [availableFunnelSteps, setAvailableFunnelSteps] = useState([]);
  const [nextStepDrawer, setNextStepDrawer] = useState(false);
  const [nextStepDrawerOpen, setNextStepDrawerOpen] = useState(false);
  const [pictureChangeModal, setPictureChangeModal] = useState(false);
  const [history, setHistory] = useState([]); // History stack for undo functionality
  const undoing = useRef(false);

  const [steps, setSteps] = useState([]);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    if (!pageData?.pixelId) {
      console.log("Pixel ID not found");
      return;
    }

    // Create the script element
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pageData?.pixelId}');
      fbq('track', 'PageView');
    `;

    // Append the script to the head
    document.head.appendChild(script);

    // Create the noscript element
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=${pageData?.pixelId}&ev=PageView&noscript=1" />`;

    // Append the noscript to the body
    document.body.appendChild(noscript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [pageData]);

  useEffect(() => {
    if (!id) return;

    const uuid = localStorage?.[`funnelUUID_${id}`] ?? uuidv4();
    localStorage[`funnelUUID_${id}`] = uuid;

    PublicService.clickFunnel({
      id,
      uuid,
      token: Cookies.get("accessToken"),
    });
  }, [id]);

  useEffect(() => {
    if (!id || id === "undefined" || id === "demo") return;

    PublicService.getFunnelSteps(id).then((res) => {
      setSteps(res.data?.vacancies);
    });
  }, [id]);

  useEffect(() => {
    if (modal && !modalOpen) setModalOpen(true);
    if (!modal && modalOpen) setModalOpen(false);
  }, [modal, modalOpen]);

  useEffect(() => {
    if (!id || id === "undefined" || id === "demo") return;
    if (nextStepDrawerOpen) return;

    PublicService.getFunnelSteps(id).then((res) => {
      setAvailableFunnelSteps(res.data?.vacancies);
    });
  }, [id, nextStepDrawerOpen]);

  useEffect(() => {
    if (!id) return;

    if (id === "demo") {
      if (
        localStorage.demoVacancy &&
        localStorage.demoVacancy !== "undefined"
      ) {
        try {
          setPageData(JSON.parse(localStorage.demoVacancy));
        } catch (e) {}
      } else {
        setInterval(() => {
          try {
            setPageData(JSON.parse(localStorage.demoVacancy));
          } catch (e) {}
        }, 5000);
      }
    } else
      PublicService.getVacancyData(id).then((res) => {
        setPageData(res.data);
      });
  }, [id]);

  useEffect(() => {
    try {
      const themeColor =
        id === "demo"
          ? JSON.parse(localStorage.demoVacancy)?.values?.themeColor
          : pageData?.themeColor;
      if (themeColor) {
        if (id === "demo")
          setTimeout(() => {
            changeIndigoShades(generateTailwindPalette(themeColor));
          }, 500);
        else {
          changeIndigoShades(generateTailwindPalette(themeColor));
        }
      }
    } catch (e) {}
  }, [pageData, id]);

  useEffect(() => {
    // Get all elements with the ID 'contact'
    const elements = document.querySelectorAll("#contact");

    // Remove the ID 'contact' from all elements
    elements.forEach((el) => {
      el.removeAttribute("id");
    });

    // Assign the ID 'contact' to the first element, if it exists
    if (elements.length > 0) {
      elements[0].setAttribute("id", "contact");
    }
  }, [pageData]); // Empty dependency array to run only once after the initial render

  const onChange = useCallback(
    async (e, key, content_id) => {
      if (!pageData) return;

      const idx = content_id;
      if (idx === -1) return console.error("Index out of bound");

      setPageData((data) => {
        const pageContent = [...data.pageContent].map((item, i) =>
          i === idx
            ? {
                ...item,
                data: {
                  ...item.data,
                  [key]: e,
                },
              }
            : item
        );

        if (id === "demo") {
          try {
            localStorage.demoVacancy = JSON.stringify({
              ...JSON.parse(localStorage.demoVacancy),
              pageContent,
            });
          } catch (e) {}
        }

        return {
          ...data,
          pageContent,
        };
      });

      if (id !== "demo") {
        await CrudService.update("Vacancy", pageData._id, {
          [`pageContent.${idx}.data.${key}`]: e,
        });

        // if (key.includes("."))
        //   PublicService.getVacancyData(id).then((res) => {
        //     setPageData(res.data);
        //   });
      }
    },
    [pageData, id]
  );

  const handleUndo = useCallback(() => {
    setHistory((prevHistory) => {
      if (prevHistory.length === 0) return prevHistory;

      const previousState = prevHistory[prevHistory.length - 1];
      undoing.current = true;
      setPageData({ ...previousState }); // Ensure to create a new object to trigger state change
      setTimeout(() => {
        undoing.current = false;
      }, 200);
      console.log(previousState);
      return prevHistory.slice(0, prevHistory.length - 1);
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        e.preventDefault();
        handleUndo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo]);

  // Only update history if pageData has changed and not because of undo
  useEffect(() => {
    if (pageData && !undoing.current) {
      setHistory((prevHistory) => {
        if (
          prevHistory.length === 0 ||
          !isEqual(prevHistory[prevHistory.length - 1], pageData)
        ) {
          return [...prevHistory, { ...pageData }];
        }
        return prevHistory;
      });
    }
  }, [pageData]);

  const isEditMode =
    (id === "demo" || user?._id === pageData?.user_id) && editMode;

  if (!pageData) return <Skeleton active />;

  return (
    <div className="">
      <div className="content">
        {(id === "demo" || user?._id === pageData?.user_id) && (
          <div className="h-[100px] w-full" />
        )}
        {(id === "demo" || user?._id === pageData?.user_id) && (
          <div className="p-[12px] smx:p-5 flex smx:flex-col smx:items-end w-full justify-between items-center fixed top-0 start-0 end-0 bg-white z-40">
            {id === "demo" ? (
              <div />
            ) : (
              <Breadcrumb
                items={[
                  {
                    title: <Link to={`/dashboard/myvacancies`}>Funnels</Link>,
                  },
                  {
                    title: (
                      <Link
                        to={`/dashboard/vacancydetails?id=${pageData?._id}`}
                      >
                        Leads
                      </Link>
                    ),
                  },
                  {
                    title:
                      (steps?.[0] ?? pageData)?.alternativeName ||
                      (steps?.[0] ?? pageData)?.name,
                  },
                  {
                    title: pageData?.funnelStepName,
                  },
                ]}
              />
            )}
            <div className="flex gap-2 items-center smx:flex-col smx:items-end">
              {id !== "demo" && (
                <Button
                  color="indigo-500"
                  size="md"
                  className={`rounded-[29px] font-bold tracking-[-0.20px] px-5 py-2 text-white `}
                  onClick={(e) => setFunnelSteps(true)}
                >
                  <div className="flex items-center gap-2">
                    <span>Funnel Steps</span>
                    <BsBarChartSteps size={22} className="text-white" />
                  </div>
                </Button>
              )}
              <Button
                color="indigo-500"
                size="md"
                className={`rounded-[29px] font-bold tracking-[-0.20px] px-5 py-2 text-white `}
                onClick={(e) => setReorder(true)}
              >
                <div className="flex items-center gap-2">
                  <span>Reorder</span>
                  <IoReorderFour size={22} className="text-white" />
                </div>
              </Button>

              {id === "demo" ? (
                <Button
                  color="indigo-500"
                  size="lg"
                  className={`rounded-[29px] font-bold tracking-[-0.20px] px-5 py-2 text-white text-3xl`}
                  onClick={() => {
                    navigate("/auth/register");
                  }}
                >
                  <div className="flex gap-1 items-center">
                    <span>Publish / Edit</span>
                    <MdOutlineKeyboardDoubleArrowRight size={36} />
                  </div>
                </Button>
              ) : (
                <Button
                  color="indigo-500"
                  size="md"
                  className={`rounded-[29px] font-bold tracking-[-0.20px] px-5 py-2 text-white `}
                  onClick={() => setEditMode((e) => !e)}
                >
                  <div className="flex items-center gap-2">
                    <span>{editMode ? "Preview" : "Edit"}</span>
                    {editMode ? (
                      <MdPreview size={22} className="text-white" />
                    ) : (
                      <MdEdit size={22} className="text-white" />
                    )}
                  </div>
                </Button>
              )}
            </div>
          </div>
        )}

        <Drawer open={reorder} onClose={() => setReorder(false)}>
          <Reorder pageData={pageData} setPageData={setPageData} id={id} />
        </Drawer>

        <Drawer open={funnelSteps} onClose={() => setFunnelSteps(false)}>
          <FunnelSteps />
        </Drawer>

        <Drawer
          open={nextStepDrawerOpen}
          onClose={() => {
            setNextStepDrawer(false);
            setNextStepDrawerOpen(false);
          }}
        >
          <FunnelSteps
            nextStepDrawer={nextStepDrawer}
            handleNextStepSelect={(item_id) => {
              onChange(item_id, `onSubmitStepId`, nextStepDrawer.id);
              setNextStepDrawer((e) => ({ ...e, currentNextStep: item_id }));
            }}
          />
        </Drawer>

        {pageData?.pageContent?.map?.((content, i) => {
          const Component = SectionMapping[content?.id] ?? DefaultComponent;
          return (
            <Component
              key={i}
              {...(content?.data ?? {})}
              onChange={(e, key) => onChange(e, key, i)}
              isEditMode={isEditMode}
              setModal={setModal}
              content_id={i}
              setPictureChangeModal={setPictureChangeModal}
              pageData={pageData}
              setPageData={setPageData}
              id={id}
              availableFunnelSteps={availableFunnelSteps}
              addNextStep={(id, currentNextStep) => {
                setNextStepDrawer({ id, currentNextStep });
                setNextStepDrawerOpen(true);
              }}
            />
          );
        })}
      </div>

      <Modal
        open={modalOpen}
        onCancel={() => setModal(false)}
        onOk={() => {
          modal.map((row) => {
            onChange(row.value, row.key, row.content_id);
          });
          setModal(false);
        }}
      >
        {modal?.map?.((row, i) => (
          <TextField
            key={i}
            label={row.label}
            type="text"
            value={row?.value ?? ""}
            onChange={(e) =>
              setModal((m) => {
                const newItems = [...m].map((item, idx) =>
                  idx === i ? { ...item, value: e.target.value } : item
                );
                return newItems;
              })
            }
          />
        ))}
      </Modal>

      <Modal
        open={!!pictureChangeModal}
        onCancel={() => setPictureChangeModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <MediaLibrary
            onSelect={async (secure_url) => {
              onChange(
                secure_url,
                pictureChangeModal.key,
                pictureChangeModal.content_id
              );
              setPictureChangeModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Page;
