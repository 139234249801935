import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class GoogleService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getAuthURI() {
    return this.api.post(`/getAuthURI`, { origin: window.location.origin });
  }
  requestToken({ code }) {
    return this.api.post(`/requestToken`, {
      code,
      origin: window.location.origin,
    });
  }
  createAd({
    days,
    vacancyLocation,
    locationIds,
    path1,
    path2,
    final_urls,
    adDescription,
    adHeadline,
    budget,
  }) {
    return this.api.post(`/createAd`, {
      days,
      vacancyLocation,
      locationIds,
      path1,
      path2,
      final_urls,
      adDescription,
      adHeadline,
      budget,
    });
  }
  getCurrentToken() {
    return this.api.get(`/getCurrentToken`);
  }
  listCampaigns() {
    return this.api.get(`/listCampaigns`);
  }
  listAssetGroups({ campaign_id }) {
    return this.api.get(`/listAssetGroups?campaign_id=${campaign_id}`);
  }
  listAssets({ asset_group_id }) {
    return this.api.get(`/listAssets?asset_group_id=${asset_group_id}`);
  }
}

export default new GoogleService(`${getBackendUrl()}/google`);
