import { useSelector } from "react-redux";
import { getPartner } from "../../redux/auth/selectors";
import { Button } from "./Button";
import { Container } from "./Container";
import backgroundImage from "./images/background-call-to-action.jpg";

export function FeatureShowcase({ data }) {
  const partner = data || useSelector(getPartner);
  return (
    <section id="features-showcase" className="relative overflow-hidden  my-32">
      <div className="container mx-auto px-4">
        {/* Header */}
        <header className="py-5">
          <h1 className="text-3xl font-bold">
            {partner.featuredShowcase1Text}
          </h1>
          <p>{partner.featuredShowcase2Text}</p>
        </header>
        {/* Main Section */}
        <div className="flex flex-wrap lg:flex-nowrap">
          {/* Left Panel */}
          <div className="w-full lg:w-1/2 bg-white p-4 shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              {partner.featuredShowcase3Text}
            </h2>
            <img className="mt-1" src={partner.featuredShowcase1Img} />
          </div>
          {/* Right Panel */}
          <div className="w-full lg:w-1/2 bg-white p-4 shadow-md mt-4 lg:mt-0 lg:ml-4">
            <h2 className="text-xl font-semibold mb-4">
              {partner.featuredShowcase4Text}
            </h2>
            <p>{partner.featuredShowcase5Text}</p>
            <img className="mt-1" src={partner.featuredShowcase2Img} />
          </div>
        </div>

        <div className="flex flex-wrap lg:flex-nowrap mt-10">
          {/* Left Panel */}
          <div className="w-full lg:w-1/2 bg-white p-4 shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              {partner.featuredShowcase6Text}
            </h2>
            <p>{partner.featuredShowcase7Text}</p>
            <img className="mt-1" src={partner.featuredShowcase3Img} />
          </div>
          {/* Right Panel */}
          <div className="w-full lg:w-1/2 bg-white p-4 shadow-md mt-4 lg:mt-0 lg:ml-4">
            <h2 className="text-xl font-semibold mb-4">
              {partner.featuredShowcase8Text}
            </h2>
            <p>{partner.featuredShowcase9Text}</p>
            <img className="mt-1" src={partner.featuredShowcase4Img} />
          </div>
        </div>
      </div>
    </section>
  );
}
