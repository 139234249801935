import React, { useEffect, useState } from "react";

const SpacingControl = ({ localContent, handleClassesChange }) => {
  const parseSpacing = (className = "", prefix) => {
    const values = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };

    const regex = new RegExp(`(-?${prefix}[trblxy]?)-(-?\\d+)`, "g");

    if (!className) return values;

    let match;
    while ((match = regex.exec(className)) !== null) {
      const parts = match[0].split("-");
      const side =
        parts[match[0].startsWith(`-${prefix}`) ? 1 : 0].slice(prefix.length) ||
        "";
      const value = match[0].startsWith(`-${prefix}`)
        ? `-${parts[2]}`
        : parts[1];

      if (side === "t") values.top = value;
      if (side === "r") values.right = value;
      if (side === "b") values.bottom = value;
      if (side === "l") values.left = value;
      if (side === "x") {
        values.right = value;
        values.left = value;
      }
      if (side === "y") {
        values.top = value;
        values.bottom = value;
      }
    }

    return values;
  };

  const [margin, setMargin] = useState(
    parseSpacing(localContent.className, "m")
  );
  const [padding, setPadding] = useState(
    parseSpacing(localContent.className, "p")
  );

  useEffect(() => {
    setMargin(parseSpacing(localContent.className, "m"));
    setPadding(parseSpacing(localContent.className, "p"));
  }, [localContent.className]);

  const handleChange = (type, side, value) => {
    const newValue = value || "0";
    if (parseInt(newValue) > 12) return;
    if (parseInt(newValue) < -2) return;
    const className =
      newValue < 0
        ? `-${type[0]}${side}-${Math.abs(newValue)}`
        : `${type[0]}${side}-${newValue}`;

    const regex = new RegExp(`-?${type[0]}${side}-\\d+`, "g");
    const updatedClassNames = localContent.className
      .split(" ")
      .filter((c) => !regex.test(c))
      .concat(className)
      .join(" ")
      .trim();

    handleClassesChange(updatedClassNames);
  };

  return (
    <div className="spacing-control-container">
      <h4 className="spacing-control-title">Spacing</h4>
      <div className="spacing-box margin-box">
        <span className="spacing-label margin-label">MARGIN</span>
        <div className="spacing-input top-input">
          <input
            type="number"
            placeholder="0"
            value={margin.top}
            onChange={(e) => handleChange("margin", "t", e.target.value)}
          />
        </div>
        <div className="spacing-input left-input">
          <input
            type="number"
            placeholder="0"
            value={margin.left}
            onChange={(e) => handleChange("margin", "l", e.target.value)}
          />
        </div>
        <div className="spacing-input bottom-input">
          <input
            type="number"
            placeholder="0"
            value={margin.bottom}
            onChange={(e) => handleChange("margin", "b", e.target.value)}
          />
        </div>
        <div className="spacing-input right-input">
          <input
            type="number"
            placeholder="0"
            value={margin.right}
            onChange={(e) => handleChange("margin", "r", e.target.value)}
          />
        </div>
        <div className="spacing-box padding-box padding-container">
          <span className="spacing-label padding-label">PADDING</span>
          <div className="spacing-input padding top-input">
            <input
              type="number"
              placeholder="0"
              value={padding.top}
              onChange={(e) => handleChange("padding", "t", e.target.value)}
            />
          </div>
          <div className="spacing-input padding left-input">
            <input
              type="number"
              placeholder="0"
              value={padding.left}
              onChange={(e) => handleChange("padding", "l", e.target.value)}
            />
          </div>
          <div className="spacing-input padding bottom-input">
            <input
              type="number"
              placeholder="0"
              value={padding.bottom}
              onChange={(e) => handleChange("padding", "b", e.target.value)}
            />
          </div>
          <div className="spacing-input padding right-input">
            <input
              type="number"
              placeholder="0"
              value={padding.right}
              onChange={(e) => handleChange("padding", "r", e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
    // <div className="spacing-control p-4 bg-gray-200 rounded-lg shadow-md">
    //   <h4 className="font-bold mb-2">Spacing</h4>
    //   <div className="flex flex-col items-center mb-4">
    //     <div className="mb-2">
    //       <label>Margin Top</label>
    //       <input
    //         type="number"
    //         value={margin.top}
    //         onChange={(e) => handleChange("margin", "t", e.target.value)}
    //         className="w-16 ml-2"
    //       />
    //     </div>
    //     <div className="flex justify-between w-full mb-2">
    //       <div className="mr-4">
    //         <label>Margin Left</label>
    //         <input
    //           type="number"
    //           value={margin.left}
    //           onChange={(e) => handleChange("margin", "l", e.target.value)}
    //           className="w-16 ml-2"
    //         />
    //       </div>
    //       <div className="mr-4">
    //         <label>Padding Top</label>
    //         <input
    //           type="number"
    //           value={padding.top}
    //           onChange={(e) => handleChange("padding", "t", e.target.value)}
    //           className="w-16 ml-2"
    //         />
    //       </div>
    //       <div>
    //         <label>Margin Right</label>
    //         <input
    //           type="number"
    //           value={margin.right}
    //           onChange={(e) => handleChange("margin", "r", e.target.value)}
    //           className="w-16 ml-2"
    //         />
    //       </div>
    //     </div>
    //     <div className="mt-2">
    //       <label>Margin Bottom</label>
    //       <input
    //         type="number"
    //         value={margin.bottom}
    //         onChange={(e) => handleChange("margin", "b", e.target.value)}
    //         className="w-16 ml-2"
    //       />
    //     </div>
    //   </div>
    //   <div className="flex justify-between w-full">
    //     <div className="mr-4">
    //       <label>Padding Left</label>
    //       <input
    //         type="number"
    //         value={padding.left}
    //         onChange={(e) => handleChange("padding", "l", e.target.value)}
    //         className="w-16 ml-2"
    //       />
    //     </div>
    //     <div className="mr-4">
    //       <label>Padding Bottom</label>
    //       <input
    //         type="number"
    //         value={padding.bottom}
    //         onChange={(e) => handleChange("padding", "b", e.target.value)}
    //         className="w-16 ml-2"
    //       />
    //     </div>
    //     <div>
    //       <label>Padding Right</label>
    //       <input
    //         type="number"
    //         value={padding.right}
    //         onChange={(e) => handleChange("padding", "r", e.target.value)}
    //         className="w-16 ml-2"
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

export default SpacingControl;
