import { Skeleton, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../../components/MultiStepComponent";
import CrudService from "../../../service/CrudService";

const VacancyPrepublish = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  const steps = [
    {
      id: "step1",
      name: "General Information",
      form: [
        {
          fieldName: "name",
          label: "Funnel Name",
          type: "input",
          placeholder: "Enter funnel name",
        },
        {
          fieldName: "description",
          label: "Funnel Description",
          type: "textarea",
          placeholder: "Enter funnel description",
          rows: 6,
        },
      ],
    },
    {
      id: "step4",
      name: "AI Call Configuration",
      form: [
        {
          fieldName: "aiSpeed",
          label: "Speed",
          type: "select",
          options: [
            { value: 0.85, label: "Slow" },
            { value: 1, label: "Normal" },
            { value: 1.35, label: "Fast" },
            { value: 1.6, label: "Ultra Fast" },
          ],
          placeholder: "Select the speed of speech",
        },
        {
          fieldName: "aiLanguage",
          label: "Language",
          type: "select",
          options: [
            { value: "en-US", label: "American English" },
            { value: "en-GB", label: "British English" },
            { value: "nl", label: "Dutch" },
            { value: "fr", label: "French" },
            { value: "de", label: "German" },
            { value: "it", label: "Italian" },
            { value: "ja", label: "Japanese" },
            { value: "pl", label: "Polish" },
            { value: "pt", label: "Portuguese" },
            { value: "ru", label: "Russian" },
            { value: "es", label: "Spanish" },
            { value: "sv", label: "Swedish" },
            { value: "tr", label: "Turkish" },
            { value: "zh", label: "Chinese" },
          ],
          placeholder: "Select the speed of speech",
        },
        {
          fieldName: "aiMaxDuration",
          label: "Maximum Call Duration",
          type: "inputNumber",
          placeholder:
            "Specify the maximum duration of a conversation in minutes",
        },
        {
          fieldName: "aiRecord",
          label: "Record Call",
          type: "switch",
          placeholder:
            "Specify whether you would like to create a voice recording of your call",
        },
      ],
    },
  ];

  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      <div>
        <MultiStepComponent
          displaySteps={true}
          displayUndoRedo
          AIEnhancements={true}
          steps={steps}
          defaultFormData={{
            ...vacancyData,
          }}
          onNext={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            delete formData._id;
            await CrudService.update("Vacancy", id, {
              ...formData,
            });
          }}
          onFinish={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            delete formData._id;
            await CrudService.update("Vacancy", id, {
              ...formData,
            });

            navigate(`/dashboard/vacancyedit?id=${id}`);
          }}
        />
      </div>
    </>
  );
};

export default VacancyPrepublish;
