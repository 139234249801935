import {
  Button,
  Divider,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useCallback, useEffect, useState } from "react";
import { GrInfo } from "react-icons/gr";
import { IoMdSave } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectDarkMode, selectLoading } from "../../../redux/auth/selectors";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import HiringManagerAddModal from "../ForwardResume/HiringManagerAddModal";
import VariableMessageBox from "../Message/VariableMessageBox";
import VariableSMSBox from "../Message/VariableSMSBox";
import AICallEdit from "./AICallEdit";
import SurveyFormSetup from "./SurveyFormSetup";

const GeneralWorkflowConfigurator = ({ VacancyId }) => {
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const loading = useSelector(selectLoading);

  const reloadData = useCallback(async () => {
    if (!VacancyId) return;

    AuthService.me().then(({ data }) => setMe(data.me));
    CrudService.search("VacancyStage", 1000, 1, {
      filters: { vacancyId: VacancyId },
      sort: { sort: 1, createdAt: 1 },
    }).then(({ data }) => {
      setStages(data.items);
    });

    CrudService.getSingle("Vacancy", VacancyId).then(({ data }) => {
      setVacancyData(data);
    });
  }, [VacancyId]);
  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const handleConfigChange = useCallback(
    async (field, value) => {
      if (!VacancyId) return;

      await CrudService.update("Vacancy", VacancyId, {
        [field]: value,
      }).then(() => reloadData());
    },
    [reloadData, VacancyId]
  );

  if (!me) return <Skeleton active />;
  if (!stages) return <Skeleton active />;
  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      <h2 className="font-bold text-lg mb-8">General Workflow Configuration</h2>

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When a lead applies, automatically assign them to the stage{" "}
          <span className="text-indigo-500">
            {stages?.find?.((s) => s._id === vacancyData?.onApplyAssignStage)
              ?.name ?? "None"}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onApplyAssignStage}
          onChange={(value) => handleConfigChange("onApplyAssignStage", value)}
        >
          <Select.Option key={1} value={null}>
            None
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When you send a meeting request to lead, automatically assign them to
          the stage{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onRequestMeetingAssignStage
            )?.name ?? "None"}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onRequestMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onRequestMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            None
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      {/* <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When I send meeting request to lead, automatically mark them as a{" "}
          <span className="text-indigo-500">qualified</span> lead
        </label>
        <Switch
          loading={loading}
          checked={vacancyData?.onRequestMeetingMarkAsQualified}
          onChange={(value) =>
            handleConfigChange("onRequestMeetingMarkAsQualified", value)
          }
        />
      </div>
      <Divider /> */}

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead scheduled a meeting, automatically assign them to the stage{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onScheduledMeetingAssignStage
            )?.name ?? "None"}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onScheduledMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onScheduledMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            None
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When a scheduled meeting has been cancelled, automatically assign lead
          to the stage{" "}
          <span className="text-indigo-500">
            {stages?.find?.(
              (s) => s._id === vacancyData?.onCancelledMeetingAssignStage
            )?.name ?? "None"}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onCancelledMeetingAssignStage}
          onChange={(value) =>
            handleConfigChange("onCancelledMeetingAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            None
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When I mark lead as unqualified, automatically assign them to the
          stage{" "}
          <span className="text-indigo-500">
            {stages?.find?.((s) => s._id === vacancyData?.onRejectedAssignStage)
              ?.name ?? "None"}
          </span>
        </label>
        <Select
          style={{ width: 200 }}
          loading={loading}
          value={vacancyData?.onRejectedAssignStage}
          onChange={(value) =>
            handleConfigChange("onRejectedAssignStage", value)
          }
        >
          <Select.Option key={1} value={null}>
            None
          </Select.Option>
          {stages.map((stage) => (
            <Select.Option key={stage._id} value={stage._id}>
              {stage.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Divider />
    </>
  );
};

const WorkflowConfigurator = ({ workflow, VacancyId }) => {
  const [workflowData, setWorkflowData] = useState(null);
  const [surveyFormActive, setSurveyFormActive] = useState(false);
  const [webhookURL, setWebhookURL] = useState("");
  const [activatedMessagingTemplate, setActivatedMessagingTemplate] =
    useState(null);
  const loading = useSelector(selectLoading);
  const darkMode = useSelector(selectDarkMode);
  const [stages, setStages] = useState(null);
  const [addHiringManagerModal, setAddHiringManagerModal] = useState(null);
  const [hiringManagers, setHiringManagers] = useState([]);
  const [aiCallModal, setAICallModal] = useState(null);
  const [smsModal, setSMSModal] = useState(null);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    // Calculate total minutes and trigger handleConfigChange when any value changes
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
    handleConfigChange("idleMinutes", totalMinutes);
  }, [days, hours, minutes]);

  const reloadData = useCallback(() => {
    if (!!workflow?.stageId) {
      CrudService.getSingle("VacancyStage", workflow.stageId).then(
        ({ data }) => {
          setWorkflowData(data);

          if (data?.idleMinutes) {
            const totalMinutes = data.idleMinutes;
            const calculatedDays = Math.floor(totalMinutes / (24 * 60));
            const remainingMinutesAfterDays = totalMinutes % (24 * 60);
            const calculatedHours = Math.floor(remainingMinutesAfterDays / 60);
            const calculatedMinutes = remainingMinutesAfterDays % 60;

            setDays(calculatedDays);
            setHours(calculatedHours);
            setMinutes(calculatedMinutes);
          }
        }
      );
    }

    CrudService.search("HiringManagerContact", 10000000, 1, {
      sort: { createdAt: 1 },
    }).then(({ data }) => {
      setHiringManagers(data.items);
    });
  }, [workflow]);
  useEffect(() => {
    reloadData();
  }, [reloadData]);
  useEffect(() => {
    if (!VacancyId) return;

    CrudService.search("VacancyStage", 1000, 1, {
      filters: { vacancyId: VacancyId },
      sort: { sort: 1, createdAt: 1 },
    }).then(({ data }) => {
      setStages(data.items);
    });
  }, [VacancyId]);

  useEffect(() => {
    setWebhookURL(workflowData?.onEnterPushWebhookURL ?? "");
  }, [workflowData]);

  const handleConfigChange = useCallback(
    async (field, value) => {
      if (!workflow?.stageId) return;

      await CrudService.update("VacancyStage", workflow.stageId, {
        [field]: value,
      }).then(() => reloadData());
    },
    [reloadData, workflow]
  );

  if (workflow === "general")
    return <GeneralWorkflowConfigurator VacancyId={VacancyId} />;
  if (!workflowData) return <Skeleton active />;
  if (!stages) return <Skeleton active />;
  return (
    <>
      <h2 className="font-bold text-lg mb-8">
        Workflow Configuration for{" "}
        <span className="text-indigo-500">{workflowData?.name}</span>
      </h2>
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, send them{" "}
          <span className="text-indigo-500">an automated email</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendEmail}
          onChange={(value) => handleConfigChange("onEnterSendEmail", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("SendEmail");
          }}
        >
          Change Email Template
        </button>
      </Space>

      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, send them{" "}
          <span className="text-indigo-500">an automated SMS</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendSMS}
          onChange={(value) => handleConfigChange("onEnterSendSMS", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setSMSModal("onEnterSendSMSBody");
          }}
        >
          Change SMS Template
        </button>
      </Space>

      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, send them{" "}
          <span className="text-indigo-500">an AI call</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterCall}
          onChange={(value) => handleConfigChange("onEnterCall", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setAICallModal("onEnterCallScript");
          }}
        >
          Change Call Script
        </button>
      </Space>

      <Divider />
      {/* <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, send them{" "}
          <span className="text-indigo-500">request to submit the survey</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendSurveySubmitRequest}
          onChange={(value) =>
            handleConfigChange("onEnterSendSurveySubmitRequest", value)
          }
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("SurveySubmitRequest");
          }}
        >
          Change Email Template
        </button>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            setSurveyFormActive(true);
          }}
        >
          Change Survey Form
        </button>
      </Space>

      <Divider /> */}

      {/* <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, send them{" "}
          <span className="text-indigo-500">a document to sign</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterSendDocument}
          onChange={(value) => handleConfigChange("onEnterSendDocument", value)}
        />
      </div>

      <Space>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("DocumentRequestInvite");
          }}
        >
          Change Email Template
        </button>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("DocumentRequest");
          }}
        >
          Change Document Template
        </button>
      </Space>
      <Space className="mt-2">
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("DocumentRequestHiringManager");
          }}
        >
          Change Hiring Manager Reminder
        </button>
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            if (loading) return;
            setActivatedMessagingTemplate("DocumentRequestCompletion");
          }}
        >
          Change Completion Confirmation
        </button>
      </Space> */}

      {workflowData?.onEnterSendDocument && (
        <>
          <div className=" flex gap-3 items-center mt-5">
            <label className="font-bold">
              When the document has been signed, move the lead into{" "}
              <span className="text-indigo-500">
                {stages?.find?.(
                  (s) => s._id === workflowData?.onDocumentSignAssignStage
                )?.name ?? "None"}{" "}
              </span>
            </label>
            <Select
              style={{ width: 200 }}
              loading={loading}
              value={workflowData?.onDocumentSignAssignStage || null}
              onChange={(value) =>
                handleConfigChange("onDocumentSignAssignStage", value)
              }
            >
              <Select.Option key={1} value={null}>
                None
              </Select.Option>
              {stages.map((stage) => (
                <Select.Option key={stage._id} value={stage._id}>
                  {stage.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="flex w-full justify-between items-center gap-1 mt-5">
            <label className="font-bold">
              {!workflowData?.offerExpiration ? (
                <>The offer should not expire</>
              ) : (
                <>
                  When the lead fails to sign within{" "}
                  <span className="text-indigo-500">
                    {workflowData?.offerExpiration}
                  </span>{" "}
                  days, the offer should expire
                </>
              )}
            </label>
            <Space>
              <InputNumber
                value={workflowData?.offerExpiration ?? 0}
                onChange={(value) =>
                  handleConfigChange("offerExpiration", value)
                }
              />
              <Tooltip
                title={
                  !workflowData?.offerExpiration
                    ? "You can automatically revoke the offer when lead fails to sign within the specified number of days"
                    : `The offer will be automatically rescinded ${workflowData?.offerExpiration} days following its issuance. To prevent the offer from being withdrawn, you may adjust this duration to 0 days.`
                }
              >
                <GrInfo />
              </Tooltip>
            </Space>
          </div>

          <div className="flex w-full justify-between items-center gap-1 mt-5">
            <Space>
              <label className="font-bold">Hiring Manager</label>
              <Tooltip
                title={`You can designate a specific hiring manager to be accountable for signing the document, provided that the template includes a signature field for this role. This individual will also be the primary recipient of all reminders throughout the document signing process.`}
              >
                <GrInfo />
              </Tooltip>
            </Space>
            <div>
              <div className="w-full flex items-center">
                <Select
                  className="grow"
                  style={{ width: 175 }}
                  value={workflowData?.DocumentRequestHiringManager ?? null}
                  onChange={(e) =>
                    handleConfigChange("DocumentRequestHiringManager", e)
                  }
                  showSearch
                  filterOption={(input, option) => {
                    const label = option.label
                      .replace(/\@\[/g, "")
                      .replace(/\]\((.)*\)/g, "")
                      .toLowerCase();
                    return label.includes(input.toLowerCase());
                  }}
                >
                  <Select.Option key={1} value={null} label={"None"}>
                    None
                  </Select.Option>
                  {hiringManagers.map((t) => (
                    <Select.Option
                      value={t._id}
                      label={
                        `${t.firstname} ${t.lastname} <${t.email}>`.slice(
                          0,
                          40
                        ) || "-"
                      }
                    >
                      <Space className="flex justify-between">
                        <div>
                          {`${t.firstname} ${t.lastname} <${t.email}>`.slice(
                            0,
                            40
                          ) || "-"}
                        </div>
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
                <button
                  onClick={() => {
                    setAddHiringManagerModal(true);
                  }}
                  className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                  disabled={loading}
                >
                  {!loading ? (
                    "Add Hiring Manager"
                  ) : (
                    <Spin>Add Hiring Manager</Spin>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <Divider />

      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, push lead data into an
          external webhook
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterPushWebhook}
          onChange={(value) => handleConfigChange("onEnterPushWebhook", value)}
        />
      </div>

      <h2 className="text-md font-semibold">Webhook URL</h2>
      <Search
        enterButton={<IoMdSave />}
        className="dark:text-gray-900"
        placeholder="https://webhook.site/..."
        value={webhookURL}
        onChange={(e) => setWebhookURL(e.target.value)}
        onSearch={async (e) => {
          handleConfigChange("onEnterPushWebhookURL", webhookURL).then(() =>
            message.success("Webhook URL successfully updated")
          );
        }}
      />

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">engaged</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsEngaged}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsEngaged", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">nurtured</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsQualified}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsQualified", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">not nurtured</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotQualified}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotQualified", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">scheduled for sales call</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsScheduledForMeeting}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsScheduledForMeeting", value)
          }
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">not scheduled for sales call</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotScheduledForMeeting}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotScheduledForMeeting", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">converted</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsHired}
          onChange={(value) => handleConfigChange("onEnterMarkAsHired", value)}
        />
      </div>
      <Divider />
      <div className="mb-5 flex gap-3 items-center">
        <label className="font-bold">
          When lead enters stage {workflowData?.name}, mark them as{" "}
          <span className="text-indigo-500">lost</span>
        </label>
        <Switch
          loading={loading}
          checked={workflowData?.onEnterMarkAsNotHired}
          onChange={(value) =>
            handleConfigChange("onEnterMarkAsNotHired", value)
          }
        />
      </div>

      <Divider />
      <div className="mb-5">
        <div className=" flex gap-3 items-center">
          <label className="font-bold">
            When leads stay too long in stage {workflowData?.name}, move them
            into{" "}
            <span className="text-indigo-500">
              {stages?.find?.((s) => s._id === workflowData?.onIdleAssignStage)
                ?.name ?? "None"}{" "}
            </span>
          </label>
          <Select
            style={{ width: 200 }}
            loading={loading}
            value={workflowData?.onIdleAssignStage || null}
            onChange={(value) => handleConfigChange("onIdleAssignStage", value)}
          >
            <Select.Option key={1} value={null}>
              None
            </Select.Option>
            {stages.map((stage) => (
              <Select.Option key={stage._id} value={stage._id}>
                {stage.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="flex w-full justify-end items-center gap-1">
          {workflowData?.onIdleAssignStage && (
            <>
              <div>
                <div>Days</div>
                <InputNumber
                  min={0}
                  value={days}
                  onChange={(value) => setDays(value ?? 0)}
                  placeholder="Days"
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>Hours</div>
                <InputNumber
                  min={0}
                  value={hours}
                  onChange={(value) => setHours(value ?? 0)}
                  placeholder="Hours"
                  style={{ width: "70px" }}
                />
              </div>
              <div>
                <div>Minutes</div>
                <InputNumber
                  min={0}
                  value={minutes}
                  onChange={(value) => setMinutes(value ?? 0)}
                  placeholder="Minutes"
                  style={{ width: "70px" }}
                />
              </div>
              <Tooltip
                title={`Candidates will be moved after ${
                  days > 0 ? `${days} day(s) ` : ""
                }${hours > 0 ? `${hours} hour(s) ` : ""}${
                  minutes > 0 ? `${minutes} minute(s) ` : ""
                } of idle time waiting in this stage.`}
              >
                <GrInfo />
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!activatedMessagingTemplate}
        onCancel={() => setActivatedMessagingTemplate(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <VariableMessageBox
          workflowData={workflowData}
          defaultSubject={
            workflowData?.[`${activatedMessagingTemplate}Subject`]
          }
          defaultBody={workflowData?.[`${activatedMessagingTemplate}Body`]}
          onSend={async (subject, body) => {
            setActivatedMessagingTemplate(null);
            if (workflow?.stageId)
              await CrudService.update("VacancyStage", workflow.stageId, {
                [`${activatedMessagingTemplate}Subject`]: subject,
                [`${activatedMessagingTemplate}Body`]: body,
              }).then(() => reloadData());
          }}
          additionalVariables={
            activatedMessagingTemplate === "DocumentRequest"
              ? [
                  {
                    id: "offerExpirationDate",
                    display: "Offer Expiration Date",
                  },
                  {
                    id: "hiringAuthorityPrintedName",
                    display: "Hiring Authority Printed Name",
                  },
                  {
                    id: "hiringAuthorityJobTitle",
                    display: "Hiring Authority Job Title",
                  },
                  { id: "candidateAddress", display: "Lead's Address" },
                  {
                    id: "candidateSignature",
                    display: "Lead's Signature",
                  },
                  {
                    id: "candidateSignatureDate",
                    display: "Lead's Signature Date",
                  },
                  {
                    id: "hiringAuthoritySignature",
                    display: "Hiring Authority Signature",
                  },
                  {
                    id: "hiringAuthoritySignatureDate",
                    display: "Hiring Authority Signature Date",
                  },
                ]
              : activatedMessagingTemplate === "DocumentRequestInvite"
              ? [
                  {
                    id: "documentSigningLink",
                    display: "Document Signing Link",
                  },
                  {
                    id: "documentTitle",
                    display: "Document Title",
                  },
                  {
                    id: "offerExpirationDate",
                    display: "Offer Expiration Date",
                  },
                ]
              : activatedMessagingTemplate === "DocumentRequestHiringManager"
              ? [
                  {
                    id: "hiringManagerSigningLink",
                    display: "Hiring Manager Signing Link",
                  },
                  {
                    id: "hiringAuthorityPrintedName",
                    display: "Hiring Authority Printed Name",
                  },
                  {
                    id: "hiringAuthorityJobTitle",
                    display: "Hiring Authority Job Title",
                  },
                ]
              : activatedMessagingTemplate === "DocumentRequestCompletion"
              ? [
                  {
                    id: "documentTitle",
                    display: "Document Title",
                  },
                  {
                    id: "documentSigningLink",
                    display: "Document Signing Link",
                  },
                ]
              : []
          }
        />
      </Modal>

      <SurveyFormSetup
        surveyFormActive={surveyFormActive}
        setSurveyFormActive={setSurveyFormActive}
      />

      <HiringManagerAddModal
        addHiringManagerModal={addHiringManagerModal}
        setAddHiringManagerModal={setAddHiringManagerModal}
        reloadTemplates={reloadData}
        setSelectedHiringManager={async (e) =>
          await handleConfigChange("DocumentRequestHiringManager", e)
        }
      />

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!aiCallModal}
        onCancel={() => setAICallModal(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <AICallEdit
          id={workflow.stageId}
          onFinish={() => {
            setAICallModal(null);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!smsModal}
        onCancel={() => setSMSModal(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <VariableSMSBox
          workflowData={workflowData}
          defaultBody={workflowData?.[`${smsModal}`]}
          onSend={async (body) => {
            setSMSModal(null);
            if (workflow?.stageId)
              await CrudService.update("VacancyStage", workflow.stageId, {
                [`${smsModal}`]: body,
              }).then(() => reloadData());
          }}
          disableTemplate={smsModal === "onEnterCallScript"}
        />
      </Modal>
    </>
  );
};

export default WorkflowConfigurator;
