import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Divider, Popconfirm, Skeleton, Space } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";
import { Button } from "../../Landing/Button";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const getNoteUser = (user, note) => {
  if (!note?.loggedBy?.firstName) return user;
  return note.loggedBy;
};

const CandidateNote = ({ candidateId }) => {
  const [note, setNote] = useState(
    localStorage[`candidateNote_${candidateId}`] ?? ""
  );
  const [candidateData, setCandidateData] = useState(null);
  const [candidateNotes, setCandidateNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    if (!candidateId) return;
    CrudService.getSingle("VacancySubmission", candidateId).then(({ data }) => {
      if (data.candidateNote) setNote(data.candidateNote);
      if (data) setCandidateData(data);
    });

    CrudService.search("CandidateNotes", 1000, 1, {
      filters: { candidate: candidateId },
      populate: "loggedBy",
    }).then(({ data }) => {
      setCandidateNotes(data.items);
    });
  }, [candidateId]);

  const debounceDelay = 750;
  const debounceTimer = useRef(null);

  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    if (!user) return;
    if (user?.accessLevel === "read") return;

    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(
      () => {
        localStorage[`candidateNote_${candidateId}`] = note;
        CrudService.update("VacancySubmission", candidateId, {
          candidateNote: note,
        });
      },
      debounceDelay,
      user
    );

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [note, candidateId]);

  if (!candidateData) return <Skeleton active />;
  return (
    <>
      <h2 className="text-xl font-bold mb-1">Lead Notes</h2>
      <h3 className="text-lg font-semibold mb-2">
        {candidateData?.formData?.firstname} {candidateData?.formData?.lastname}
      </h3>

      <h3 className="text-lg font-semibold mb-2">General Notes</h3>

      <div className="dark:text-black">
        <CKEditor
          editor={ClassicEditor}
          data={note}
          disabled={user?.accessLevel === "read"}
          onChange={(event, editor) => {
            const data = editor.getData();
            if (data) setNote(data);
          }}
        />
      </div>

      <Divider />
      <h3 className="text-lg font-semibold mb-2">Notes</h3>

      {candidateNotes
        ?.sort?.((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        ?.map?.((note) => (
          <div
            className="border-b-2 pb-2 border-gray-100 my-5 relative pt-8"
            key={note?._id}
          >
            <div className="absolute top-1 start-1 text-sm text-gray-400">
              {getNoteUser(user, note)?.firstName ?? ""}{" "}
              {getNoteUser(user, note)?.lastName ?? ""}{" "}
              {`<${getNoteUser(user, note)?.email ?? ""}>`} at{" "}
              {moment(note.createdAt).format("HH:mm, Do MMM YYYY")}
            </div>
            <div className="absolute top-1 end-1">
              <Space>
                <FaEdit
                  size={18}
                  className="cursor-pointer"
                  onClick={() =>
                    setEditing((e) =>
                      e?._id === note._id ? null : { ...note }
                    )
                  }
                />
                <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={async () => {
                    if (!note?._id) return;
                    await CrudService.delete("CandidateNotes", note?._id).then(
                      () => {
                        setCandidateNotes((e) =>
                          e.filter((x) => x._id !== note?._id)
                        );
                      }
                    );
                  }}
                >
                  <MdDelete size={18} className="cursor-pointer text-red-500" />
                </Popconfirm>
              </Space>
            </div>

            {editing?._id === note._id ? (
              <div className="dark:text-black">
                <CKEditor
                  editor={ClassicEditor}
                  data={editing.message}
                  disabled={user?.accessLevel === "read"}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data) setEditing((e) => ({ ...e, message: data }));
                  }}
                />

                <Button
                  variant="solid"
                  color="blue"
                  className="w-full rounded-none rounded-b-lg"
                  onClick={() => {
                    if (loading) return;
                    CrudService.update("CandidateNotes", editing._id, {
                      message: editing.message,
                    }).then(({ data }) => {
                      CrudService.search("CandidateNotes", 1000, 1, {
                        filters: { candidate: candidateId },
                        populate: "loggedBy",
                      }).then(({ data }) => {
                        setCandidateNotes(data.items);
                        setEditing(null);
                      });
                    });
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  <span>Update</span>
                </Button>
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: note?.message?.replace?.(/\n/g, "<br>"),
                }}
              />
            )}
          </div>
        ))}

      <CKEditor
        editor={ClassicEditor}
        data={newNote}
        disabled={user?.accessLevel === "read"}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (data) setNewNote(data);
        }}
      />

      <div className="mt-2">
        <Button
          variant="solid"
          color="blue"
          className="w-full"
          onClick={() => {
            const note = {
              candidate: candidateId,
              message: newNote,
              loggedBy: user.actualID ?? user._id,
            };

            CrudService.create("CandidateNotes", note).then(({ data }) => {
              setCandidateNotes((e) => [data.result, ...e]);
              setNewNote("");
            });
          }}
          loading={loading}
        >
          <span>Insert Note</span>
        </Button>
      </div>
    </>
  );
};

export default CandidateNote;
