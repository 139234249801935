import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { Popconfirm, Skeleton, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../../components/MultiStepComponent";
import MultiStepConfigurator from "../../../components/MultiStepConfigurator";
import { getPartner } from "../../../redux/auth/selectors";
import CalendlyService from "../../../service/CalendlyService";
import CrudService from "../../../service/CrudService";
import getFormPrompt from "./getFormPrompt";
import getFunnelPrompt from "./getFunnelPrompt";

const steps = (eventTypes) => [
  {
    id: "step1",
    name: "General Information",
    form: [
      {
        fieldName: "name",
        label: "Job name",
        type: "input",
        placeholder: "Dentist",
      },
      {
        fieldName: "valueProposition",
        label: "Scroll stopper",
        type: "input",
        placeholder: "Enter the scroll stopper text",
      },
      {
        fieldName: "heroTitle",
        label: "Hero title",
        type: "textarea",
        placeholder: "Enter title on top of your funnel",
      },
      {
        fieldName: "requiredSkills",
        label: "Requirements",
        type: "textarea",
        placeholder: "Enter the required skills",
      },
    ],
  },
  {
    id: "step10",
    name: "Details",
    form: [
      {
        fieldName: "location",
        label: "Preferred Work Location",
        type: "input",
        placeholder: "Enter your preferred work location",
      },

      {
        fieldName: "engagementType",
        label: "Engagement Type",
        type: "select",
        options: [
          { value: "Permanent", label: "Permanent" },
          { value: "Temporary", label: "Temporary" },
          { value: "Fixed-Term", label: "Fixed-Term" },
          { value: "Freelance", label: "Freelance" },
          { value: "Project-based", label: "Project-based" },
          { value: "Retainer", label: "Retainer" },
          { value: "Seasonal", label: "Seasonal" },
          { value: "Internship", label: "Internship" },
          { value: "Apprenticeship", label: "Apprenticeship" },
          { value: "Commission-Based", label: "Commission-Based" },
          { value: "Contract-to-Hire", label: "Contract-to-Hire" },
          { value: "Partnership", label: "Partnership" },
        ],
      },
      {
        fieldName: "contractType",
        label: "Contract Type",
        type: "select",
        options: [
          { value: "Full-time", label: "Full-time" },
          { value: "Part-time", label: "Part-time" },
          { value: "Casual", label: "Casual" },
          { value: "Zero-Hours", label: "Zero-Hours" },
          { value: "Profit-share", label: "Profit-share" },
        ],
      },
      {
        fieldName: "preferredCalendlyEvent",
        label: "Calendly Event Type",
        type: "select",
        tooltip:
          "Specify a unique Calendly event type for each funnel, or leave blank to use the default account setting for interviews.",
        options: eventTypes.map((eventType) => ({
          value: eventType.uri,
          label: eventType.name,
        })),
      },
    ],
  },
  {
    id: "step3",
    name: "Benefits & Culture",
    form: [
      {
        fieldName: "benefitsTitle",
        label: "Benefits Title",
        type: "input",
        placeholder: "Title for Benefits Section",
      },
      {
        fieldName: "benefitsText",
        label: "Benefits Description",
        type: "textarea",
        rows: 10,
        placeholder: "Describe the benefits of working with us",
      },
      {
        fieldName: "benefits",
        label: "Benefits",
        type: "list",
        defaultForm: [
          {
            fieldName: "title",
            label: "Title",
            type: "input",
            placeholder: "Title of this benefit",
          },
          {
            fieldName: "description",
            label: "Description",
            type: "textarea",
            placeholder: "Description of this benefit",
          },
          {
            fieldName: "icon",
            label: "Icon",
            type: "input",
            placeholder: "Emoji",
          },
        ],
        defaultObject: {
          title: "",
          description: "",
          icon: "",
        },
      },
    ],
  },
  {
    id: "step4",
    name: "Testimonials",
    form: [
      {
        fieldName: "testimonialTitle",
        label: "Testimonial Section Title",
        type: "input",
        placeholder: "Enter the title for the testimonial section",
      },
      {
        fieldName: "teamTestimonials",
        label: "Testimonials",
        type: "list",
        defaultForm: [
          {
            fieldName: "author",
            label: "Fullname",
            type: "input",
            placeholder: "Fullname of the team member",
          },
          {
            fieldName: "authorPosition",
            label: "Role",
            type: "input",
            placeholder: "The position of the team member",
          },
          {
            fieldName: "testimonial",
            label: "Testimonial",
            type: "textarea",
            rows: 5,
            placeholder: "What does the team member think about the company?",
          },
        ],
        defaultObject: {
          author: "",
          authorPosition: "",
          testimonial: "",
        },
      },
    ],
  },
  {
    id: "step5",
    name: "Application Submission",
    form: [
      {
        fieldName: "CTA",
        label: "Call to Action",
        type: "input",
        placeholder: "Call to action for application submission",
      },
    ],
  },
  {
    id: "step6",
    name: "Post-Application",
    form: [
      {
        fieldName: "thankYouHero",
        label: "Thank You Message",
        type: "input",
        placeholder: "Enter the thank you message post-application",
      },
      {
        fieldName: "underReviewInfo",
        label: "Application Under Review Information",
        type: "textarea",
        rows: 3,
        placeholder: "Information about the application review process",
      },
      {
        fieldName: "nextSteps",
        label: "Next Steps After Application",
        type: "textarea",
        rows: 3,
        placeholder:
          "Describe the next steps after the application is submitted",
      },

      // {
      //   fieldName: "eeodc",
      //   label: "Include EEO Data Collection Form",
      //   type: "switch",
      // },
    ],
  },
];

const VacancyEdit = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);
  const [funnelSteps, setFunnelSteps] = useState([]);
  const [thinking, setThinking] = useState(false);
  const vidRef = useRef();
  const socket = useRef();
  const socketPing = useRef();
  const partner = useSelector(getPartner);
  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    if (!partner?.calendlyclientId) return;
    CalendlyService.getEventTypes().then(({ data }) => {
      setEventTypes(data.eventTypes);
    });
  }, [partner]);

  useEffect(() => {
    if (vacancyData?.form) setFunnelSteps(vacancyData.form);
  }, [vacancyData]);

  useEffect(() => {
    if (vidRef.current) vidRef.current.play();
  }, [vidRef]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    if (!partner) return;
    setVacancyData(null);
    setFunnelSteps([]);
    setThinking(false);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
      if (!res.data?.form) {
        setThinking(true);
        socket.current = new WebSocket(
          `wss://booklified-chat-socket.herokuapp.com`
        );

        socket.current.addEventListener("open", async () => {
          socketPing.current = setInterval(
            () => socket.current.send(JSON.stringify({ id: "PING" })),
            30000
          );
          const content = getFormPrompt({
            jobName: res.data.name,
            description: res.data.description,
            keyBenefits: res.data.keyBenefits,
            requiredSkills: res.data.requiredSkills,
          });
          socket.current.send(
            JSON.stringify({
              id: "OPEN_AI_PROMPT",
              payload: {
                content,
                model: "gpt-3.5-turbo",
                partner: partner._id,
              },
            })
          );

          const content2 = getFunnelPrompt({
            jobName: res.data.name,
            description: res.data.description,
            keyBenefits: res.data.keyBenefits,
            requiredSkills: res.data.requiredSkills,
          });
          socket.current.send(
            JSON.stringify({
              id: "OPEN_AI_PROMPT",
              payload: {
                trackId: "2",
                content: content2,
                model: "gpt-3.5-turbo",
                partner: partner._id,
              },
            })
          );
        });

        socket.current.addEventListener("message", async (event) => {
          const message = JSON.parse(event.data);
          let formData = [];
          const response = message.payload?.response;

          if (message.payload?.trackId === "2") {
            const data = JSON.parse(response.replace(/\n\n/g, " "));
            if (!data?.valueProposition)
              data.valueProposition = `We are hiring ${res.data?.name ?? ""}`;
            await CrudService.update("Vacancy", id, data);
            await CrudService.getSingle("Vacancy", id).then((res) => {
              setVacancyData(res.data);
              if (!!res.data?.form && !!res.data?.valueProposition) {
                setThinking(false);
              }
            });
          } else {
            try {
              formData = JSON.parse(
                `[${response
                  .split("[")
                  ?.slice(1)
                  .join("[")
                  .split("]")
                  .slice(0, -1)
                  .join("]")}]`
              );
              if (!Array.isArray(formData)) throw new Error("Not an array");
            } catch (e) {}
            if (socketPing.current) clearInterval(socketPing.current);
            await CrudService.update("Vacancy", id, { form: formData });
            await CrudService.getSingle("Vacancy", id).then((res) => {
              setVacancyData(res.data);
              if (!!res.data?.form && !!res.data?.valueProposition) {
                setThinking(false);
              }
            });
          }
        });
      }
    });
  }, [searchParams, partner]);

  if (!vacancyData) return <Skeleton active />;
  if (thinking)
    return (
      <div className="flex justify-center">
        <video
          className="rounded-xl"
          ref={vidRef}
          muted
          src={partner?.aiVideoSrc ?? "/videos/aithink.mp4"}
          autoPlay
          loop
          style={{ height: "80vh" }}
        />
      </div>
    );

  if (!funnelSteps) return <Skeleton active />;
  return (
    <>
      <div>
        <MultiStepComponent
          AIEnhancements={true}
          displayUndoRedo
          steps={steps(eventTypes)}
          defaultFormData={{
            ...vacancyData,
          }}
          onFinish={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            await CrudService.update("Vacancy", id, {
              ...formData,
            });

            navigate(`/dashboard/vacancyeditForm?id=${id}`);
          }}
          onNext={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;

            await CrudService.update("Vacancy", id, {
              ...formData,
            });
          }}
          buttomLineWrapperClass="fixed bottom-0 w-full bg-white dark:bg-gray-900 rounded-t-sm pl-[20px] pt-[20px] pb-[20px] pr-[80px] right-0	bottom-0"
          buttomLineInnerClass="items-end flex flex-col"
        />
      </div>
    </>
  );
};

export default VacancyEdit;
