import { v4 as uuidv4 } from "uuid";

export const templates = ({ user, images = [] }) => ({
  default: `{
      "name": "A meaningful name for the landing page",
      "pageContent": [
        {
          "id": "HeadlineComponent",
          "data": {
            "description": [
              {
                "id": "4be090b3-b49c-42a6-826a-c1a311225635",
                "type": "DivComponent",
                "components": [
                  {
                    "id": "9fb945d9-317b-4a30-88f6-5742248b6dd3",
                    "type": "TextComponent",
                    "content": {
                      "text": "[Replace this with a short descriptive headline]",
                      "className": "text-center text-5xl uppercase font-extrabold font-sans"
                    }
                  }
                ],
                "content": {
                  "className": "container-sm mdx:p-5 smx:px-5 pl-8 pr-8 flex gap-6"
                }
              }
            ]
          }
        },
        {
          "id": "Hero",
          "data": {
            "description": [
              {
                "type": "DivComponent",
                "id": "${uuidv4()}",
                "content": {
                  "className": ""
                },
                "components": [
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "A great headline to capture interest",
                      "className":
                        "text-gray-900_02 text-[45px] font-semibold md:text-[41px] sm:text-[35px] text-gray-900_02"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "5-10 word subline",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "A detailed description of our services / products",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  }
                ]
              }],
            "image": "${
              images?.[0 % 6] ?? "/dhimages/img_screen_mockup_replace.png"
            }",
            "video": "https://player.vimeo.com/video/937499561?h=a4a0716db0",
            "benefits": [
              {
                "text": "5-10 word benefit"
              },
              {
                "text": "5-10 word benefit"
              },
              {
                "text": "5-10 word benefit"
              }
            ]
          }
        },
        {
          "id": "CallToAction",
          "data": {
            "headline": "Our core value proposition and what it entails for the customer",
            "description": "A more detailed description of why they should start today",
            "secondaryButtonText": "Contact Us",
            "secondaryButtonLink": "#contact",
            "primaryButtonText": "Book Demo",
            "primaryButtonLink": "${
              user?.userCalendarLink ?? "https://calendly.com/"
            }"
          }
        },
         {
            "id": "CustomerPainPoints",
            "data": {
              "featuredShowcase1Text": "Overcome The Biggest Digital Marketing Challenges",
              "featuredShowcase2Text":  "Having the right online presence sets your business up for success.",
              "featuredShowcase3Text": "Running multiple ad campaigns means creating countless copywriting creatives and undergoing constant A/B testing to see what works.",
            "featuredShowcase1Img": "${
              images?.[1 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase4Text": "Building a landing page from scratch can be time-consuming and technically demanding.",
              "featuredShowcase5Text": "Our platform allows you to generate and customize landing pages in minutes, not days, with drag-and-drop ease and no coding required.",
            "featuredShowcase2Img": "${
              images?.[2 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase6Text": "Organizing and producing webinars takes weeks of work, from content creation to technical setup.",
              "featuredShowcase7Text":  "Our platform includes AI avatars and pre-built webinar templates that make going live simple and impactful, keeping your audience engaged and informed.",
            "featuredShowcase3Img": "${
              images?.[3 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase8Text": "Crafting compelling email sequences that resonate with customers is a must for engagement and retention.",
              "featuredShowcase9Text": "Our AI helps you create personalized email sequences, ensuring your messaging hits the right note every time.",
            "featuredShowcase4Img": "${
              images?.[4 % 6] ?? "/dhimages/img_image_1.png"
            }"
            }
          },
      

        {
          "id": "AdminFeatures",
          "data": {
            "title": "Feature Category Title",
            "features": [
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              }
            ],
            "image": "${images?.[3 % 6] ?? "/dhimages/img_image_1.png"}"
          }
        },
        {
          "id": "AdminFeatures",
          "data": {
            "title": "Feature Category Title",
            "features": [
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              }
            ],
            "imageLeft": "${
              images?.[4 % 6] ?? "/dhimages/img_image_500x594.png"
            }"
          }
        },
        {
          "id": "AdminFeatures",
          "data": {
            "title": "Feature Category Title",
            "features": [
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              },
              {
                "description": "Feature description (5-10 words)"
              }
            ],
            "image": "${images?.[5 % 6] ?? "/dhimages/img_image_1.png"}"
          }
        },
        {
          "id": "TestemonialSectionSlider",
          "data": {
            "testimonials": [
              {
                "title": "Testimonial section title",
                "description": "Summary of what customers are saying about us",
                "rate": "5.0",
                "totalReviewers": 200
              },
              {
                "title": "Testimonial section title",
                "description": "Summary of what customers are saying about us",
                "rate": "5.0",
                "totalReviewers": 58
              }
            ]
          }
        },
   
        {
          "id": "DivideLine"
        },
        {
          "id": "LeadForm",
          "data": {
            "headline": "Sign Up for Updates",
            "description": "Stay informed with the latest news from our team.",
            "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
          }
        },
        {
          "id": "DivideLine"
        }
      ]
    }`,
  secondary: `{
      "name": "A meaningful name for the landing page",
      "pageContent": [
              {
          "id": "CustomComponent",
          "data": {
            "description": [
              {
                "id": "4be090b3-b49c-42a6-826a-c1a311225635",
                "type": "DivComponent",
                "components": [
                  {
                    "id": "9fb945d9-317b-4a30-88f6-5742248b6dd3",
                    "type": "TextComponent",
                    "content": {
                      "text": "[Replace this with a short descriptive headline]",
                      "className": "text-center text-5xl uppercase font-extrabold font-sans"
                    }
                  }
                ],
                "content": {
                  "className": "container-sm mdx:p-5 smx:px-5 pl-8 pr-8 flex gap-6"
                }
              }
            ]
          }
        },
        {
          "id": "Hero",
          "data": {
            "description": [
              {
                "type": "DivComponent",
                "id": "${uuidv4()}",
                "content": {
                  "className": ""
                },
                "components": [
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "A great headline to capture interest",
                      "className":
                        "text-gray-900_02 text-[45px] font-semibold md:text-[41px] sm:text-[35px] text-gray-900_02"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "5-10 word subline",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  },
                  {
                    "type": "TextComponent",
                    "id": "${uuidv4()}",
                    "content": {
                      "text": "A detailed description of our services / products",
                      "className": "text-[#475467] font-semibold smx:text-sm text-base"
                    }
                  }
                ]
              }],
            "image": "${
              images?.[0 % 6] ?? "/dhimages/img_screen_mockup_replace.png"
            }",
            "video": "https://player.vimeo.com/video/937499561?h=a4a0716db0",
            "benefits": [
              {
                "text": "5-10 word benefit"
              },
              {
                "text": "5-10 word benefit"
              },
              {
                "text": "5-10 word benefit"
              }
            ]
          }
        },
          {
            "id": "CustomerPainPoints",
            "data": {
              "featuredShowcase1Text": "Overcome The Biggest Digital Marketing Challenges",
              "featuredShowcase2Text":  "Having the right online presence sets your business up for success.",
              "featuredShowcase3Text": "Running multiple ad campaigns means creating countless copywriting creatives and undergoing constant A/B testing to see what works.",
            "featuredShowcase1Img": "${
              images?.[1 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase4Text": "Building a landing page from scratch can be time-consuming and technically demanding.",
              "featuredShowcase5Text": "Our platform allows you to generate and customize landing pages in minutes, not days, with drag-and-drop ease and no coding required.",
            "featuredShowcase2Img": "${
              images?.[2 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase6Text": "Organizing and producing webinars takes weeks of work, from content creation to technical setup.",
              "featuredShowcase7Text":  "Our platform includes AI avatars and pre-built webinar templates that make going live simple and impactful, keeping your audience engaged and informed.",
            "featuredShowcase3Img": "${
              images?.[3 % 6] ?? "/dhimages/img_image_1.png"
            }",
              "featuredShowcase8Text": "Crafting compelling email sequences that resonate with customers is a must for engagement and retention.",
              "featuredShowcase9Text": "Our AI helps you create personalized email sequences, ensuring your messaging hits the right note every time.",
            "featuredShowcase4Img": "${
              images?.[4 % 6] ?? "/dhimages/img_image_1.png"
            }"
            }
          },
            {
          "id": "FeatureSlider",
          "data": {
            "slides": [
              {
            "image": "${
              images?.[1 % 6] ??
              "/dhimages/img_screen_mockup_replace_221x333.png"
            }",
                "title": "Feature title of our product / service",
                "description": "Feature descritpion of our product / service"
              },
              {
            "image": "${
              images?.[2 % 6] ??
              "/dhimages/img_screen_mockup_replace_221x333.png"
            }",
                "title": "Feature title of our product / service",
                "description": "Feature descritpion of our product / service"
              }
            ]
          }
        },
          {
            "id": "Challenges",
            "data": {
              "challenges": [
                {
                  "name": "Unbiased Screening",
                  "content": "Reaching out and initiating the first contact is be time-consuming. Let alone following up to make sure the candidate is there. Fear not! Your AI-agent books, texts, and follows up to streamline the process, maximizing interviews and optimizing every sourcing or marketing effort.",
            "image": "${
              images?.[5 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                },
                {
                  "name": "Efficient Reporting",
                  "content": "Reaching out and initiating the first contact is be time-consuming. Let alone following up to make sure the candidate is there. Fear not! Your AI-agent books, texts, and follows up to streamline the process, maximizing interviews and optimizing every sourcing or marketing effort.",
            "image": "${
              images?.[6 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                },
                {
                  "name": "Unbiased Screening 2",
                  "content": "Reaching out and initiating the first contact is be time-consuming. Let alone following up to make sure the candidate is there. Fear not! Your AI-agent books, texts, and follows up to streamline the process, maximizing interviews and optimizing every sourcing or marketing effort.",
            "image": "${
              images?.[7 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                },
                {
                  "name": "Efficient Reporting 2",
                  "content": "Reaching out and initiating the first contact is be time-consuming. Let alone following up to make sure the candidate is there. Fear not! Your AI-agent books, texts, and follows up to streamline the process, maximizing interviews and optimizing every sourcing or marketing effort.",
            "image": "${
              images?.[8 % 6] ??
              "/dhimages/img_screen_mockup_replace_280x420.png"
            }"
                }
              ],
              "heading": "Hireheroes Defies all recruiter challenges",
              "title": "Hireheroes combines AI and automations to reduce cost, save time, deliver quality candidates and an improved candidate experience.",
              "subline": "Example: job posting with 100 applicants"
            }
          },
          {
            "id": "LeadForm",
            "data": {
              "headline": "Sign Up for Updates",
              "description": "Stay informed with the latest news from our team.",
              "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "Feature Category Title",
              "features": [
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                }
              ],
              "imageLeft": "${images?.[9 % 6] ?? "/dhimages/img_image_1.png"}"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "Feature Category Title",
              "features": [
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                }
              ],
              "image": "${
                images?.[10 % 6] ?? "/dhimages/img_image_500x594.png"
              }"
            }
          },
          {
            "id": "AdminFeatures",
            "data": {
              "title": "Feature Category Title",
              "features": [
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                },
                {
                  "description": "Feature description (5-10 words)"
                }
              ],
              "imageLeft": "${images?.[11 % 6] ?? "/dhimages/img_image_1.png"}"
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "Benefits",
            "data": {
              "title": "A meaningful title for the entire benefits section",
              "description": "Description of what our core benefits are",
              "benefits": [
                {
                  "emoji": "🚀",
                  "title": "Benefit title (2-5 words)",
                  "description": "Benefit description (10-20 words)"
                },
                {
                  "emoji": "🚀",
                  "title": "Benefit title (2-5 words)",
                  "description": "Benefit description (10-20 words)"
                },
                {
                  "emoji": "🚀",
                  "title": "Benefit title (2-5 words)",
                  "description": "Benefit description (10-20 words)"
                },
                {
                  "emoji": "🚀",
                  "title": "Benefit title (2-5 words)",
                  "description": "Benefit description (10-20 words)"
                }
              ]
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "ValueProp",
            "data": {
              "title": "Title for the section of our promise",
              "description": "Description of our promise",
              "image": "${images?.[12 % 6] ?? "/dhimages/img_image.png"}"
            }
          },
          {
            "id": "DivideLine"
          },
          {
            "id": "LeadForm",
            "data": {
              "headline": "Sign Up for Updates",
              "description": "Stay informed with the latest news from our team.",
              "privacyPolicyLink": "${user?.privacyPolicyLink ?? "#"}"
            }
          },
          {
            "id": "DivideLine"
          }
        ]
    }`,
});
