import React, { useMemo, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { useSelector } from "react-redux";
import { getPartner } from "../../redux/auth/selectors";
import { Container } from "./Container";
import backgroundImage from "./images/background-faqs.jpg";

export function Faqs({ data }) {
  const partner = data || useSelector(getPartner);

  const faqSegments = useMemo(() => {
    const faqs = partner?.FAQ;
    if (!faqs) return [];

    const array = [];
    const current = [];
    for (const review of faqs) {
      current.push(review);
      if (current.length === 3) {
        array.push([...current]);
        current.length = 0;
      }
    }
    if (current.length) array.push([...current]);

    return array;
  }, [partner]);

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQIndex === index) {
      setOpenFAQIndex(null); // Close FAQ if it's already open
    } else {
      setOpenFAQIndex(index); // Open clicked FAQ
    }
  };

  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={partner?.lpSimpleColors ? null : backgroundImage}
        style={{ display: partner?.lpSimpleColors ? "none" : "block" }}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2
            id="faq-title"
            className="text-3xl font-semibold text-center text-gray-900 sm:text-4xl"
          >
            Frequently Asked Questions
          </h2>
          <div className="mt-6 border-t border-gray-200 ">
            {faqSegments?.map((column, columnIndex) =>
              column.map((faq, faqIndex) => (
                <div key={faqIndex} className="border-b border-gray-200">
                  <button
                    onClick={() => toggleFAQ(faqIndex + columnIndex * 100)}
                    className={`flex items-center justify-start gap-1 w-full p-4 text-left text-gray-900 bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 ${
                      faqIndex === faqSegments.length - 1 &&
                      columnIndex === column.length - 1 &&
                      openFAQIndex !== faqIndex + columnIndex * 100
                        ? "rounded-bl-lg rounded-br-lg"
                        : faqIndex === 0 && columnIndex === 0
                        ? "rounded-tl-lg rounded-tr-lg"
                        : ""
                    }`}
                  >
                    {openFAQIndex === faqIndex + columnIndex * 100 ? (
                      <IoMdArrowDropdown />
                    ) : (
                      <IoMdArrowDropright />
                    )}
                    <span className="text-lg font-medium text-gray-100">
                      {faq.question}
                    </span>
                  </button>
                  <div
                    className={`border border-indigo-500 transition-max-height duration-500 ease-in-out overflow-hidden ${
                      openFAQIndex === faqIndex + columnIndex * 100
                        ? "max-h-96"
                        : "max-h-0"
                    }`}
                  >
                    <p className="p-4 text-gray-700">{faq.answer}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Container>
    </section>
  );
}
