import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class CrudService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  create(model, body) {
    return this.api.post(`/create?ModelName=${model}`, body);
  }
  update(model, id, body) {
    return this.api.put(`/single?ModelName=${model}&id=${id}`, body);
  }
  getSingle(model, id, populate, populate2) {
    let url = `/single?ModelName=${model}&id=${id}`;
    if (populate) url += `&populate=${populate}`;
    if (populate2) url += `&populate2=${populate2}`;
    return this.api.get(url);
  }
  delete(model, id) {
    return this.api.delete(`/single?ModelName=${model}&id=${id}`);
  }
  search(model, limit, page, { text, filters, sort, populate, populate2 }) {
    return this.api.post(
      `/search?ModelName=${model}&limit=${limit ?? 10}&page=${page ?? 1}`,
      {
        text,
        filters,
        sort,
        populate,
        populate2,
      }
    );
  }
}

export default new CrudService(`${getBackendUrl()}/crud`);
