import {
  Select as AntdSelect,
  Divider,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { GrInfo } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiStepComponent from "../../components/MultiStepComponent";
import { countries } from "../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
} from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import CalendlyService from "../../service/CalendlyService";
import PartnerService from "../../service/PartnerService";
import { mailOptions } from "../Dashboard/Settings";

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const UserOnboard = ({ hideWelcome = false }) => {
  const [me, setMe] = useState(null);
  const [smtp, setSmtp] = useState(null);
  const [SMTPModal, setSMTPModal] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [calendlyToken, setCalendlyToken] = useState(null);

  const navigate = useNavigate();
  const partner = useSelector(getPartner);
  const loading = useSelector(selectLoading);
  const darkMode = useSelector(selectDarkMode);

  useEffect(() => {
    AuthService.me().then((data) => setMe(data.data.me));
  }, []);
  useEffect(() => {
    AuthService.me().then((data) =>
      setOnboardingStatus(data.data.onboardingStatus)
    );

    CalendlyService.getCurrentToken()
      .then(({ data }) => {
        setCalendlyToken(data);
      })
      .catch(() => setCalendlyToken(null));
  }, []);

  const handleSave = (me) => {
    AuthService.updateMe({
      ...me,
      silent: true,
    });
  };

  const debouncedChange = debounce((value) => handleSave(value), 800);

  const getProps = (fieldKey) => ({
    value: me?.[fieldKey],
    onChange: (e) => {
      setMe((current) => {
        const value = {
          ...current,
          [fieldKey]: e?.target?.value ?? e,
        };

        // debouncedChange(value);

        return value;
      });
    },
  });

  const partnerRegistrationSteps = [
    {
      id: "step1",
      name: "Basic Information",
      form: [
        {
          fieldName: "phone",
          label: "Phone Number*",
          type: "phone",
          required: true,
        },
        {
          fieldName: "line1",
          label: "Address Line 1*",
          type: "input",
          required: true,
        },
        {
          fieldName: "line2",
          label: "Address Line 2",
          type: "input",
        },
        {
          fieldName: "city",
          label: "City*",
          type: "input",
          required: true,
        },
        {
          fieldName: "state",
          label: "State*",
          type: "input",
          required: true,
        },
        {
          fieldName: "zipCode",
          label: "Zip Code*",
          type: "input",
          required: true,
        },
        {
          fieldName: "country",
          label: "Country*",
          type: "select",
          options: countries,
          required: true,
        },
      ],
    },
    {
      id: "step2",
      name: "Company Information",
      form: [
        {
          fieldName: "companyName",
          label: "Company Name*",
          type: "input",
          required: true,
        },
        {
          fieldName: "companyWebsite",
          label: "Company Website*",
          type: "input",
          required: true,
        },
      ],
    },
    {
      id: "step3",
      name: "Branding",
      form: [
        {
          fieldName: "logo",
          label: "Logo",
          type: "upload",
          tooltip: "Upload your company logo",
        },
      ],
    },
    {
      id: "step4",
      name: "Communication Preferences",
      form: [
        {
          fieldName: "userCalendarLink",
          label: "Calendar Link",
          type: "input",
          tooltip: "Enter your calendar link for scheduling",
        },
        {
          fieldName: "mailTemplate",
          label: "Mail Template",
          type: "select",
          options: mailOptions,
          tooltip: "Enter your default mail template",
        },
      ],
    },
    {
      id: "step5",
      requiredFields: [
        { value: "serviceDetails", label: "Service Details" },
        { value: "customerPersonaAge", label: "Target Age" },
        { value: "customerPersonaGender", label: "Target Gender" },
        { value: "customerPersonaInterest", label: "Target Interests" },
        { value: "customerPersonaIndustry", label: "Target Industries" },
        { value: "customerPersonaKeywords", label: "Target Keywords" },
      ],
      form: [
        {
          type: "custom2",
          CustomInputComponent: (
            <>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="">
                  <div className="">
                    <div className="sm:col-span-6">
                      <div className="flex gap-3 items-center">
                        <label
                          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                        >
                          Describe your the benefits of your service*
                        </label>
                      </div>
                      <div className="mt-2">
                        <textarea
                          rows={5}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                          {...getProps("serviceDetails")}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3 mt-3">
                      <div className="flex gap-3 items-center">
                        <label
                          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                        >
                          Target Age*
                        </label>
                      </div>
                      <div className="mt-2">
                        <AntdSelect
                          mode="multiple"
                          options={[
                            { value: "0-13", label: "0-13" },
                            { value: "14-18", label: "14-18" },
                            { value: "19-24", label: "19-24" },
                            { value: "25-34", label: "25-34" },
                            { value: "35-44", label: "35-44" },
                            { value: "45-54", label: "45-54" },
                            { value: "55-64", label: "55-64" },
                            { value: "65+", label: "65+" },
                          ]}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                          {...getProps("customerPersonaAge")}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3 mt-3">
                      <div className="flex gap-3 items-center">
                        <label
                          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                        >
                          Target Gender*
                        </label>
                      </div>
                      <div className="mt-2">
                        <AntdSelect
                          mode="multiple"
                          options={[
                            { value: "man", label: "Man" },
                            { value: "woman", label: "Woman" },
                            { value: "diverse", label: "Diverse" },
                          ]}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                          {...getProps("customerPersonaGender")}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3 mt-3">
                      <div className="flex gap-3 items-center">
                        <label
                          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                        >
                          Target Interests*
                        </label>
                      </div>
                      <div className="mt-2">
                        <AntdSelect
                          mode="tags"
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                          {...getProps("customerPersonaInterest")}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3 mt-3">
                      <div className="flex gap-3 items-center">
                        <label
                          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                        >
                          Target Industries*
                        </label>
                      </div>
                      <div className="mt-2">
                        <AntdSelect
                          mode="tags"
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                          {...getProps("customerPersonaIndustry")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="flex gap-3 items-center">
                      <label
                        className={`block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 `}
                      >
                        Target Keywords*
                      </label>
                    </div>
                    <div className="mt-2">
                      <AntdSelect
                        mode="tags"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                        {...getProps("customerPersonaKeywords")}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </>
          ),
        },
      ],
    },
    {
      id: "step5.2",
      hide: !partner?.helpUrl,
      requiredFields: [{ value: "connectCalendly", label: "Help Center" }],
      form: [
        {
          type: "custom2",
          CustomInputComponent: (
            <>
              <div className="px-4  flex gap-3 items-center justify-center">
                <div className="">
                  <div className="col-span-full">
                    Make sure to visit our{" "}
                    <a href={partner?.helpUrl}>help center</a> to acquire useful
                    knowledge on how to get full value from SalesAI!
                  </div>
                </div>
              </div>
            </>
          ),
        },
      ],
    },
    {
      id: "step6",
      requiredFields: [
        { value: "connectCalendly", label: "Calendly Connection" },
      ],
      form: [
        {
          type: "custom2",
          CustomInputComponent: (
            <>
              <div className="px-4  flex gap-3 items-center justify-center">
                <div className="">
                  <div className="col-span-full">
                    <div>
                      <div className="flex items-center justify-center gap-5">
                        <div>
                          <Space>
                            <label
                              className={`"block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400`}
                            >
                              Calendly
                            </label>
                          </Space>

                          <div className="mt-2">
                            {calendlyToken?.access_token ? (
                              <Popconfirm
                                title="Your calendar scheduling functionality will stop working. Are you sure to proceed?"
                                onConfirm={async () => {
                                  await CalendlyService.disconnectCalendly();
                                  AuthService.me().then((data) => {
                                    setMe(data.data.me);
                                    setOnboardingStatus(
                                      data.data.onboardingStatus
                                    );
                                  });
                                  CalendlyService.getCurrentToken().then(
                                    (data) => {
                                      setCalendlyToken(data.access_token);
                                    }
                                  );
                                }}
                              >
                                <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                                  Disconnect
                                </button>
                              </Popconfirm>
                            ) : (
                              <button
                                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                                onClick={async () => {
                                  const res =
                                    await CalendlyService.getAuthURI();
                                  window.location.href = res.data.authUri;
                                }}
                              >
                                Connect
                              </button>
                            )}
                          </div>
                        </div>
                        <img
                          src="/images/logos/calendly2.png"
                          style={{ height: 50 }}
                        />
                      </div>
                    </div>

                    <div>
                      {calendlyToken?.access_token && (
                        <div className="mt-2">
                          <label className="text-sm">
                            Selecting a default event type for interviews will
                            help you to automate your scheduling and let you
                            focus on the conversations that matter.
                          </label>
                          <div className="mt-2">
                            <Select
                              style={{ width: 200 }}
                              value={softValue.preferredCalendlyEvent}
                              onChange={(value) => {
                                setSoftValue((e) => ({
                                  ...e,
                                  preferredCalendlyEvent: value,
                                }));
                              }}
                              options={
                                eventTypes
                                  ? eventTypes.map((eventType) => ({
                                      value: eventType.uri,
                                      label: eventType.name,
                                    }))
                                  : []
                              }
                              className="block w-1/2 rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ),
        },
      ],
    },
  ].filter((a) => {
    if (a.hide) return false;
    if (a.id === "step5" && me?.serviceDetails) return false;

    return true;
  });

  if (!me) return <Skeleton active />;
  return (
    <>
      <div
        className={hideWelcome ? "" : "fixed left-0 top-0 w-[100vw] h-[100vh]"}
        style={
          hideWelcome
            ? {}
            : {
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: -1,
                backgroundImage: `url('/images/screenshots/payroll.png')`,
                filter: "blur(10px)",
              }
        }
      />
      <div
        className={
          hideWelcome
            ? ""
            : "relative flex min-h-screen flex-col justify-center overflow-hidden bg-transparent py-12"
        }
      >
        <div
          className={
            hideWelcome
              ? ""
              : "relative bg-white dark:bg-gray-900 px-6 pt-10 pb-9 shadow-xl dark:shadow-gray-400/50 hover:shadow-gray-600/50  mx-auto w-full max-w-lg rounded-2xl"
          }
        >
          <div
            className={
              hideWelcome
                ? ""
                : "mx-auto flex w-full max-w-md flex-col space-y-2"
            }
          >
            {!hideWelcome && (
              <div className="flex flex-col items-center justify-center text-center space-y-2">
                <div className="font-semibold text-3xl">
                  <p>Onboarding</p>
                </div>
                <div className="flex flex-row text-sm font-medium text-gray-400">
                  <p>
                    We are pleased to welcome you aboard as a valued user in the
                    journey towards enhancing your marketing. To ensure a smooth
                    and efficient setup process, we have prepared a step-by-step
                    guide to assist you in configuring all the necessary
                    elements.
                  </p>
                </div>
              </div>
            )}
            <div>
              <MultiStepComponent
                loading={loading}
                displayUndoRedo
                displaySteps={false}
                steps={partnerRegistrationSteps}
                defaultFormData={me}
                passFormData={(formData) => {
                  if (!formData) return;
                  if (!hideWelcome) return;
                  debouncedChange(formData);
                }}
                onFinish={async (formData) => {
                  await AuthService.updateMe({
                    ...formData,
                    silent: true,
                  });
                  navigate("/auth/subscription");
                }}
                formDataParent={{
                  serviceDetails: me?.serviceDetails ?? "",
                  customerPersonaAge: me?.customerPersonaAge ?? [],
                  customerPersonaGender: me?.customerPersonaGender ?? [],
                  customerPersonaInterest: me?.customerPersonaInterest ?? [],
                  customerPersonaIndustry: me?.customerPersonaIndustry ?? [],
                  customerPersonaKeywords: me?.customerPersonaKeywords ?? [],
                }}
                onNext={async (formData) => {
                  await AuthService.updateMe({
                    ...formData,
                    silent: true,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!SMTPModal}
        onCancel={() => setSMTPModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await PartnerService.updateSMTP({ smtp });

            if (!result.data?.smtp?.connectionStatus)
              return message.error("Connection was not successful");
            AuthService.me().then((data) => {
              setMe(data.data.me);
              setSMTPModal(false);
            });
          }}
        >
          <div className="mt-10 mb-2 flex items-center justify-between gap-3">
            <label>Host</label>
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.host}
              onChange={(e) => setSmtp((x) => ({ ...x, host: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Port</label>
            <input
              type="number"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.port}
              onChange={(e) => setSmtp((x) => ({ ...x, port: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Email</label>
            <input
              type="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.email}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, email: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Password</label>
            <input
              type="password"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.password}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, password: e.target.value }))
              }
            />
          </div>

          <Divider />

          <div className="flex items-center justify-start gap-3">
            <h2 className="text-lg font-semibold">DKIM Settings</h2>
            <a
              href="https://easydmarc.com/tools/dkim-record-generator"
              target="_blank"
            >
              <Tooltip title="DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.">
                <GrInfo />
              </Tooltip>
            </a>
          </div>

          <div className="my-2 flex items-center justify-between gap-3">
            <label>Domain</label>
            <input
              type="text"
              placeholder="your-domain.com"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimDomain}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimDomain: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Key Selector</label>
            <input
              type="text"
              placeholder="s2"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimKeySelector}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimKeySelector: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Private Key</label>
            <textarea
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimPrivateKey}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimPrivateKey: e.target.value }))
              }
            />
          </div>

          <div className="flex justify-between">
            <div />

            {me?.smtp?.connectionStatus ? (
              <Popconfirm
                title="When you disconnect your SMTP, your emails will no longer be sent from your inbox."
                onConfirm={async () => {
                  await PartnerService.disconnectSMTP();
                  PartnerService.getPartnerSecretConfig().then((data) => {
                    setMe(data.data.me);
                    AuthService.me().then((data) => {
                      setOnboardingStatus(data.data.onboardingStatus);
                    });
                  });
                }}
              >
                <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                  Disconnect
                </button>
              </Popconfirm>
            ) : (
              <button
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                Connect
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UserOnboard;
