import { Alert, Breadcrumb, Card, Skeleton, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import CrudService from "../../../service/CrudService";
import UserService from "../../../service/UserService";

const keyName = {
  impressions: "Impressions",
  clicks: "Clicks",
  spend: "Spend",
  cpc: "Cost Per Click",
  cpm: "CPM",
  ctr: "Click Through Rate",
  reach: "Reach",
  frequency: "Frequency",
  conversions: "Conversions",
  date_start: "First Delivery",
  date_stop: "Last Delivery",
};
const description = {
  impressions: "Number of times users that scrolled past your ad",
  clicks: "Number of users that clicked on your ad",
  spend: "Total Cost",
  cpc: "Cost Per Click",
  cpm: "Cost Per 1000 Impressions",
  ctr: "Ratio of users clicking on your ad to the total users scrolling past your ad",
  reach: "Total amount of unique users that have seen your ad",
  frequency: "Number of times your ad shows up per user on average",
  conversions: "Number of visitors that have converted to a lead",
  date_start: "Date of the first delivery of your ad",
  date_stop: "Date of the last delivery of your ad",
};

const AdStats = () => {
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(null);
  const [stats, setStats] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getData = () => {
      const id = searchParams.get("id");
      if (id) {
        setFormData(null);

        CrudService.getSingle("AdCampaign", id).then((res) => {
          if (res.data) setFormData(res.data);
          if (res.data.campaign)
            UserService.getCampaignStats(res.data.campaign).then((res) => {
              console.log(res.data);
              if (res.data?.insights?.[0]?._data)
                setStats(res.data.insights[0]._data);
              else setStats("Delivering");
            });
        });
      }
    };

    getData();
  }, [location]);

  if (!formData || !stats) return <Skeleton active />;

  const Bread = (
    <Breadcrumb
      className="mb-5"
      items={[
        {
          title: <Link to={`/dashboard/adautomation`}>Ad Automation</Link>,
        },
        {
          title: formData?.name,
        },
        {
          title: "Stats",
        },
      ]}
    />
  );

  if (stats === "Delivering")
    return (
      <div className="p-4">
        {Bread}
        <Alert
          type="info"
          message="Your ad has not yet been delivered to the target audience. You will see the statistics here as soon as your ad starts to deliver."
        />
      </div>
    );

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      render: (value, record) => (
        <>
          <Space>
            <div>{value}</div>
            <Tooltip title={description[record.key]}>
              <FaInfoCircle className="cursor-pointer" />
            </Tooltip>
          </Space>
        </>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data = Object.keys(stats)
    .filter((key) => !["account_currency"].includes(key))
    .map((key) => ({
      key,
      metric: keyName[key],
      value: `${stats[key]}${
        +["spend", "cpc", "cpm"].includes(key)
          ? ` ${stats.account_currency}`
          : ""
      }`,
    }));

  return (
    <div className="p-4">
      {Bread}

      <Card title="Ad Campaign Statistics" className="mb-4 shadow-lg">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          className="bg-white"
        />
      </Card>
    </div>
  );
};

export default AdStats;
