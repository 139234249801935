import { Alert, Divider, Skeleton, message } from "antd";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuid to generate unique keys
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";
import ApplyForm from "./ApplyForm";

export const handleTextDecoration = (text) => {
  return text
    ?.replace?.(/\*\*\*\*(.*?)\*\*\*\*/g, "<span class='underline'>$1</span>")
    ?.replace?.(/\*\*\*(.*?)\*\*\*/g, "<strong><em>$1</em></strong>")
    ?.replace?.(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    ?.replace?.(/\*(.*?)\*/g, "<em>$1</em>");
};

const VacancyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);
  const [applyForm, setApplyForm] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [isOwn, setIsOwn] = useState(false);

  useEffect(() => {
    if (!id) return;
    if (applyForm) return;
    setVacancyData(null);

    if (localStorage[`vacancyApplied_${id}`] === "true")
      setAlreadyApplied(true);

    const uuid = localStorage?.[`funnelUUID_${id}`] ?? uuidv4();
    localStorage[`funnelUUID_${id}`] = uuid;

    PublicService.clickFunnel({
      id,
      uuid,
      token: Cookies.get("accessToken"),
    }).then(({ data }) => {
      if (data?.own) setIsOwn(true);
    });
    PublicService.getVacancyData(id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [id, applyForm]);

  const onCTA = useCallback(() => {
    if (alreadyApplied)
      return message.info(
        "It seems like you already signed up for this. Please give us some time to review your application."
      );

    if (isOwn)
      message.info(
        "Please note that since you are currently logged in as the owner of this funnel, this is a demonstration of the process, and final submission of the form is not active in this environment.",
        [8]
      );

    if (localStorage?.[`funnelUUID_${id}`])
      PublicService.ctaClick({
        funnelUUID: localStorage[`funnelUUID_${id}`],
        token: Cookies.get("accessToken"),
      });

    setApplyForm(true);
  }, [alreadyApplied, isOwn, id]);

  if (applyForm) return <ApplyForm reset={() => setApplyForm(false)} />;
  if (!vacancyData) return <Skeleton active />;
  if (!vacancyData.enabled)
    return (
      <div className="p-5">
        <Alert
          type="info"
          message="The funnel you are looking for is no longer available."
        />
      </div>
    );
  return (
    <>
      <div className="content">
        <div className="w-full pt-6 pl-8 pb-6 pr-8 border-box">
          <div className="max-w-md w-full sm:max-w-xl md:max-w-3xl lg:max-w-4xl mx-auto">
            <div>
              <div className="break-words text-center  ">
                <h2
                  className="font-bold text-2xl"
                  dangerouslySetInnerHTML={{
                    __html: handleTextDecoration(vacancyData.valueProposition),
                  }}
                ></h2>
                <h4 className="mt-5">
                  🕝 {vacancyData.contractType} 💼 {vacancyData.engagementType}{" "}
                  🏢 {vacancyData.location}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full border-box pl-8 pr-8 pb-4 mt-5">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className=" break-words text-center ">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: handleTextDecoration(vacancyData.heroTitle),
                  }}
                ></h4>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-md sm:max-w-xl mx-auto">
          <div className="text-center">
            <button
              className="w-full py-3 px-5 mx-auto text-center cursor-pointer rounded-md max-w-md transform active:scale-95 box-border transition border-none"
              onClick={onCTA}
              type="button"
            >
              <div className="w-full border-box">
                <div className="w-full max-w-md sm:max-w-xl mx-auto md:max-w-3xl lg:max-w-4xl">
                  <div>
                    <div className="p-4 bg-indigo-500 rounded-3xl  text-white font-bold text-center break-words">
                      <h3>{vacancyData.CTA}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>

        <Divider />

        <div className="w-full border-box pt-8 pl-8 pr-8 ">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className=" break-words text-center  text-xl">
                <h2>
                  <strong>{vacancyData.benefitsTitle}</strong>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full border-box pt-6 pl-8 pr-8 pb-8">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div
                className=" break-words text-center "
                dangerouslySetInnerHTML={{
                  __html: handleTextDecoration(vacancyData.benefitsText),
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="w-full pt-6 pl-6 pr-6 pb-2 border-box">
          <div className="w-full sm:max-w-xl max-w-md md:max-w-3xl lg:max-w-4xl mx-auto">
            <div>
              <div className="grid gap-6 text-left grid-cols-1 md:grid-cols-2">
                {vacancyData.benefits.map((benefit) => (
                  <div
                    key={benefit._id}
                    className="w-full flex align-top box-border items-start"
                  >
                    <div
                      className="flex justify-center flex-shrink-0 items-center"
                      style={{ width: "46px", height: "46px" }}
                    >
                      <span data-emoji-set="native">
                        <span
                          style={{
                            fontSize: "34px",
                            fontFamily:
                              'EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"',
                          }}
                        >
                          {benefit.icon}
                        </span>
                      </span>
                    </div>
                    <div
                      className="text flex flex-grow items-center break-words ml-6 min-w-0"
                      style={{ minHeight: "46px", fontSize: "16px" }}
                    >
                      <div className="border-box w-full">
                        <div className="w-full sm:max-w-xl max-w-md md:max-w-3xl lg:max-w-4xl mx-auto">
                          <div>
                            <div className=" inheritSize break-words text-left ">
                              <h4>
                                <strong>{benefit.title}</strong>{" "}
                                {benefit.description}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full border-box pt-2 pl-8 pr-8 pb-2 mt-10">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div></div>
          </div>
        </div>

        <Divider />

        {vacancyData.requiredSkills && (
          <>
            <div className="w-full border-box pt-8 pl-8 pr-8 ">
              <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                <div>
                  <div className=" break-words text-center  text-xl">
                    <h2>
                      <strong>Requirements</strong>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full border-box pt-6 pl-8 pr-8 pb-8">
              <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                <div className="flex justify-center">
                  <div
                    className=" break-words text-left "
                    dangerouslySetInnerHTML={{
                      __html: handleTextDecoration(vacancyData.requiredSkills),
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <Divider />
          </>
        )}

        <div className="w-full border-box pt-8 pl-8 pr-8 pb-2">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className=" break-words text-center  text-xl">
                <h2>
                  <strong>{vacancyData.testimonialTitle}</strong>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-box pt-2 pl-6 pr-6 pb-6">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className="grid text-left gap-6 grid-cols-1 md:grid-cols-2">
                {vacancyData.teamTestimonials.map((testimonial) => (
                  <div
                    key={testimonial._id}
                    className="flex box-border w-full align-top items-center flex-col"
                  >
                    <div
                      className="flex-shrink-0 flex items-center justify-center "
                      style={{
                        width: "46px",
                        height: "46px",
                      }}
                    >
                      <div className="w-full h-full bg-no-repeat">
                        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-5xl">
                          <div>
                            <div className="p-0">
                              <div className="image overflow-hidden leading-[0] rounded">
                                <div className="   " style={{ width: "100%" }}>
                                  <div
                                    className=" loaded"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingBottom: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text flex items-center flex-grow break-words w-full mt-3 "
                      style={{ minHeight: "0px", fontSize: "16px" }}
                    >
                      <div className="w-full border-box ">
                        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                          <div>
                            <div className=" break-words text-center inheritSize ">
                              <h4>
                                <strong>
                                  {testimonial.author},{" "}
                                  {testimonial.authorPosition}
                                </strong>
                              </h4>
                              <h4
                                dangerouslySetInnerHTML={{
                                  __html: handleTextDecoration(
                                    testimonial.testimonial
                                  ),
                                }}
                              ></h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-md sm:max-w-xl mx-auto">
          <div className="text-center">
            <button
              className="w-full py-3 px-5 mx-auto text-center cursor-pointer rounded-md max-w-md transform active:scale-95 box-border transition border-none"
              onClick={onCTA}
              type="button"
            >
              <div className="w-full border-box">
                <div className="w-full max-w-md sm:max-w-xl mx-auto md:max-w-3xl lg:max-w-4xl">
                  <div>
                    <div className="p-4 bg-indigo-500 rounded-3xl  text-white font-bold text-center break-words">
                      <h3>{vacancyData.CTA}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="w-full border-box pt-2 pl-8 pr-8 pb-2 ">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-box pt-2 pl-8 pr-8 pb-2 ">
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default VacancyDetails;
