export const brandColor = "#000000";

export const USER_TESTING_MODE = true;

export const countries = [
  { value: "JP", label: "Japan" },
  { value: "CN", label: "China" },
  { value: "US", label: "United States" },
  { value: "DE", label: "Germany" },
  { value: "IN", label: "India" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "CA", label: "Canada" },
  { value: "IT", label: "Italy" },
  { value: "BR", label: "Brazil" },
  { value: "AU", label: "Australia" },
  { value: "KR", label: "South Korea" },
  { value: "MX", label: "Mexico" },
  { value: "ES", label: "Spain" },
  { value: "ID", label: "Indonesia" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "NL", label: "Netherlands" },
  { value: "TR", label: "Turkey" },
  { value: "CH", label: "Switzerland" },
  { value: "PL", label: "Poland" },
  { value: "AR", label: "Argentina" },
  { value: "SE", label: "Sweden" },
  { value: "NO", label: "Norway" },
  { value: "BE", label: "Belgium" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "TH", label: "Thailand" },
  { value: "NG", label: "Nigeria" },
  { value: "EG", label: "Egypt" },
  { value: "AT", label: "Austria" },
  { value: "SG", label: "Singapore" },
  { value: "RU", label: "Russia" },
  { value: "BD", label: "Bangladesh" },
  { value: "VN", label: "Vietnam" },
  { value: "MY", label: "Malaysia" },
  { value: "ZA", label: "South Africa" },
  { value: "PH", label: "Philippines" },
  { value: "DK", label: "Denmark" },
  { value: "IR", label: "Iran" },
  { value: "PK", label: "Pakistan" },
  { value: "HK", label: "Hong Kong" },
  { value: "CO", label: "Colombia" },
  { value: "RO", label: "Romania" },
  { value: "CL", label: "Chile" },
  { value: "CZ", label: "Czech Republic (Czechia)" },
  { value: "FI", label: "Finland" },
  { value: "IQ", label: "Iraq" },
  { value: "PT", label: "Portugal" },
  { value: "NZ", label: "New Zealand" },
  { value: "PE", label: "Peru" },
  { value: "QA", label: "Qatar" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "GR", label: "Greece" },
  { value: "DZ", label: "Algeria" },
  { value: "KW", label: "Kuwait" },
  { value: "HU", label: "Hungary" },
  { value: "UA", label: "Ukraine" },
  { value: "MA", label: "Morocco" },
  { value: "ET", label: "Ethiopia" },
  { value: "SK", label: "Slovakia" },
  { value: "EC", label: "Ecuador" },
  { value: "OM", label: "Oman" },
  { value: "DO", label: "Dominican Republic" },
  { value: "KE", label: "Kenya" },
  { value: "AO", label: "Angola" },
  { value: "GT", label: "Guatemala" },
  { value: "BG", label: "Bulgaria" },
  { value: "LU", label: "Luxembourg" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "PA", label: "Panama" },
  { value: "TZ", label: "Tanzania" },
  { value: "LK", label: "Sri Lanka" },
  { value: "GH", label: "Ghana" },
  { value: "BY", label: "Belarus" },
  { value: "UY", label: "Uruguay" },
  { value: "HR", label: "Croatia" },
  { value: "LT", label: "Lithuania" },
  { value: "CI", label: "Côte d'Ivoire" },
  { value: "CR", label: "Costa Rica" },
  { value: "RS", label: "Serbia" },
  { value: "SI", label: "Slovenia" },
  { value: "MM", label: "Myanmar" },
  { value: "CD", label: "DR Congo" },
  { value: "SD", label: "Sudan" },
  { value: "JO", label: "Jordan" },
  { value: "TN", label: "Tunisia" },
  { value: "LY", label: "Libya" },
  { value: "UG", label: "Uganda" },
  { value: "BH", label: "Bahrain" },
  { value: "CM", label: "Cameroon" },
  { value: "BO", label: "Bolivia" },
  { value: "PY", label: "Paraguay" },
  { value: "LV", label: "Latvia" },
  { value: "NP", label: "Nepal" },
  { value: "EE", label: "Estonia" },
  { value: "SV", label: "El Salvador" },
  { value: "HN", label: "Honduras" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "KH", label: "Cambodia" },
  { value: "ZM", label: "Zambia" },
  { value: "CY", label: "Cyprus" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "IS", label: "Iceland" },
  { value: "SN", label: "Senegal" },
  { value: "GE", label: "Georgia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "MO", label: "Macao" },
  { value: "GN", label: "Guinea" },
  { value: "GA", label: "Gabon" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "BW", label: "Botswana" },
  { value: "HT", label: "Haiti" },
  { value: "AM", label: "Armenia" },
  { value: "PS", label: "State of Palestine" },
  { value: "BF", label: "Burkina Faso" },
  { value: "AL", label: "Albania" },
  { value: "ML", label: "Mali" },
  { value: "MZ", label: "Mozambique" },
  { value: "MT", label: "Malta" },
  { value: "BJ", label: "Benin" },
  { value: "JM", label: "Jamaica" },
  { value: "MN", label: "Mongolia" },
  { value: "BN", label: "Brunei" },
  { value: "LA", label: "Laos" },
  { value: "NI", label: "Nicaragua" },
  { value: "GY", label: "Guyana" },
  { value: "MG", label: "Madagascar" },
  { value: "CG", label: "Congo" },
  { value: "MD", label: "Moldova" },
  { value: "NE", label: "Niger" },
  { value: "MK", label: "North Macedonia" },
  { value: "RW", label: "Rwanda" },
  { value: "MW", label: "Malawi" },
  { value: "MU", label: "Mauritius" },
  { value: "BS", label: "Bahamas" },
  { value: "TD", label: "Chad" },
  { value: "NA", label: "Namibia" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "MR", label: "Mauritania" },
  { value: "TG", label: "Togo" },
  { value: "MV", label: "Maldives" },
  { value: "ME", label: "Montenegro" },
  { value: "BB", label: "Barbados" },
  { value: "FJ", label: "Fiji" },
  { value: "SZ", label: "Eswatini" },
  { value: "LR", label: "Liberia" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SR", label: "Suriname" },
  { value: "AD", label: "Andorra" },
  { value: "TL", label: "Timor-Leste" },
  { value: "BI", label: "Burundi" },
  { value: "BZ", label: "Belize" },
  { value: "LS", label: "Lesotho" },
  { value: "CF", label: "Central African Republic" },
  { value: "CV", label: "Cabo Verde" },
  { value: "GM", label: "Gambia" },
  { value: "LC", label: "Saint Lucia" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SC", label: "Seychelles" },
  { value: "GD", label: "Grenada" },
  { value: "KM", label: "Comoros" },
  { value: "VU", label: "Vanuatu" },
  { value: "KN", label: "Saint Kitts & Nevis" },
  { value: "VC", label: "St. Vincent & Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "DM", label: "Dominica" },
  { value: "ST", label: "Sao Tome & Principe" },
  { value: "FM", label: "Micronesia" },
  { value: "MH", label: "Marshall Islands" },
  { value: "KI", label: "Kiribati" },
  { value: "TV", label: "Tuvalu" },
];

export const MINIMUM_AI_CHARS = 50;

export const ZOOM_ENABLED = false;

export const STANDARD_MOMENT_FORMAT = "DD-MM-YYYY hh:mm A";

export const CURRENCY_EXCHANGES = {
  usd: 1,
  eur: 0.92,
  gbp: 0.79,
  aud: 1.52,
  cad: 1.34,
  chf: 0.87,
  inr: 83.12,
  aed: 3.67,
};

export const COST_PER_SEARCH = 0.05;
export const COST_PER_UNLOCK_EMAIL = 0.15;
export const COST_PER_ATTEMPT = 0.1;
export const MAX_BULK_UNLOCK = 10;

export const DEFAULT_CANDIDATE_MESSAGE_VARIABLES = [
  { id: "candidateFirstname", display: "Lead's Firstname" },
  { id: "candidateLastname", display: "Lead's Lastname" },
  { id: "candidateEmail", display: "Lead's Email" },
  { id: "candidatePhone", display: "Lead's Phone" },
  { id: "jobTitle", display: "Job Title" },
  { id: "jobLocation", display: "Job Location" },
  { id: "jobApplicationLink", display: "Job Application Link" },
  { id: "interviewDate", display: "Interview Date" },
  { id: "interviewTime", display: "Interview Time" },
  { id: "interviewDateEnd", display: "Interview Ending Date" },
  { id: "interviewTimeEnd", display: "Interview Ending Time" },
  { id: "interviewMeetingLink", display: "Interview Meeting Link" },
  { id: "calendly_cancel_url", display: "Calendly Cancel URL" },
  {
    id: "calendly_reschedule_url",
    display: "Calendly Reschedule URL",
  },
  { id: "companyName", display: "Company Name" },
  { id: "companyWebsite", display: "Company Website" },
  { id: "companyAddress", display: "Company Address" },
  { id: "applicationStatus", display: "Application Status" },
  { id: "rejectionReason", display: "Rejection Reason" },
  { id: "calendarLink", display: "Calendar Link" },
  { id: "userCalendarLink", display: "User Calendar Link" },
  { id: "currentDate", display: "Current Date" },
  { id: "currentTime", display: "Current Time" },
  { id: "userFirstname", display: "User Firstname" },
  { id: "userLastname", display: "User Lastname" },
  { id: "userEmail", display: "User Email" },
  { id: "userPhone", display: "User Phone" },
  { id: "cvLink", display: "CV Submission Link" },
  { id: "surveyLink", display: "Survey Link" },
  {
    id: "interviewTimeRemaining",
    display: "Interview Remaining Time",
  },
];

export const eeoForm = [
  {
    fieldName: "gender",
    label: "Gender",
    type: "select",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "nonBinary", label: "Non Binary" },
      { value: "preferNotToSay", label: "Prefer not to disclose" },
    ],
    placeholder: "Select your sexual orientation",
  },
  {
    fieldName: "sexualOrientation",
    label: "Sexual Orientation",
    type: "select",
    options: [
      { value: "heterosexual", label: "Heterosexual" },
      { value: "homosexual", label: "Homosexual" },
      { value: "bisexual", label: "Bisexual" },
      { value: "other", label: "Other" },
      { value: "preferNotToSay", label: "Prefer not to say" },
    ],
    placeholder: "Select your sexual orientation",
  },
  {
    fieldName: "ethnicity",
    label: "Race / Ethnicity",
    type: "select",
    options: [
      { value: "hispanicOrLatino", label: "Hispanic or Latino" },
      { value: "americanIndian", label: "American Indian/Alaskan" },
      { value: "asian", label: "Asian" },
      { value: "black", label: "Black" },
      {
        value: "hawaiianPacificIslander",
        label: "Hawaiian / Pacific Islander",
      },
      { value: "white", label: "White" },
      { value: "preferNotToSay", label: "Prefer not to say" },
    ],
    placeholder: "Select your race / ethnicity",
  },
  {
    fieldName: "veteranStatus",
    label: "Veteran Status",
    type: "radio",
    options: [
      { value: "veteran", label: "Veteran" },
      { value: "notVeteran", label: "Not a Veteran" },
      { value: "preferNotToSay", label: "Prefer not to say" },
    ],
  },
  {
    fieldName: "disability",
    label: "Disability Status",
    type: "radio",
    tooltip:
      "A disability is a condition that limits one or more of your major life activities. Disabilities include, but are not limited to: substance use disorder, autoimmune disorder, blind or low vision, cancer, cardiovascular disease, celiac disease, cerebral palsy, deaf or serious hearing difficulties, diabetes, disfigurement, epilepsy, seizure disorder, gastrointestinal disorders, intellectual disabilities, mental health conditions, missing limbs, mobility impairment, nervous system condition, neurodivergence, ADHD, autism, dyslexia, dyspraxia, learning disabilities, paralysis, pulmonary or respiratory conditions, tuberculosis, asthma, emphysema, short stature and traumatic brain injury.",
    options: [
      {
        value: "yes",
        label: "Yes, I have a disability (or previously had a disability)",
      },
      { value: "no", label: "No, I don't have a disability" },
      { value: "preferNotToSay", label: "Prefer not to say" },
    ],
  },
];

export const personalDataCollection = [
  {
    fieldName: "firstname",
    label: "Firstname",
    type: "input",
    required: true,
    placeholder: "Enter your firstname",
  },
  {
    fieldName: "lastname",
    label: "Lastname",
    type: "input",
    required: true,
    placeholder: "Enter your lastname",
  },
  {
    fieldName: "email",
    label: "Email",
    type: "email",
    required: true,
    placeholder: "Enter your email",
  },
  {
    fieldName: "phone",
    label: "Phone",
    type: "phone",
    required: true,
    placeholder: "Enter your phone",
  },
];
