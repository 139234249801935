import { Card, Col, Progress, Row, Select, Space, Spin, Statistic } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { currencies } from "../../data/currencies";
import { getPartner } from "../../redux/auth/selectors";
import PartnerService from "../../service/PartnerService";

const { Option } = Select;

const segmentDetails = {
  H: { segmentNum: 8, timeframeName: "hour" },
  D: { segmentNum: 7, timeframeName: "day" },
  W: { segmentNum: 4, timeframeName: "week" },
  M: { segmentNum: 6, timeframeName: "month" },
  A: { segmentNum: 5, timeframeName: "year" },
};

const PartnerStats = () => {
  const [mainStats, setMainStats] = useState({});
  const [segmentedStats, setSegmentedStats] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [timeframe, setTimeframe] = useState("D");
  const partner = useSelector(getPartner);

  const isLoading = useMemo(() => loading1 || loading3, [loading1, loading3]);

  const chartData = [...segmentedStats].reverse().map((segment) => ({
    name: `${segment.idx === 1 ? "This" : segment.idx - 1} ${
      segmentDetails[timeframe].timeframeName
    }${segment.idx <= 2 ? "" : "s"}${segment.idx === 1 ? "" : " ago"}`,
    Clicks: segment.clicks,
    Conversions: segment.conversions,
    Applicants: segment.applicants,
    Qualified: segment.qualified,
    Meetings: segment.meetings,
    Hires: segment.hires,
    Users: segment.users,
  }));

  const loadData = useCallback(() => {
    setLoading1(true);
    setLoading3(true);

    PartnerService.getNumbers()
      .then(({ data }) => {
        setMainStats(data);
      })
      .finally(() => {
        setLoading1(false);
      });
    PartnerService.getSegmentedNumbers(timeframe)
      .then(({ data }) => {
        setSegmentedStats(data);
      })
      .finally(() => {
        setLoading3(false);
      });
  }, [timeframe]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <div>
        <h1 className="font-bold mb-4 text-lg">Saas Statistics</h1>

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold ">Your overall performance</h2>{" "}
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title="Total Users"
                    value={mainStats.users}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Monthly Active Users"
                    value={mainStats.monthlyActiveUsers}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Subscribed Users"
                    value={mainStats.payingUsers}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Users Subscribed Once"
                    value={mainStats.usersPaidOnce}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Monthly Recurring Revenue"
                    value={mainStats.currentMRR}
                    prefix={
                      currencies.find((c) => c.iso === partner?.currency)
                        ?.symbol ?? "$"
                    }
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <div className="ant-statistic css-dev-only-do-not-override-qk40m6">
                    <div className="ant-statistic-title">Paid User Ratio</div>
                    <Progress
                      type="circle"
                      size={60}
                      percent={mainStats.paidUserRatio}
                      status="active"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="ant-statistic css-dev-only-do-not-override-qk40m6">
                    <div className="ant-statistic-title">Churn Rate</div>
                    <Progress
                      type="circle"
                      size={60}
                      percent={mainStats.churn}
                      status="active"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="mb-2 flex justify-between">
                <h2 className="font-semibold ">
                  This is the overall performance across all recruiters on your
                  SaaS
                </h2>{" "}
                {isLoading ? (
                  <Spin />
                ) : (
                  <MdRefresh
                    size={20}
                    onClick={loadData}
                    className="cursor-pointer"
                  />
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title="Clicks"
                    value={mainStats.clicks}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Conversions"
                    value={mainStats.applicants}
                    valueStyle={{ color: "#52c41a" }}
                  />
                </Col>

                <Col span={12}>
                  <Statistic
                    title="Qualified"
                    value={mainStats.qualified}
                    valueStyle={{ color: "#fa8c16" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Meetings"
                    value={mainStats.meetings}
                    valueStyle={{ color: "#722ed1" }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Customers"
                    value={mainStats.hires}
                    valueStyle={{ color: "#eb2f96" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} className="mb-2">
            <Card>
              <div className="flex justify-between mb-2">
                <h2 className="font-semibold">
                  How your SaaS performed during the last{" "}
                  {segmentDetails[timeframe].segmentNum}{" "}
                  {segmentDetails[timeframe].timeframeName}s
                </h2>
                <Space>
                  <Select
                    defaultValue={timeframe}
                    style={{ width: 150 }}
                    onChange={(value) => setTimeframe(value)}
                  >
                    <Option value="H">
                      Last {segmentDetails["H"].segmentNum} hours
                    </Option>
                    <Option value="D">
                      Last {segmentDetails["D"].segmentNum} days
                    </Option>
                    <Option value="W">
                      Last {segmentDetails["W"].segmentNum} weeks
                    </Option>
                    <Option value="M">
                      Last {segmentDetails["M"].segmentNum} months
                    </Option>
                    <Option value="A">
                      Last {segmentDetails["A"].segmentNum} years
                    </Option>
                  </Select>

                  {isLoading ? (
                    <Spin />
                  ) : (
                    <MdRefresh
                      size={20}
                      onClick={loadData}
                      className="cursor-pointer"
                    />
                  )}
                </Space>
              </div>
              {/* Display segmentedStats data here */}
              <div className="max-w-full overflow-auto">
                <LineChart width={800} height={400} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Users" stroke="#8884d8" />
                  <Line type="monotone" dataKey="Clicks" stroke="#8884d8" />
                  <Line
                    type="monotone"
                    dataKey="Conversions"
                    stroke="#82ca9d"
                  />
                  <Line type="monotone" dataKey="Applicants" stroke="#ff7300" />
                  <Line type="monotone" dataKey="Qualified" stroke="#ff3860" />
                  <Line type="monotone" dataKey="Meetings" stroke="#ffaa00" />
                  <Line type="monotone" dataKey="Hires" stroke="#00bfff" />
                </LineChart>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PartnerStats;
