import { Menu, Transition } from "@headlessui/react";
import { Button, Modal, Popconfirm, Rate, Space, Tag, message } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaFilter, FaLinkedin, FaRegCalendarCheck } from "react-icons/fa";
import { FaNoteSticky, FaPhone } from "react-icons/fa6";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { MdDelete, MdOutlineMail, MdOutlineTextsms } from "react-icons/md";
import { RiShareForwardBoxFill, RiSortAsc } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPhoneCandidate } from "../../redux/auth/actions";
import {
  getPartner,
  getPhoneCandidate,
  selectDarkMode,
  selectUser,
} from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import ATSService from "../../service/ATSService";
import CrudService from "../../service/CrudService";
import ForwardResume from "./ForwardResume";
import CandidateMeetingBox from "./Message/CandidateMeetingBox";
import VariableMessageBox from "./Message/VariableMessageBox";
import VariableSMSBox from "./Message/VariableSMSBox";
import CandidateNote from "./vacancies/CandidateNote";
import DetailsModal from "./vacancies/DetailsModal";

const PAGE_LIMIT = 24;

const SearchCandidates = () => {
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortId, setSortId] = useState("recent_created");
  const [queryFilter, setQueryFilter] = useState("ALL");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const myUser = useSelector(selectUser);
  const darkMode = useSelector(selectDarkMode);

  const [messageCandidate, setMessageCandidate] = useState(null);
  const [smsCandidate, setSMSCandidate] = useState(null);
  const [scheduleCandidate, setScheduleCandidate] = useState(null);
  const [noteModal, setNoteModal] = useState(null);
  const [forwardResume, setForwardResume] = useState(null);
  const [detailsModal, setDetailsModal] = useState(null);
  const phoneCandidate = useSelector(getPhoneCandidate);

  const sortQuery = {
    recent_created: { createdAt: -1 },
    oldest_created: { createdAt: 1 },
    recent_hired: { hiredDate: -1 },
    oldest_hired: { hiredDate: 1 },
    highest_stars: { stars: -1 },
    lowest_stars: { stars: 1 },
  };

  const loadMoreUsers = useCallback(
    async ({ text = undefined, refresh = false, page = 1 }) => {
      setLoading(true);
      try {
        const response = await ATSService.searchCandidates({
          page,
          limit: PAGE_LIMIT,
          sort: sortId ? sortQuery[sortId] : {},
          text,
          filter: {
            ALL: {},
            QUALIFIED: { qualified: true },
            MEETING: { meetingScheduled: true },
            HIRED: { hired: true },
            REJECTED: { rejected: true },
          }[queryFilter],
        });

        const newUsers = response.data.result;
        setUsers((prevUsers) => [...(refresh ? [] : prevUsers), ...newUsers]);
        setPage((prevPage) => prevPage + 1);
        setTotal(response.data.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [queryFilter, sortId]
  );

  useEffect(() => {
    loadMoreUsers({});
  }, [loadMoreUsers]);

  useEffect(() => {
    if (loading) return;
    const handleScroll = () => {
      const container = document.getElementById("myContainer");

      if (
        container &&
        window.innerHeight + window.scrollY >= container.scrollHeight - 100
      ) {
        loadMoreUsers({ page });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading]);

  // Function to perform the actual search
  const performSearch = useCallback((text) => {
    setPage(1);
    loadMoreUsers({
      text: text ? text : undefined,
      refresh: true,
      page: 1,
    });
  }, []);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 1000);
  };

  const onCardOptionClick = (id, option) => {
    if (option === "email") setMessageCandidate(id);
    if (option === "sms") setSMSCandidate(id);
    if (option === "phone") {
      if (phoneCandidate) return message.info("Please close the active tab");
      store.dispatch(setPhoneCandidate(id));
    }
    if (option === "schedule") {
      setScheduleCandidate(id);
    }
    if (option === "open-note") setNoteModal(id);
    if (option === "forward-resume") setForwardResume(id);
    if (option === "details-modal") setDetailsModal(id);
  };

  return (
    <>
      <div className="relative mt-2 flex items-center">
        <input
          type="text"
          placeholder="Search Leads"
          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
          value={searchTerm}
          onChange={handleInputChange}
        />

        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <RiSortAsc />
              Sort
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-1 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  ring-1 ring-black ring-opacity-5 focus:outline-none">
              {[
                { _id: "recent_created", name: "Newest first" },
                { _id: "oldest_created", name: "Oldest first" },
                { _id: "recent_hired", name: "Newest customer" },
                { _id: "oldest_hired", name: "Oldest customer" },
                { _id: "highest_stars", name: "Best rating" },
                { _id: "lowest_stars", name: "Worst rating" },
              ].map((item) => (
                <Menu.Item key={item._id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active || sortId === item._id
                          ? "bg-gray-100 dark:bg-gray-400 dark:bg-gray-600"
                          : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300  cursor-pointer"
                      )}
                      onClick={() => {
                        setPage(1);
                        setUsers([]);
                        setSortId(item._id);
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>

        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <FaFilter />
              Filter
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-1 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  ring-1 ring-black ring-opacity-5 focus:outline-none">
              {[
                { _id: "ALL", name: "All leads" },
                { _id: "QUALIFIED", name: "Qualified" },
                { _id: "MEETING", name: "Meeting Scheduled" },
                { _id: "HIRED", name: "Customer" },
                { _id: "REJECTED", name: "Rejected" },
              ].map((item) => (
                <Menu.Item key={item._id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active || queryFilter === item._id
                          ? "bg-gray-100 dark:bg-gray-400 dark:bg-gray-600"
                          : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300  cursor-pointer"
                      )}
                      onClick={() => {
                        setPage(1);
                        setUsers([]);
                        setQueryFilter(item._id);
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <div className="container mx-auto p-4" id="myContainer">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.map((user) => (
            <div
              key={user._id}
              className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out"
            >
              <div className="px-6 py-4">
                <div className="flex justify-between">
                  <p>
                    {user?.formData?.firstname} {user?.formData?.lastname}
                  </p>
                  <Space>
                    <a
                      href="#"
                      title="Send Email"
                      onClick={() => onCardOptionClick(user?._id, "email")}
                    >
                      <MdOutlineMail />
                    </a>

                    {partner?.calendlyclientId && (
                      <a
                        href="#"
                        title="Schedule"
                        onClick={() => onCardOptionClick(user?._id, "schedule")}
                      >
                        <FaRegCalendarCheck />
                      </a>
                    )}

                    {user?.formData?.phone && partner?.twilioAccountSID && (
                      <a
                        href="#"
                        title="Send SMS"
                        onClick={() => onCardOptionClick(user?._id, "sms")}
                      >
                        <MdOutlineTextsms />
                      </a>
                    )}
                    {user?.formData?.phone &&
                      partner?.twilioAccountSID &&
                      partner?.twilioTwimlAppSID && (
                        <a
                          href={`#`}
                          title="Call"
                          onClick={() => onCardOptionClick(user?._id, "phone")}
                        >
                          <FaPhone />
                        </a>
                      )}
                  </Space>
                </div>

                <div className="mb-2">
                  <Tag color="geekblue">{user?.stageId?.name}</Tag>
                </div>

                <div>
                  <Rate
                    value={user.stars ?? 0}
                    onChange={(e) => {
                      if (myUser?.accessLevel === "read")
                        return message.error("Your access is read-only");
                      setUsers((c) => {
                        const current = [...c];

                        const item = current.find((c) => c._id === user._id);
                        if (!item) return current;
                        item.stars = e;

                        return current;
                      });
                      CrudService.update("VacancySubmission", user._id, {
                        stars: e,
                      });
                    }}
                  />
                </div>
                <div className="flex mt-1 mb-3 justify-start items-end">
                  <Space>
                    {/* <a
                      href="#"
                      title="Forward Resume"
                      onClick={(e) =>
                        onCardOptionClick(user._id, "forward-resume")
                      }
                    >
                      <RiShareForwardBoxFill />
                    </a> */}
                    <a
                      href="#"
                      title="Lead Notes"
                      onClick={(e) => onCardOptionClick(user._id, "open-note")}
                    >
                      <FaNoteSticky />
                    </a>
                    <a
                      href="#"
                      title="Details"
                      onClick={(e) =>
                        onCardOptionClick(user._id, "details-modal")
                      }
                    >
                      <IoFileTrayStackedOutline />
                    </a>
                    <a
                      href="#"
                      title="LinkedIn"
                      onClick={(e) => {
                        window.open(
                          user?.formData?.linkedInUrl?.includes?.("https://")
                            ? `${
                                user?.formData?.linkedInUrl?.includes?.("https")
                                  ? ""
                                  : "https://"
                              }${user?.formData?.linkedInUrl}`
                            : `https://www.linkedin.com/search/results/all/?keywords=${user?.formData?.firstname} ${user?.formData?.lastname}`
                        );
                      }}
                    >
                      <FaLinkedin />
                    </a>
                    <Popconfirm
                      title="Are you sure to delete the lead?"
                      onConfirm={async () => {
                        await CrudService.delete("VacancySubmission", user._id);
                        setUsers((c) => {
                          const current = [...c];

                          return current.filter((e) => e._id !== user._id);
                        });
                      }}
                    >
                      <MdDelete
                        size={18}
                        className="cursor-pointer text-red-500 relative"
                      />
                    </Popconfirm>
                  </Space>
                </div>

                <p className="text-gray-400 text-[10px]">
                  Signed up:{" "}
                  {moment(user.createdAt).format("Do MMM, YYYY, HH:mm")}
                </p>
                {user.hiredDate && (
                  <p className="text-gray-400 text-[10px]">
                    Closed:{" "}
                    {moment(user.hiredDate).format("Do MMM, YYYY, HH:mm")}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
        {total >= PAGE_LIMIT * (page - 1) && (
          <div className="flex justify-center mt-5">
            <Button loading={loading} onClick={() => loadMoreUsers({ page })}>
              Load more
            </Button>
          </div>
        )}
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!messageCandidate}
        onCancel={() => setMessageCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <VariableMessageBox
          candidateId={messageCandidate}
          onSend={() => {
            setMessageCandidate(null);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!smsCandidate}
        onCancel={() => setSMSCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <VariableSMSBox
          candidateId={smsCandidate}
          onSend={() => {
            setSMSCandidate(null);
          }}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!scheduleCandidate}
        onCancel={() => setScheduleCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <CandidateMeetingBox
          candidateId={scheduleCandidate}
          onSend={() => {
            setScheduleCandidate(null);
          }}
          close={() => setScheduleCandidate(null)}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!noteModal}
        onCancel={() => setTimeout(() => setNoteModal(null), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <CandidateNote candidateId={noteModal} />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!forwardResume}
        onCancel={() => setTimeout(() => setForwardResume(null), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <ForwardResume
          candidateId={forwardResume}
          onSend={() => setForwardResume(null)}
        />
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        width={"90vh"}
        open={!!detailsModal}
        onCancel={() => setTimeout(() => setDetailsModal(null), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <DetailsModal candidateId={detailsModal} />
      </Modal>
    </>
  );
};

export default SearchCandidates;
