import { v4 as uuidv4 } from "uuid";

export const exampleData = ({ user }) => [
  {
    id: "Hero",
    data: {
      description: [
        {
          type: "DivComponent",
          id: uuidv4(),
          content: {
            className: "",
          },
          components: [
            {
              type: "TextComponent",
              id: uuidv4(),
              content: {
                text: "A great headline to capture interest",
                className:
                  "text-gray-900_02 text-[45px] font-semibold md:text-[41px] sm:text-[35px] text-gray-900_02",
              },
            },
            {
              type: "TextComponent",
              id: uuidv4(),
              content: {
                text: "5-10 word subline",
                className: "text-[#475467] font-semibold smx:text-sm text-base",
              },
            },
            {
              type: "TextComponent",
              id: uuidv4(),
              content: {
                text: "A detailed description of our services / products",
                className: "text-[#475467] font-semibold smx:text-sm text-base",
              },
            },
          ],
        },
      ],
      image: "/dhimages/img_screen_mockup_replace.png",
      video: "https://player.vimeo.com/video/937499561?h=a4a0716db0",
      benefits: [
        {
          text: "5-10 word benefit",
        },
        {
          text: "5-10 word benefit",
        },
        {
          text: "5-10 word benefit",
        },
      ],
    },
  },
  {
    id: "CallToAction",
    data: {
      headline:
        "Our core value proposition and what it entails for the customer",
      description: "A more detailed description of why they should start today",
      secondaryButtonText: "Contact Us",
      secondaryButtonLink: "#contact",
      primaryButtonText: "Book Demo",
      primaryButtonLink: (user) =>
        user?.userCalendarLink ?? "https://calendly.com/",
    },
  },
  {
    id: "FeatureSlider",
    data: {
      slides: [
        {
          image: "/dhimages/img_screen_mockup_replace_221x333.png",
          title: "Feature title of our product / service",
          description: "Feature descritpion of our product / service",
        },
        {
          image: "/dhimages/img_screen_mockup_replace_221x333.png",
          title: "Feature title of our product / service",
          description: "Feature descritpion of our product / service",
        },
      ],
    },
  },
  {
    id: "TestemonialSectionSlider",
    data: {
      testimonials: [
        {
          title: "Testimonial section title",
          description: "Summary of what customers are saying about us",
          rate: "5.0",
          totalReviewers: 200,
        },
        {
          title: "Testimonial section title",
          description: "Summary of what customers are saying about us",
          rate: "5.0",
          totalReviewers: 58,
        },
      ],
    },
  },
  {
    id: "AdminFeatures",
    data: {
      title: "Feature Category Title",
      features: [
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
      ],
      image: "/dhimages/img_image_1.png",
    },
  },
  {
    id: "AdminFeatures",
    data: {
      title: "Feature Category Title",
      features: [
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
      ],
      imageLeft: "/dhimages/img_image_500x594.png",
    },
  },
  {
    id: "AdminFeatures",
    data: {
      title: "Feature Category Title",
      features: [
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
        {
          description: "Feature description (5-10 words)",
        },
      ],
      image: "/dhimages/img_image_1.png",
    },
  },
  {
    id: "LeadForm",
    data: {
      headline: "Sign Up for Updates",
      description: "Stay informed with the latest news from our team.",
      privacyPolicyLink: user?.privacyPolicyLink ?? "#",
    },
  },
  {
    id: "DivideLine",
  },
  {
    id: "Benefits",
    data: {
      title: "A meaningful title for the entire benefits section",
      description: "Description of what our core benefits are",
      benefits: [
        {
          emoji: "🚀",
          title: "Benefit title (2-5 words)",
          description: "Benefit description (10-20 words)",
        },
        {
          emoji: "🚀",
          title: "Benefit title (2-5 words)",
          description: "Benefit description (10-20 words)",
        },
        {
          emoji: "🚀",
          title: "Benefit title (2-5 words)",
          description: "Benefit description (10-20 words)",
        },
        {
          emoji: "🚀",
          title: "Benefit title (2-5 words)",
          description: "Benefit description (10-20 words)",
        },
      ],
    },
  },
  {
    id: "ValueProp",
    data: {
      title: "Title for the section of our promise",
      description: "Description of our promise",
      image: "/dhimages/img_image.png",
    },
  },
  {
    id: "LeadForm",
    data: {
      headline: "Sign Up for Updates",
      description: "Stay informed with the latest news from our team.",
      privacyPolicyLink: user?.privacyPolicyLink ?? "#",
    },
  },
  {
    id: "LeadForm",
    data: {
      headline: "Sign Up for Updates",
      description: "Stay informed with the latest news from our team.",
      privacyPolicyLink: user?.privacyPolicyLink ?? "#",
    },
  },
  {
    id: "CustomComponent",
    data: {
      description: [
        {
          id: uuidv4(),
          type: "DivComponent",
          components: [
            {
              id: uuidv4(),
              type: "TextComponent",
              content: {
                text: "Key Feature 1",
                className:
                  "flex w-full items-center justify-center gap-3 rounded-[12px] border-[1.5px] border-solid border-gray-200 bg-gray-200 p-3",
              },
            },
            {
              id: uuidv4(),
              type: "TextComponent",
              content: {
                text: "Key Feature 2",
                className:
                  "flex w-full items-center justify-center gap-3 rounded-[12px] border-[1.5px] border-solid border-gray-200 bg-gray-200 p-3",
              },
            },
            {
              id: uuidv4(),
              type: "TextComponent",
              content: {
                text: "Key Feature 3",
                className:
                  "flex w-full items-center justify-center gap-3 rounded-[12px] border-[1.5px] border-solid border-gray-200 bg-gray-200 p-3",
              },
            },
          ],
          content: {
            className: "container-sm mdx:p-5 smx:px-5 pl-8 pr-8 flex gap-6",
          },
        },
      ],
    },
  },
  {
    id: "CustomerPainPoints",
    data: {
      featuredShowcase1Text:
        "Overcome The Biggest Digital Marketing Challenges",
      featuredShowcase2Text:
        "Having the right online presence sets your business up for success.",
      featuredShowcase3Text:
        "Running multiple ad campaigns means creating countless copywriting creatives and undergoing constant A/B testing to see what works.",
      featuredShowcase1Img: "/dhimages/img_image_1.png",
      featuredShowcase4Text:
        "Building a landing page from scratch can be time-consuming and technically demanding.",
      featuredShowcase5Text:
        "Our platform allows you to generate and customize landing pages in minutes, not days, with drag-and-drop ease and no coding required.",
      featuredShowcase2Img: "/dhimages/img_image_1.png",
      featuredShowcase6Text:
        "Organizing and producing webinars takes weeks of work, from content creation to technical setup.",
      featuredShowcase7Text:
        "Our platform includes AI avatars and pre-built webinar templates that make going live simple and impactful, keeping your audience engaged and informed.",
      featuredShowcase3Img: "/dhimages/img_image_1.png",
      featuredShowcase8Text:
        "Crafting compelling email sequences that resonate with customers is a must for engagement and retention.",
      featuredShowcase9Text:
        "Our AI helps you create personalized email sequences, ensuring your messaging hits the right note every time.",
      featuredShowcase4Img: "/dhimages/img_image_1.png",
    },
  },
];
