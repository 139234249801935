import {
  Divider,
  Drawer,
  Input,
  Popconfirm,
  Select,
  Space,
  message,
} from "antd";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  CircleMenu,
  CircleMenuItem,
  TooltipPlacement,
} from "react-circular-menu";
import { BiHide, BiShow } from "react-icons/bi";
import { CiImageOn, CiText } from "react-icons/ci";
import { FaExternalLinkAlt } from "react-icons/fa";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { LuComponent, LuRollerCoaster } from "react-icons/lu";
import { MdDeleteForever } from "react-icons/md";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuid to generate unique keys
import CrudService from "../../service/CrudService";
import UploadService from "../../service/UploadService";
import SpacingControl from "./EditorComponents/SpacingControl";
import { Button } from "./components";
import { configSections } from "./configSections";

const { Option } = Select;

export const TextComponent = ({ content, readOnly }) => {
  const style = {};
  if (content?.["background-color"])
    style.background = content?.["background-color"];
  if (content?.["text-color"]) style.color = content?.["text-color"];

  return (
    <div
      className={`${content.className} ${
        readOnly ? "" : "hover:border hover:border-yellow-400"
      }`}
      style={style}
    >
      {content.text}
    </div>
  );
};

export const DivComponent = ({ content, renderComponent, readOnly }) => {
  const style = {};
  if (content?.["background-color"])
    style.background = content?.["background-color"];
  if (content?.["text-color"]) style.color = content?.["text-color"];

  return (
    <div
      className={`${content.className} ${
        readOnly ? "" : "hover:border hover:border-yellow-400"
      }`}
      style={style}
    >
      {content.children &&
        content.children.map((child) => renderComponent(child))}
    </div>
  );
};

export const ImageComponent = ({ content, onUpdate, readOnly }) => {
  const [src, setSrc] = useState(content.src);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setSrc(e.target.result);
      UploadService.upload(file, 100).then((res) => {
        onUpdate({ src: res.data.secure_url, alt: "Updated Image" });
      });
    };
    reader.readAsDataURL(file);
  };

  const style = {
    objectFit: "contain",
    background: "black",
  };
  if (content?.["background-color"])
    style.background = content?.["background-color"];

  return (
    <div
      className={`${readOnly ? "" : "hover:border hover:border-yellow-400"}`}
    >
      <img
        src={src}
        alt={content.alt}
        className={`${content.className}`}
        style={style}
        {...content}
      />
      {!readOnly && (
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          onClick={(e) => {
            e.stopPropagation();
            e.target.file = "";
          }}
        />
      )}
    </div>
  );
};

const componentMap = {
  TextComponent,
  DivComponent,
  ImageComponent,
};

const LayoutControl = ({
  localContent,
  handleClassesChange,
  handleInputChange,
}) => {
  const options = ["block", "flex", "grid", "inline"];

  const layout = options.find((o) =>
    localContent?.className?.split?.(" ")?.includes?.(o)
  );

  return (
    <div>
      <h4 className="font-bold mt-2">Layout</h4>
      <Select
        value={layout}
        onChange={(value) => {
          const newClasses = localContent.className
            .split(" ")
            .filter((c) => !options.includes(c));
          newClasses.push(value);
          handleClassesChange(newClasses.join(" ").trim());
        }}
        style={{ width: "100%", marginBottom: "16px" }}
      >
        {options.map((option) => (
          <Option className="capitalize" key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>

      {layout === "flex" && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Direction
          </label>
          <Select
            value={localContent.className
              ?.split(" ")
              ?.find((c) => c.startsWith("flex-"))}
            onChange={(value) => handleInputChange("flex-direction", value)}
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Flex Direction"
          >
            <Option value="flex-row">Row</Option>
            <Option value="flex-row-reverse">Row Reverse</Option>
            <Option value="flex-col">Column</Option>
            <Option value="flex-col-reverse">Column Reverse</Option>
          </Select>

          <label className="block text-sm font-medium text-gray-700">
            Justify
          </label>
          <Select
            value={localContent.className
              ?.split(" ")
              ?.find((c) => c.startsWith("justify-"))}
            onChange={(value) => handleInputChange("justify-content", value)}
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Justify Content"
          >
            <Option value="justify-start">Start</Option>
            <Option value="justify-center">Center</Option>
            <Option value="justify-end">End</Option>
            <Option value="justify-between">Between</Option>
            <Option value="justify-around">Around</Option>
            <Option value="justify-evenly">Evenly</Option>
          </Select>

          <label className="block text-sm font-medium text-gray-700">
            Align
          </label>
          <Select
            value={localContent.className
              ?.split(" ")
              ?.find((c) => c.startsWith("items-"))}
            onChange={(value) => handleInputChange("align-items", value)}
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Align Items"
          >
            <Option value="items-start">Start</Option>
            <Option value="items-center">Center</Option>
            <Option value="items-end">End</Option>
            <Option value="items-baseline">Baseline</Option>
            <Option value="items-stretch">Stretch</Option>
          </Select>

          <label className="block text-sm font-medium text-gray-700">Gap</label>
          <Select
            value={localContent.className
              ?.split(" ")
              ?.find((c) => c.startsWith("gap-"))}
            onChange={(value) => handleInputChange("gap", value)}
            style={{ width: "100%", marginBottom: "16px" }}
            placeholder="Gap"
          >
            <Option value="gap-0">0</Option>
            <Option value="gap-1">1</Option>
            <Option value="gap-2">2</Option>
            <Option value="gap-3">3</Option>
            <Option value="gap-4">4</Option>
            <Option value="gap-5">5</Option>
            <Option value="gap-6">6</Option>
            <Option value="gap-8">8</Option>
            <Option value="gap-10">10</Option>
            <Option value="gap-12">12</Option>
            <Option value="gap-16">16</Option>
          </Select>
        </>
      )}
    </div>
  );
};

const SizeControl = ({ localContent, handleInputChange }) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  return (
    <div>
      {configSections
        .filter(
          (section) =>
            section.properties.filter(
              (e) =>
                section.title === "Spacing and Sizing" &&
                (showAdvancedOptions || e.notAdvanced)
            ).length > 0
        )
        .map((section) => (
          <div key={section.title}>
            <h3 className="font-bold mt-2">{section.title}</h3>
            {section.properties
              .filter(
                (e) =>
                  section.title === "Spacing and Sizing" &&
                  (showAdvancedOptions || e.notAdvanced)
              )
              .map((prop) => (
                <div key={prop.key} style={{ marginBottom: "16px" }}>
                  <label className="block text-sm font-medium text-gray-700">
                    {prop.placeholder}
                  </label>
                  <div className="flex gap-1 items-center">
                    <Select
                      allowClear
                      showSearch
                      value={localContent.className
                        ?.split?.(" ")
                        ?.find?.((c) => c.startsWith(prop.key + "-"))}
                      style={{ width: "100%" }}
                      onChange={(value) => handleInputChange(prop.key, value)}
                    >
                      {prop.options.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ))}
          </div>
        ))}

      <Button
        color="indigo-500"
        size="sm"
        className={`rounded-md font-bold tracking-[-0.20px] text-white mt-2`}
        onClick={() => {
          setShowAdvancedOptions((e) => !e);
        }}
      >
        <div className="flex gap-1 items-center">
          <span>
            {showAdvancedOptions
              ? "Hide Advanced Options"
              : "Show Advanced Options"}
          </span>
          {showAdvancedOptions ? <BiShow size={22} /> : <BiHide size={22} />}
        </div>
      </Button>
    </div>
  );
};

const TypographyControl = ({
  localContent,
  handleInputChange,
  handleLocalContentChange,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  return (
    <div>
      {configSections
        .filter(
          (section) =>
            section.properties.filter(
              (e) =>
                section.title === "Typography" &&
                (showAdvancedOptions || e.notAdvanced)
            ).length > 0
        )
        .map((section) => (
          <div key={section.title}>
            <h3 className="font-bold mt-2">{section.title}</h3>
            {section.properties
              .filter(
                (e) =>
                  section.title === "Typography" &&
                  (showAdvancedOptions || e.notAdvanced)
              )
              .map((prop) => (
                <div key={prop.key} style={{ marginBottom: "16px" }}>
                  <label className="block text-sm font-medium text-gray-700">
                    {prop.placeholder}
                  </label>
                  <div className="flex gap-1 items-center">
                    {prop.inputType === "color-picker" ? (
                      <input
                        type="color"
                        value={localContent[prop.key]}
                        onChange={(e) => {
                          handleLocalContentChange({
                            ...localContent,
                            [prop.key]: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <Select
                        allowClear
                        showSearch
                        value={localContent.className
                          ?.split?.(" ")
                          ?.find?.((c) => c.startsWith(prop.key + "-"))}
                        style={{ width: "100%" }}
                        onChange={(value) => handleInputChange(prop.key, value)}
                      >
                        {prop.options.map((option) => (
                          <Option key={option} value={option}>
                            {prop?.labels?.[option] ?? option}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}

      <Button
        color="indigo-500"
        size="sm"
        className={`rounded-md font-bold tracking-[-0.20px] text-white mt-2`}
        onClick={() => {
          setShowAdvancedOptions((e) => !e);
        }}
      >
        <div className="flex gap-1 items-center">
          <span>
            {showAdvancedOptions
              ? "Hide Advanced Options"
              : "Show Advanced Options"}
          </span>
          {showAdvancedOptions ? <BiShow size={22} /> : <BiHide size={22} />}
        </div>
      </Button>
    </div>
  );
};

const BackgroundControl = ({
  localContent,
  handleInputChange,
  handleLocalContentChange,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  return (
    <div>
      {configSections
        .filter(
          (section) =>
            section.properties.filter(
              (e) =>
                section.title === "Background" &&
                (showAdvancedOptions || e.notAdvanced)
            ).length > 0
        )
        .map((section) => (
          <div key={section.title}>
            <h3 className="font-bold mt-2">{section.title}</h3>
            {section.properties
              .filter(
                (e) =>
                  section.title === "Background" &&
                  (showAdvancedOptions || e.notAdvanced)
              )
              .map((prop) => (
                <div key={prop.key} style={{ marginBottom: "16px" }}>
                  <label className="block text-sm font-medium text-gray-700">
                    {prop.placeholder}
                  </label>
                  <div className="flex gap-1 items-center">
                    {prop.inputType === "color-picker" ? (
                      <input
                        type="color"
                        value={localContent[prop.key]}
                        onChange={(e) => {
                          handleLocalContentChange({
                            ...localContent,
                            [prop.key]: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <Select
                        allowClear
                        showSearch
                        value={localContent.className
                          ?.split?.(" ")
                          ?.find?.((c) => c.startsWith(prop.key + "-"))}
                        style={{ width: "100%" }}
                        onChange={(value) => handleInputChange(prop.key, value)}
                      >
                        {prop.options.map((option) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}

      <Button
        color="indigo-500"
        size="sm"
        className={`rounded-md font-bold tracking-[-0.20px] text-white mt-2`}
        onClick={() => {
          setShowAdvancedOptions((e) => !e);
        }}
      >
        <div className="flex gap-1 items-center">
          <span>
            {showAdvancedOptions
              ? "Hide Advanced Options"
              : "Show Advanced Options"}
          </span>
          {showAdvancedOptions ? <BiShow size={22} /> : <BiHide size={22} />}
        </div>
      </Button>
    </div>
  );
};

const BordersControl = ({ localContent, handleInputChange }) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  return (
    <div>
      {configSections
        .filter(
          (section) =>
            section.properties.filter(
              (e) =>
                section.title === "Borders" &&
                (showAdvancedOptions || e.notAdvanced)
            ).length > 0
        )
        .map((section) => (
          <div key={section.title}>
            <h3 className="font-bold mt-2">{section.title}</h3>
            {section.properties
              .filter(
                (e) =>
                  section.title === "Borders" &&
                  (showAdvancedOptions || e.notAdvanced)
              )
              .map((prop) => (
                <div key={prop.key} style={{ marginBottom: "16px" }}>
                  <label className="block text-sm font-medium text-gray-700">
                    {prop.placeholder}
                  </label>
                  <div className="flex gap-1 items-center">
                    <Select
                      allowClear
                      showSearch
                      value={localContent.className
                        ?.split?.(" ")
                        ?.find?.((c) => c.startsWith(prop.key + "-"))}
                      style={{ width: "100%" }}
                      onChange={(value) => handleInputChange(prop.key, value)}
                    >
                      {prop.options.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ))}
          </div>
        ))}

      <Button
        color="indigo-500"
        size="sm"
        className={`rounded-md font-bold tracking-[-0.20px] text-white mt-2`}
        onClick={() => {
          setShowAdvancedOptions((e) => !e);
        }}
      >
        <div className="flex gap-1 items-center">
          <span>
            {showAdvancedOptions
              ? "Hide Advanced Options"
              : "Show Advanced Options"}
          </span>
          {showAdvancedOptions ? <BiShow size={22} /> : <BiHide size={22} />}
        </div>
      </Button>
    </div>
  );
};

const EditorDrawer = ({
  localContent,
  handleClassesChange,
  handleInputChange,
  handleLocalContentChange,
  showAdvancedOptions,
  setShowAdvancedOptions,
  selectedItem,
  setLocalContent,
}) => (
  <>
    {selectedItem?.type === "ImageComponent" && (
      <div style={{ marginBottom: "16px" }}>
        <h4 className="font-bold mt-2">Image Attributes</h4>
        <Input
          className="h-[30px] rounded-md border-gray-300"
          value={localContent.width}
          onChange={(e) =>
            setLocalContent({ ...localContent, width: e.target.value })
          }
          placeholder="Image Width"
        />
        <Input
          className="h-[30px] rounded-md border-gray-300"
          value={localContent.height}
          onChange={(e) =>
            setLocalContent({ ...localContent, height: e.target.value })
          }
          placeholder="Image Height"
        />
      </div>
    )}

    {selectedItem?.type === "TextComponent" && (
      <div style={{ marginBottom: "16px" }}>
        <h4 className="font-bold mt-2">Text</h4>
        <Input
          className="h-[30px] rounded-md border-gray-300"
          value={localContent.text}
          onChange={(e) =>
            setLocalContent({ ...localContent, text: e.target.value })
          }
          placeholder="Text Content"
        />
      </div>
    )}

    <SpacingControl
      localContent={localContent}
      handleClassesChange={handleClassesChange}
    />
    <Divider />
    <LayoutControl
      localContent={localContent}
      handleClassesChange={handleClassesChange}
      handleInputChange={handleInputChange}
    />
    <Divider />
    <SizeControl
      localContent={localContent}
      handleInputChange={handleInputChange}
    />
    <Divider />
    <TypographyControl
      localContent={localContent}
      handleInputChange={handleInputChange}
      handleLocalContentChange={handleLocalContentChange}
    />
    <Divider />
    <BackgroundControl
      localContent={localContent}
      handleInputChange={handleInputChange}
      handleLocalContentChange={handleLocalContentChange}
    />
    <Divider />
    <BordersControl
      localContent={localContent}
      handleInputChange={handleInputChange}
    />

    <Divider />

    <Input
      className="h-[30px] rounded-md border-gray-300"
      value={localContent.className}
      onChange={(e) =>
        setLocalContent({ ...localContent, className: e.target.value })
      }
      placeholder="Tailwind CSS Class"
    />
  </>
);

const DragDropEditor = ({
  initialComponents = [],
  defauldClassname = "",
  onChange,
  readOnly = false,
  wrapperClassname,
  wrapperClick,
  onRemove,
  itemContent,
}) => {
  let { id } = useParams();
  const [components, setComponents] = useState(
    Array.isArray(initialComponents)
      ? initialComponents
      : [
          {
            id: uuidv4(),
            type: "TextComponent",
            content: {
              text: initialComponents,
              className: defauldClassname,
            },
          },
        ]
  );

  useEffect(() => {
    setComponents(
      Array.isArray(initialComponents)
        ? initialComponents
        : [
            {
              id: uuidv4(),
              type: "TextComponent",
              content: {
                text: initialComponents,
                className: defauldClassname,
              },
            },
          ]
    );
  }, [initialComponents]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [localContent, setLocalContent] = useState({});
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setLocalContent(selectedItem?.content || {});
  }, [selectedItem]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const items = Array.from(components);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setComponents(items);
    onChange && onChange(items);
  };

  const handleClick = (item) => {
    setSelectedItem(item);
    setDrawerVisible(true);
  };

  useEffect(() => {
    const handleUpdate = () => {
      setComponents(
        Array.isArray(initialComponents)
          ? initialComponents
          : [
              {
                id: uuidv4(),
                type: "TextComponent",
                content: {
                  text: initialComponents,
                  className: defauldClassname,
                },
              },
            ]
      );
    };
    document.addEventListener("COMPONENT_UPDATE_DRAGDROP", handleUpdate);
    return () =>
      document.removeEventListener("COMPONENT_UPDATE_DRAGDROP", handleUpdate);
  }, [initialComponents]);

  useEffect(() => {
    const updatedComponents = components.map((comp) =>
      comp?.id === selectedItem?.id
        ? { ...comp, content: { ...comp.content, ...localContent } }
        : comp
    );
    setComponents(updatedComponents);
    onChange && onChange(updatedComponents);
  }, [selectedItem, localContent]);

  const handleInputChange = (key, value) => {
    const options =
      configSections
        .map((a) => a.properties)
        ?.flat?.()
        ?.find?.((a) => a.key === key)?.options ?? [];
    const newClasses = localContent.className
      .split(" ")
      .filter((c) => !options.includes(c));
    newClasses.push(value);
    const updatedContent = { className: newClasses.join(" ").trim() };
    setLocalContent((localContent) => ({ ...localContent, ...updatedContent }));
  };

  const handleClassesChange = (newClasses) => {
    const updatedContent = { className: newClasses };
    setLocalContent((localContent) => ({ ...localContent, ...updatedContent }));
  };
  const handleLocalContentChange = (newLocalContent) => {
    setLocalContent((localContent) => ({ ...newLocalContent }));
  };

  const addNewComponent = (componentType) => {
    let newComponent =
      componentType === "DivComponent"
        ? {
            id: uuidv4(),
            type: componentType,
            components: [],
            content: {
              className: defauldClassname,
            },
          }
        : {
            id: uuidv4(),
            type: componentType,
            content: {
              text: "New Text",
              className: defauldClassname,
            },
          };

    if (componentType === "Duplicate" && components?.length > 0) {
      const lastComponent = components[components.length - 1];
      newComponent = {
        ...lastComponent,
        id: uuidv4(),
      };
      const handleComponent = (component) => {
        component.id = uuidv4();
        if (Array.isArray(component?.components)) {
          for (const compo of component?.components) handleComponent(compo);
        }
      };
      if (Array.isArray(componentType.components)) {
        for (const compo of componentType.components) handleComponent(compo);
      }
    } else if (componentType === "Duplicate") {
      return message.error("Nothing to duplicate within this component");
    }

    const updatedComponents = [...components];

    if (selectedItem?.type === "DivComponent") {
      const updatedComponent = updatedComponents.find(
        (c) => c.id === selectedItem.id
      );
      if (updatedComponent) {
        updatedComponent.components = [
          ...updatedComponent.components,
          newComponent,
        ];
        setTimeout(
          () =>
            document.dispatchEvent(
              new CustomEvent("COMPONENT_UPDATE_DRAGDROP")
            ),
          50
        );
      }
    } else {
      updatedComponents.push(newComponent);
    }
    setComponents(updatedComponents);
    onChange && onChange(updatedComponents);
  };

  const renderComponent = (item) => {
    const Component = componentMap[item.type];
    return (
      <Component
        renderComponent={renderComponent}
        content={item.content}
        readOnly={readOnly}
        onUpdate={(updates) => {
          const updatedItem = components.map((comp) =>
            comp.id === item.id
              ? { ...comp, content: { ...comp.content, ...updates } }
              : comp
          );
          setComponents(updatedItem);
          onChange && onChange(updatedItem);
        }}
      />
    );
  };

  const itemStyle = {};
  if (itemContent?.["background-color"])
    itemStyle.background = itemContent?.["background-color"];
  if (itemContent?.["text-color"])
    itemStyle.color = itemContent?.["text-color"];

  const [showOptions, setShowOptions] = useState(false);
  return (
    <div
      className="p-2 min-w-[100px] min-h-[50px]"
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="components">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={wrapperClassname}
              style={itemStyle}
              onClick={(e) => {
                if (wrapperClick) wrapperClick(e);
              }}
            >
              {components.map((item, index) =>
                item.type === "DivComponent" ? (
                  <DragDropEditor
                    readOnly={readOnly}
                    wrapperClassname={`${
                      readOnly
                        ? `p-5 ${item?.content?.className}`
                        : `hover:border hover:border-blue-400 cursor-pointer p-5 ${item?.content?.className}`
                    }`}
                    itemContent={item?.content}
                    wrapperClick={(e) => {
                      if (!item) return;
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                      if (!readOnly)
                        handleClick({ ...item, showDivOptions: true });
                    }}
                    onRemove={() => {
                      const updatedComponents = [...components].filter(
                        (a) => a.id !== item.id
                      );
                      setComponents(updatedComponents);
                      onChange && onChange(updatedComponents);
                    }}
                    initialComponents={item.components}
                    onChange={(e) => {
                      setComponents((cur) => {
                        const updatedComponents = cur.map((b, i) =>
                          i === index
                            ? {
                                ...b,
                                components: e,
                              }
                            : b
                        );
                        onChange && onChange(updatedComponents);
                        return updatedComponents;
                      });
                    }}
                  />
                ) : (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        className="grow"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={
                          readOnly
                            ? () => {}
                            : (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClick(item);
                              }
                        }
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        {renderComponent(item)}
                        <Space
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          {!readOnly &&
                            ["TextComponent", "ImageComponent"].includes(
                              item?.type
                            ) && (
                              <HiOutlineDocumentDuplicate
                                size={15}
                                className="text-xs cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setComponents((c) => {
                                    let dupe;
                                    try {
                                      dupe = {
                                        ...c?.find?.((x) => x.id === item.id),
                                      };
                                    } catch (e) {}

                                    if (dupe) dupe.id = uuidv4();

                                    const updatedComponents = [...c];
                                    if (dupe) {
                                      const currentIndex = c.findIndex(
                                        (x) => x.id === item.id
                                      );
                                      updatedComponents.splice(
                                        currentIndex + 1,
                                        0,
                                        dupe
                                      );
                                    } else {
                                      message.error(
                                        "Nothing to duplicate within this component"
                                      );
                                    }

                                    onChange && onChange(updatedComponents);
                                    return updatedComponents;
                                  });
                                }}
                              />
                            )}
                          {!readOnly &&
                            ["TextComponent", "ImageComponent"].includes(
                              item?.type
                            ) && (
                              <Popconfirm
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                title="Are you sure?"
                                onConfirm={(e) => {
                                  setComponents((c) => {
                                    const updatedComponents = [...c].filter(
                                      (x) => x.id !== item.id
                                    );
                                    onChange && onChange(updatedComponents);
                                    return updatedComponents;
                                  });
                                }}
                                className="text-xs"
                              >
                                <MdDeleteForever
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  className="cursor-pointer"
                                  size={15}
                                />
                              </Popconfirm>
                            )}
                        </Space>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!readOnly && showOptions && (
        <div className="flex gap-1 items-center mt-1 ml-4 absolute circlemenuarea">
          <CircleMenu
            startAngle={-90}
            rotationAngle={360}
            itemSize={0.8}
            radius={3}
            rotationAngleInclusive={false}
          >
            <CircleMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewComponent("ImageComponent");
              }}
              tooltip="Image"
            >
              <CiImageOn />
            </CircleMenuItem>
            <CircleMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewComponent("TextComponent");
              }}
              tooltip="Text"
            >
              <CiText />
            </CircleMenuItem>
            <CircleMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewComponent("DivComponent");
              }}
              tooltip="Component"
            >
              <LuComponent />
            </CircleMenuItem>
            <CircleMenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewComponent("Duplicate");
              }}
              tooltip="Duplicate"
            >
              <HiOutlineDocumentDuplicate />
            </CircleMenuItem>
          </CircleMenu>

          {onRemove && (
            <Popconfirm
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              title="Are you sure?"
              onConfirm={(e) => {
                onRemove();
              }}
              className="text-xs"
            >
              <MdDeleteForever className="cursor-pointer" size={15} />
            </Popconfirm>
          )}
        </div>
      )}

      <Drawer
        title="Edit Component"
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
          setSelectedItem(null);
        }}
        open={drawerVisible}
      >
        <EditorDrawer
          selectedItem={selectedItem}
          setLocalContent={setLocalContent}
          localContent={localContent}
          handleClassesChange={handleClassesChange}
          handleInputChange={handleInputChange}
          handleLocalContentChange={handleLocalContentChange}
          showAdvancedOptions={showAdvancedOptions}
          setShowAdvancedOptions={setShowAdvancedOptions}
        />
      </Drawer>
    </div>
  );
};

export default DragDropEditor;
